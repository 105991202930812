import AppTextStyles from 'version-two/theme/components/AppTextStyles';
import AppInputStyles from 'version-two/theme/components/AppInputStyles';
import AppButtonStyles from 'version-two/theme/components/AppButtonStyles';
import AppLinkStyles from 'version-two/theme/components/AppLinkStyles';
import AppBoxStyles from 'version-two/theme/components/AppBoxStyles';
import AppFlexStyles from 'version-two/theme/components/AppFlexStyles';
import AppTabStyles from 'version-two/theme/components/AppTabStyles';
import AppAvatarStyles from './AppAvatarStyles';

const components = {
  Text: AppTextStyles,
  Input: AppInputStyles,
  Button: AppButtonStyles,
  Link: AppLinkStyles,
  AppBox: AppBoxStyles,
  AppFlex: AppFlexStyles,
  Avatar: AppAvatarStyles,
  Tabs: AppTabStyles,
};

export default components;
