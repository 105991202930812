/* eslint-disable prettier/prettier */
/* eslint-disable no-alert */

/* eslint-disable no-console */
import useAppStore from "version-two/store";

const getUserToken = () => {
  const zustandToken = useAppStore.getState().user_token;
  if (zustandToken) {
    return zustandToken;
  }
  const localStorageUserToken = localStorage.getItem('token');
  if ([undefined, 'undefined', null].includes(localStorageUserToken)) {
    return null;
  }

  return localStorageUserToken;
};

export default getUserToken;
