import { theme as baseTheme } from '@chakra-ui/react';
import appColors from 'version-two/theme/appColors';
import { fontSizes, fontWeights, lineHeights } from 'version-two/theme/fonts';

const studentDetailTab = (props) => ({
  tablist: {
    ...baseTheme.components.Tabs.variants.line(props).tablist,
  },

  tab: {
    ...baseTheme.components.Tabs.variants.line(props).tab,
    color: appColors.brandGrey['500'],

    fontSize: fontSizes.sm,
    lineHeight: lineHeights.sm,

    fontWeight: fontWeights.semibold,

    py: 3,
    px: 8,

    _selected: {
      color: appColors.brandGrey['900'],
      // borderBottomRadius: appBorderRadius.sm,
      borderBottom: '3px solid',
      borderBottomColor: appColors.brandSecondary['500'],

      fontWeight: fontWeights.bold,
    },
    _focus: {
      outline: 'none',
      boxShadow: 'none',
    },
  },
});
const colorfulVariant = () => ({
  tab: {
    border: '2px solid',
    borderColor: 'transparent',
    borderTopRadius: 'lg',
    borderBottom: 'none',
    fontSize: fontSizes.sm,
    fontWeight: fontWeights.xl,
    color: appColors.brandSecondary['500'],
    _selected: {
      fontSize: fontSizes.sm,
      fontWeight: fontWeights.extrabold,

      color: appColors.brandGrey['900'],

      display: 'block',
      backgroundColor: appColors.brandGrey['50'],
      padding: '8px 16px 8px 16px',

      borderRadius: '8px',

      textDecoration: 'none',
      boxShadow: 'none',

      _hover: {
        textDecoration: 'none',
      },
    },
  },
  tablist: {
    borderBottom: '2x solid',
    borderColor: 'inherit',
  },
});
const AppTabStyles = {
  variants: {
    studentDetailTab: (props) => studentDetailTab(props),
    teacherTab: colorfulVariant,
  },
};
export default AppTabStyles;
