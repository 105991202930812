import {
  Image,
  // Image,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
  // useBreakpointValue,
} from '@chakra-ui/react';
import StudentDashboardLayout from 'version-two/layout/StudentDashboardLayout';
import StudentProgramContent from 'version-two/pages/studentDashboard/StudentProgramDetailWrapper/studentProgramContent/StudentProgramContent';
import getIsWebView from 'version-two/utils/getIsWebView';
import AppFlex from 'version-two/components/chakraLayoutOverrides/AppFlex';
import AppBox from 'version-two/components/chakraLayoutOverrides/AppBox';
import AppText from 'version-two/components/Typography/AppText';
import { fontWeights } from 'version-two/theme/fonts';
import AppAvatar from 'version-two/components/AppAvatar/AppAvatar';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  GET_PITCH_LIST,
  GET_STUDENT_LIVE_CLASS_DETAIL_DATA,
  POST_PITCH_CHANGE,
} from 'version-two/api/urls';
import makeGetRequest from 'version-two/api/utils/makeGetRequest';
import AppLoader from 'version-two/components/AppLoader/AppLoader';
// import AppButton from 'version-two/components/Typography/AppButton';
// import { PurpleChatIcon } from 'version-two/assets';
// import { MAKE_STUDENT_CHAT_PORTAL } from 'version-two/navigation/routes';
import StudentProgramClassRecordings from 'version-two/pages/studentDashboard/StudentProgramDetailWrapper/StudentProgramClassRecordings/StudentProgramClassRecordings';
import AppButton from 'version-two/components/Typography/AppButton';
import { MusicIcon } from 'version-two/assets';
import AppModal from 'version-two/components/AppModal/AppModal';
import SelectPitchListModal from 'version-two/components/modal/SelectPitchListModal';
import useAppStore from 'version-two/store';
import makePostRequest from 'version-two/api/utils/makePostRequest';
import ShowCurrentPitch from './ShowCurrentPitch';

function StudentProgramDetailWrapper() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  // const [tempPitchVal, setTempPitchVal] = useState(null);
  const { pitch, setPitch } = useAppStore();
  const queryClient = useQueryClient();

  const { programSlug } = useParams();
  const {
    data: studentProgramData,
    isLoading: studentProgramDataIsLoading,
    refetch: refetchStudentProgramData,
  } = useQuery(
    GET_STUDENT_LIVE_CLASS_DETAIL_DATA(programSlug),
    () => makeGetRequest(GET_STUDENT_LIVE_CLASS_DETAIL_DATA(programSlug)),
    {
      onSuccess: (res) => {
        setPitch(res?.data?.default_pitch);
      },
    },
  );

  const { data: pitchList, isLoading: pitchListIsLoading } = useQuery(
    GET_PITCH_LIST,
    () => makeGetRequest(GET_PITCH_LIST),
    {
      enabled: true,
    },
  );

  const { mutate: mutatePitch, isLoading: mutateIsLoading } = useMutation(
    (body) => makePostRequest(POST_PITCH_CHANGE, body),
    {
      onSuccess: () => {
        refetchStudentProgramData();
        queryClient.invalidateQueries(
          GET_STUDENT_LIVE_CLASS_DETAIL_DATA(programSlug),
        );
        onClose();
      },
    },
  );

  const handlePitchListChange = (val) => {
    mutatePitch({
      batch_id: programSlug,
      default_pitch: val?.id,
    });
    // setTempPitchVal(val);
  };

  // const {
  //   isOpen: isOpenPitchConform,
  //   onOpen: onOpenPitchConform,
  //   onClose: onClosePitchConform,
  // } = useDisclosure();

  // const handlePitchChangeConformation = () => {
  //   setPitch(tempPitchVal);
  //   onClosePitchConform();
  //   setTempPitchVal(null);

  // TODO: code to refetch the list based on the selected pitch
  // };

  const isPitchDisabled =
    studentProgramData?.data?.live_class_v2?.disable_pitch !== 'false';

  return (
    <StudentDashboardLayout>
      <AppLoader isLoading={studentProgramDataIsLoading}>
        {!studentProgramDataIsLoading && (
          <>
            <AppFlex
              // sx={{ marginBottom: ['15px', '40px'] }}
              customStyles={{
                justifyContent: 'space-between',
                marginBottom: { base: '15px', sm: '40px' },
              }}
            >
              {!getIsWebView() && (
                <AppBox
                  customStyles={{ display: { base: 'none', sm: 'block' } }}
                >
                  <AppText
                    title="Trainer"
                    customStyles={{ mb: 1, fontSize: '19px', fontWeight: 700 }}
                  />
                  <AppFlex>
                    <AppAvatar
                      h="60px"
                      w="60px"
                      name={studentProgramData?.data?.teacher.name}
                      src={
                        studentProgramData?.data?.teacher
                          ?.live_class_profile_photo
                      }
                    />

                    <AppBox customStyles={{ ml: 5 }}>
                      <AppText
                        title={studentProgramData?.data?.teacher.name}
                        size="sm"
                        customStyles={{
                          mb: 1,
                          fontSize: '19px',
                          fontWeight: 700,
                        }}
                      />
                      {/* Teacher phone number -- commented -- Client requirement -- */}
                      {/* <AppFlex> */}
                      {/*  <Image src={PhoneCustomIcon} mr={2} /> */}
                      {/*  <AppLink */}
                      {/*    href={`tel:${data.data.teacher.live_class_phone_number}`} */}
                      {/*    title={data.data.teacher.live_class_phone_number} */}
                      {/*  /> */}
                      {/* </AppFlex> */}
                    </AppBox>
                  </AppFlex>
                </AppBox>
              )}
              {/* Schedule -- commented -- Client requirement -- */}
              {/* <AppBox> */}
              {/*  <AppText */}
              {/*    title="Schedule" */}
              {/*    variant="lightXS" */}
              {/*    customStyles={{ mb: 1, fontWeight: fontWeights.normal }} */}
              {/*  /> */}
              {/*  <AppText title={data.data.schedule_display} size="sm" /> */}
              {/* </AppBox> */}

              {!isPitchDisabled && <ShowCurrentPitch currentPitchVal={pitch} />}
            </AppFlex>

            <AppText
              title={studentProgramData?.data?.group_chat?.batch_name}
              size="sm"
              customStyles={{ fontWeight: fontWeights.bold }}
            />
            <AppText
              title={studentProgramData?.data?.group_chat?.title_line}
              variant="lightXS"
              customStyles={{ mb: 3 }}
            />

            {!isPitchDisabled && (
              <AppFlex customStyles={{ mb: 5 }} onClick={onOpen}>
                <AppButton
                  variant="outline"
                  customStyles={{ width: '100%', py: 6 }}
                >
                  <AppFlex customStyles={{ alignItems: 'center' }}>
                    <Image src={MusicIcon} mr={2} />
                    <AppText
                      title="Change your Pitch"
                      variant="contactSupport"
                    />
                  </AppFlex>
                </AppButton>
              </AppFlex>
            )}

            <Tabs variant="studentDetailTab">
              <TabList>
                <Tab>Content</Tab>
                <Tab>Recordings</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <StudentProgramContent />
                </TabPanel>
                <TabPanel>
                  <StudentProgramClassRecordings />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </>
        )}
      </AppLoader>
      {!isPitchDisabled && (
        <AppModal
          closeButton
          onClose={onClose}
          isOpen={isOpen}
          className="pitch-modal"
          bodyClassName="pitch-modal-body"
          headerClassName="pitch-modal-header"
          header="Choose Pitch"
        >
          <SelectPitchListModal
            currentPitchVal={pitch}
            onChange={handlePitchListChange}
            isMutateLoading={mutateIsLoading}
            pitchList={pitchList?.data}
            pitchListIsLoading={pitchListIsLoading}
          />
        </AppModal>
      )}
      {/* <AppModal
        onClose={onClosePitchConform}
        isOpen={isOpenPitchConform}
        className="pitch-conform-modal"
        bodyClassName="pitch-conform-modal-body"
        headerClassName="pitch-conform-modal-header"
        header="Are you sure?"
      >
        <PitchChangeConformationModal
          onClose={onClosePitchConform}
          handlePitchChangeConformation={handlePitchChangeConformation}
          currentPitchVal={pitch}
          tempPitchVal={tempPitchVal}
        />
      </AppModal> */}
    </StudentDashboardLayout>
  );
}

export default StudentProgramDetailWrapper;
