import React from 'react';
import AppText from 'voxguru-v1/components/Typography/AppText';

function StagingIdentifier() {
  return process.env.REACT_APP_NODE_ENV !== 'production' ? (
    <AppText
      title="(Staging)"
      size="sm"
      customStyles={{
        marginTop: '10px',
        marginBottom: '-12px',
      }}
    />
  ) : (
    ''
  );
}

export default StagingIdentifier;
