// import TeacherDashboardLayout from 'version-two/layout/TeacherDashboardLayout';
import ProgramTitleCard from 'version-two/components/ProgramTitleCard/ProgramTitleCard';
import { Box, Flex, useDisclosure, useBreakpointValue } from '@chakra-ui/react';
import AppText from 'version-two/components/Typography/AppText';
import { CopyContentIcon } from 'version-two/assets';
import TeacherProgramDetailDrawable from 'version-two/components/TeacherProgramDetailDrawable/TeacherProgramDetailDrawable';
import ManageModuleAccessBulk from 'version-two/components/ManageModuleAccessBulk/ManageModuleAccessBulk';
import { useQuery } from 'react-query';
import { GET_TEACHER_ID_MODULE } from 'version-two/api/urls/teacher';
import makeGetRequest from 'version-two/api/utils/makeGetRequest';
// import AppLoader from 'version-two/components/AppLoader/AppLoader';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import AppFlex from 'version-two/components/chakraLayoutOverrides/AppFlex';
// import { Select } from 'chakra-react-select';
// import useStudentSearch from 'version-two/hooks/useStudentSearch';
import {
  MAKE_TEACHER_PROGRAM_DETAIL,
  MAKE_TEACHER_PROGRAM_DETAIL_CONTENT,
} from 'version-two/navigation/routes';
import appColors from 'version-two/theme/appColors';

function TeacherCourseMaterial() {
  const navigate = useNavigate();
  const { programSlug, moduleSlug } = useParams();

  const { data: programDetail, isLoading: programDetailIsLoading } = useQuery(
    GET_TEACHER_ID_MODULE(programSlug),
    () => makeGetRequest(GET_TEACHER_ID_MODULE(programSlug)),
  );

  const [activeModule, setActiveModule] = useState();

  const {
    isOpen: programDrawableIsOpen,
    onOpen: programDrawableOnOpen,
    onClose: programDrawableOnClose,
  } = useDisclosure();

  const {
    isOpen: moduleManageIsOpen,
    onOpen: moduleManageOnOpen,
    onClose: moduleManageOnClose,
  } = useDisclosure();

  const handleManageClick = (module) => {
    setActiveModule(module);
    moduleManageOnOpen();
  };

  const handleManageClose = () => {
    moduleManageOnClose();
  };

  const handleNavigateToDrawable = (module) => {
    navigate(MAKE_TEACHER_PROGRAM_DETAIL_CONTENT(programSlug, module.id));
  };

  const handleContentOpen = (module) => {
    setActiveModule(module);
    programDrawableOnOpen();
  };

  const handleDrawableClose = () => {
    setActiveModule(null);
    programDrawableOnClose();
  };
  const handleContentClose = () => {
    navigate(MAKE_TEACHER_PROGRAM_DETAIL(programSlug));
    handleDrawableClose();
  };

  // const { searchDefaultProps } = useStudentSearch({ programSlug });

  const drawable = useBreakpointValue({ base: true, sm: false });

  useEffect(() => {
    if (programDetail && moduleSlug) {
      programDetail.data.modules.some((programData) => {
        if (programData.id === moduleSlug) {
          handleContentOpen(programData);
          return true;
        }
        return false;
      });
    }

    if (!moduleSlug) {
      handleDrawableClose();
    }
  }, [moduleSlug, programDetail]);

  // const pageBreadCrumbDetail = [
  //   {
  //     name: 'Your Batches',
  //     link: TEACHER_DASHBOARD,
  //   },
  //   {
  //     name: programDetail?.data?.batch?.identity,
  //     link: MAKE_TEACHER_PROGRAM_DETAIL(programSlug),
  //   },
  // ];
  return (
    <>
      {!programDetailIsLoading && (
        <>
          <ProgramTitleCard
            title="Course material"
            type={programDetail.data.batch?.identity}
          />
          <Box textTransform="capitalize">
            <Flex
              alignItems="center"
              mt={3}
              py={5}
              boxShadow={`0px 1px 0px 0px ${appColors.brandGrey['100']} inset;`}
              display={{ base: 'none', sm: 'flex' }}
              width="100%"
            >
              <Box
                style={{
                  flexBasis: '55%',
                }}
              >
                <AppText
                  display={{ base: 'none', sm: 'block' }}
                  title="Modules"
                  variant="tableHeader"
                />
              </Box>
              <Box
                style={{
                  flexBasis: '20%',
                }}
              >
                <AppText
                  display={{ base: 'none', sm: 'block' }}
                  title="Content"
                  variant="tableHeader"
                />
              </Box>
              {/* <Box
      style={{
        flexBasis: '20%',
      }}
    /> */}
              {/* <Box
      style={{
        flexBasis: '20%',
      }}
    >
      <AppText title="Module Access" variant="tableHeader" />
    </Box> */}
            </Flex>
            {programDetail.data.modules.map((module) => (
              <AppFlex
                customStyles={{
                  alignItems: 'center',
                }}
                variant="moduleListingCard"
                key={module.identity}
              >
                <Flex
                  // direction={{ base: 'column', sm: 'row' }}
                  alignItems={{ base: 'left', md: 'center', lg: 'center' }}
                  justifyContent={{ base: 'space-between', md: 'left' }}
                  flexBasis={{ base: '100%', sm: '80%' }}
                  onClick={
                    drawable
                      ? () => handleNavigateToDrawable(module)
                      : undefined
                  }
                >
                  <Box
                    flexBasis={{ base: '50%', sm: '70%' }}
                    ml={{ base: '20px', sm: '0px' }}
                  >
                    <AppText
                      title={`MODULE ${module.position}`}
                      size="xxs"
                      variant="light"
                    />
                    <AppText
                      title={module.identity}
                      size="sm"
                      onClick={() => handleNavigateToDrawable(module)}
                      customStyles={{
                        cursor: 'pointer',
                        width: 'fit-content',
                      }}
                    />
                  </Box>
                  <Flex
                    style={{
                      flexBasis: '20%',
                      cursor: 'pointer',
                      alignItems: 'center',
                    }}
                    onClick={() => handleNavigateToDrawable(module)}
                  >
                    <Flex>
                      <img src={CopyContentIcon} alt="" />
                      <AppText
                        title={module.content_count || '0'}
                        customStyles={{ ml: 2 }}
                      />
                    </Flex>
                  </Flex>
                  {/* <Box>
                    <GroupUserProfileImage
                      studentsDisplay={
                        module?.accessible_students_display || []
                      }
                    />
                  </Box> */}
                </Flex>
                {/* {programDetail?.data?.teacher
              ?.can_provide_live_class_module_access && (
              <Box
                style={{
                  flexBasis: '20%',
                }}
                onClick={() => handleManageClick(liveClassData)}
              >
                <Flex
                  style={{
                    cursor: 'pointer',
                  }}
                >
                  <img src={ManageLockIcon} alt="" />
                  <AppText title="Manage" size="xs" variant="manageLabel" />
                </Flex>
              </Box>
            )} */}
              </AppFlex>
            ))}
          </Box>
        </>
      )}
      {programDrawableIsOpen && (
        <TeacherProgramDetailDrawable
          isOpen={programDrawableIsOpen}
          onClose={handleContentClose}
          activeModule={activeModule.id}
          activeModuleName={activeModule.identity}
          activeStudentManage={handleManageClick}
          canManageAccess={
            programDetail?.data?.teacher?.can_provide_live_class_module_access
          }
        />
      )}
      {moduleManageIsOpen && (
        <ManageModuleAccessBulk
          isOpen={moduleManageIsOpen}
          onClose={handleManageClose}
          activeModule={activeModule.id}
          moduleName={activeModule.identity}
        />
      )}
    </>
  );
}

export default TeacherCourseMaterial;
