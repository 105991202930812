import { Box, CloseButton, Image } from '@chakra-ui/react';
import { useEffect, useMemo } from 'react';
import useGetFileData from 'version-two/hooks/useGetFileData';
import AppLoader from 'version-two/components/AppLoader/AppLoader';
import { useNavigate } from 'react-router-dom';
import getIsWebView from 'version-two/utils/getIsWebView';
import appColors from 'version-two/theme/appColors';

function AppImageViewer() {
  const navigate = useNavigate();
  const isWebView = useMemo(() => getIsWebView(), []);

  const { data, loading } = useGetFileData();

  useEffect(() => {
    // Prevent Right Click
    document.addEventListener('contextmenu', (e) => {
      e.preventDefault();
    });
  }, []);
  return (
    <AppLoader isLoading={loading}>
      {isWebView && (
        <CloseButton
          onClick={() => navigate(-1)}
          size="lg"
          style={{
            position: 'absolute',
            top: '15px',
            left: '15px',
          }}
        />
      )}
      {!loading && (
        <Box height="100vh" bg={appColors.brandGrey['500']}>
          <Image
            src={data}
            maxWidth="90%"
            height="100%"
            objectFit="contain"
            margin="auto"
          />
        </Box>
      )}
    </AppLoader>
  );
}

export default AppImageViewer;
