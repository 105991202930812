import { Box, Flex } from '@chakra-ui/react';
import AppText from 'version-two/components/Typography/AppText';
import AppBox from 'version-two/components/chakraLayoutOverrides/AppBox';
import TeacherDashboardLayout from 'version-two/layout/TeacherDashboardLayout';
import { useNavigate } from 'react-router-dom';
import { MAKE_TEACHER_PROGRAM_DETAIL } from 'version-two/navigation/routes';
import { useQuery } from 'react-query';
import { GET_TEACHER_LIST_PROGRAMS } from 'version-two/api/urls/teacher';
import makeGetRequest from 'version-two/api/utils/makeGetRequest';
import AppLoader from 'version-two/components/AppLoader/AppLoader';
import appColors from 'version-two/theme/appColors';

function TeacherProgramList() {
  const { data: programListData, isLoading: programListIsLoading } = useQuery(
    GET_TEACHER_LIST_PROGRAMS,
    () => makeGetRequest(GET_TEACHER_LIST_PROGRAMS),
  );

  // const programListDataCustom = {
  //   data: [
  //     {
  //       batchType: 'Regular Batches',
  //       related_live_classes: [
  //         {
  //           identity: 'Carnatic Grade 1',
  //           sessionFlowDate: 'Monday 6-7 am',
  //         },
  //         {
  //           identity: 'Carnatic Grade 1',
  //           sessionFlowDate: 'Tuesday 5-6 am',
  //         },
  //         {
  //           identity: 'Carnatic Grade 1',
  //           sessionFlowDate: 'Monday 6-7 am',
  //         },
  //         {
  //           identity: 'Carnatic Grade 1',
  //           sessionFlowDate: 'Tuesday 5-6 am',
  //         },
  //       ],
  //     },
  //     {
  //       batchType: 'Substitute Classes',
  //       related_live_classes: [
  //         {
  //           identity: 'Carnatic Grade 1',
  //           sessionFlowDate: 'Monday 6-7 am',
  //         },
  //         {
  //           identity: 'Carnatic Grade 1',
  //           sessionFlowDate: 'Tuesday 5-6 am',
  //         },
  //         {
  //           identity: 'Carnatic Grade 1',
  //           sessionFlowDate: 'Monday 6-7 am',
  //         },
  //         {
  //           identity: 'Carnatic Grade 1',
  //           sessionFlowDate: 'Tuesday 5-6 am',
  //         },
  //       ],
  //     },
  //   ],
  // };

  const navigate = useNavigate();
  return (
    <TeacherDashboardLayout>
      <AppLoader isLoading={programListIsLoading}>
        {!programListIsLoading && (
          // programListData.data.map((data) => (
          //   <Box key={data.identity} mb={5}>
          //     <AppText title={data.identity} variant="header" />

          //     <Flex flexWrap="wrap">
          //       {data.related_live_classes.map((moduleData) => (
          //         <AppBox
          //           variant="programCard"
          //           onClick={() => {
          //             navigate(MAKE_TEACHER_PROGRAM_DETAIL(moduleData.id));
          //           }}
          //           key={moduleData.identity}
          //         >
          //           <AppText title={moduleData.identity} size="md" />
          //           <AppText
          //             title={`${moduleData.modules_count} Modules`}
          //             variant="lightXS"
          //           />
          //         </AppBox>
          //       ))}
          //     </Flex>
          //   </Box>
          // )
          <Box>
            <Box mb={5}>
              <AppText title="Regular batches" variant="header" />
              <Flex flexWrap="wrap">
                {programListData.data.regular_batch_data.length !== 0 ? (
                  programListData.data.regular_batch_data.map((moduleData) => (
                    <AppBox
                      variant="programCard"
                      role="group"
                      onClick={() => {
                        navigate(MAKE_TEACHER_PROGRAM_DETAIL(moduleData.id));
                      }}
                      key={moduleData.identity}
                      className="maintxt"
                    >
                      <AppText
                        title={`${moduleData.week_day}s ${moduleData.batch_timings}`}
                        size="md"
                        groupHover={{ color: appColors.brandPrimary['300'] }}
                        className="txt"
                      />
                      <AppText
                        title={moduleData?.title_teacher}
                        variant="lightXS"
                      />
                    </AppBox>
                  ))
                ) : (
                  <AppText
                    title="You have no regular batches currently"
                    customStyles={{
                      fontSize: '20px',
                      margin: '15px 0px',
                      fontWeight: '500',
                    }}
                    variant="lightXS"
                  />
                )}
              </Flex>
            </Box>
            {programListData.data.substitute_batch_data.length !== 0 && (
              <Box mb={5}>
                <AppText title="Substitute batches" variant="header" />
                <Flex flexWrap="wrap">
                  {programListData.data.substitute_batch_data.map(
                    (moduleData) => (
                      <AppBox
                        variant="programCard"
                        onClick={() => {
                          navigate(MAKE_TEACHER_PROGRAM_DETAIL(moduleData.id));
                        }}
                        key={moduleData.identity}
                      >
                        <AppText
                          title={`${moduleData.week_day}s ${moduleData.batch_timings}`}
                          size="md"
                        />
                        <AppText
                          title={moduleData.title_teacher}
                          variant="lightXS"
                        />
                      </AppBox>
                    ),
                  )}
                </Flex>
              </Box>
            )}
          </Box>
        )}
      </AppLoader>
    </TeacherDashboardLayout>
  );
}

export default TeacherProgramList;
