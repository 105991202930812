import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { GET_STUDENT_LIVE_CLASS_RECORDINGS } from 'version-two/api/urls';
import makeGetRequest from 'version-two/api/utils/makeGetRequest';
import AppVideoPlayer from 'version-two/pages/common/AppVideoPlayer/AppVideoPlayer';

function WebRecordingsVideo() {
  const { week, programSlug } = useParams();
  const { data: studentProgramData, isLoading: studentProgramDataIsLoading } =
    useQuery(GET_STUDENT_LIVE_CLASS_RECORDINGS(programSlug), () =>
      makeGetRequest(GET_STUDENT_LIVE_CLASS_RECORDINGS(programSlug)),
    );

  return (
    <AppVideoPlayer
      loading={studentProgramDataIsLoading}
      url={studentProgramData?.data?.schedules?.[week].file}
    />
  );
}

export default WebRecordingsVideo;
