import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import appColors from 'version-two/theme/appColors';

const ScalerAnimation = keyframes`
  0%,
  100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
`;

const AppLoaderWrapper = styled.div`
  position: relative;
  height: 50px;
  width: 50px;

  &:before,
  &:after {
    content: '';
    width: inherit;
    height: inherit;
    border-radius: 50%;
    background-color: ${appColors.brandPrimary['300']};
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    animation: ${ScalerAnimation} 2s infinite ease-in-out;
  }

  &:after {
    animation-delay: -1s;
  }
`;

export { AppLoaderWrapper };
