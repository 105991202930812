import TeacherDashboardLayout from 'version-two/layout/TeacherDashboardLayout';
import ProgramTitleCard from 'version-two/components/ProgramTitleCard/ProgramTitleCard';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  GET_INDIVIDUAL_STUDENT_PROGRAM_DETAIL,
  GET_TEACHER_LIVE_CLASS_DETAIL,
  INDIVIDUAL_STUDENT_MODULE_COURSE_LOCK,
  INDIVIDUAL_STUDENT_MODULE_COURSE_UNLOCK,
} from 'version-two/api/urls';
import makeGetRequest from 'version-two/api/utils/makeGetRequest';
import { Box, Flex, Image, Stack, useDisclosure } from '@chakra-ui/react';
import AppText from 'version-two/components/Typography/AppText';
import AppFlex from 'version-two/components/chakraLayoutOverrides/AppFlex';
import { CopyContentIcon, LockDark } from 'version-two/assets';
import AppLoader from 'version-two/components/AppLoader/AppLoader';
import getFormattedDate from 'version-two/utils/getFormattedData';
import makePostRequest from 'version-two/api/utils/makePostRequest';
import useStudentSearch from 'version-two/hooks/useStudentSearch';
import { Select } from 'chakra-react-select';
import ConfirmationDialog from 'version-two/pages/teacherDashboard/TeacherProgramDetailSingleStudent/ConfirmationDialog';
import { useRef, useState } from 'react';
import {
  MAKE_TEACHER_PROGRAM_DETAIL,
  TEACHER_DASHBOARD,
} from 'version-two/navigation/routes';
import appColors from 'version-two/theme/appColors';
import { fontWeights } from 'version-two/theme/fonts';

const getStudentValue = (data) => [
  {
    label: data.name,
    value: data.id,
  },
];

function TeacherProgramDetailSingleStudent() {
  const { programSlug, studentId } = useParams();

  const [activeModule] = useState();

  const unlockDialogRef = useRef();
  const lockDialogRef = useRef();

  const queryClient = useQueryClient();
  const { data: programDetail, isLoading: programDetailIsLoading } = useQuery(
    GET_TEACHER_LIVE_CLASS_DETAIL(programSlug),
    () => makeGetRequest(GET_TEACHER_LIVE_CLASS_DETAIL(programSlug)),
  );
  const {
    data: studentProgramDetailData,
    isLoading: studentProgramDetailIsLoading,
  } = useQuery(
    GET_INDIVIDUAL_STUDENT_PROGRAM_DETAIL(programSlug, studentId),
    () =>
      makeGetRequest(
        GET_INDIVIDUAL_STUDENT_PROGRAM_DETAIL(programSlug, studentId),
      ),
  );

  const { mutate: moduleLockMutation } = useMutation(
    (body) => makePostRequest(INDIVIDUAL_STUDENT_MODULE_COURSE_LOCK, body),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(
          GET_INDIVIDUAL_STUDENT_PROGRAM_DETAIL(programSlug, studentId),
        ),
    },
  );

  const { mutate: moduleUnlockMutation } = useMutation(
    (body) => makePostRequest(INDIVIDUAL_STUDENT_MODULE_COURSE_UNLOCK, body),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(
          GET_INDIVIDUAL_STUDENT_PROGRAM_DETAIL(programSlug, studentId),
        ),
    },
  );

  const { isOpen: unlockDialogIsOpen, onClose: unlockDialogOnClose } =
    useDisclosure();

  const { isOpen: lockDialogIsOpen, onClose: lockDialogOnClose } =
    useDisclosure();

  const { searchDefaultProps } = useStudentSearch({ programSlug });

  const getRequestBody = (moduleId) => ({
    student: studentId,
    module: moduleId,
  });
  const handleModuleLock = (moduleId) => {
    moduleLockMutation(getRequestBody(moduleId));
    lockDialogOnClose();
  };

  const handleModuleUnlock = (moduleId) => {
    moduleUnlockMutation(getRequestBody(moduleId));
    unlockDialogOnClose();
  };

  const pageBreadCrumbDetail = [
    {
      name: 'Program',
      link: TEACHER_DASHBOARD,
    },
    {
      name: programDetail?.data?.identity,
      link: MAKE_TEACHER_PROGRAM_DETAIL(programSlug),
    },
  ];

  return (
    <TeacherDashboardLayout isProgram breadCrumb={pageBreadCrumbDetail}>
      {!programDetailIsLoading && (
        <ProgramTitleCard
          title={programDetail.data.identity}
          type={programDetail.data.category.identity}
        />
      )}

      <AppLoader isLoading={studentProgramDetailIsLoading}>
        {!studentProgramDetailIsLoading && (
          <>
            <Stack spacing={4} mt={5}>
              <Select
                {...searchDefaultProps}
                value={getStudentValue(studentProgramDetailData.data.student)}
              />
            </Stack>
            <Box>
              <Flex
                alignItems="center"
                mt={3}
                py={5}
                boxShadow={`0px 1px 0px 0px ${appColors.brandGrey['100']} inset;`}
                display={{ base: 'none', sm: 'flex' }}
              >
                <Flex
                  direction={{ base: 'column', sm: 'row' }}
                  style={{
                    flexBasis: '80%',
                  }}
                >
                  <Box
                    style={{
                      flexBasis: '45%',
                    }}
                  >
                    <AppText title="Title" variant="tableHeader" />
                  </Box>
                  <Box
                    style={{
                      flexBasis: '20%',
                    }}
                  >
                    <AppText title="Files" variant="tableHeader" />
                  </Box>
                </Flex>

                <Box
                  style={{
                    flexBasis: '30%',
                    textAlign: 'end',
                  }}
                >
                  <AppText title="Module Access" variant="tableHeader" />
                </Box>
              </Flex>
              {studentProgramDetailData.data.modules.map((liveClassData) => (
                <AppFlex
                  customStyles={{
                    alignItems: 'center',
                  }}
                  variant="moduleListingCard"
                  key={liveClassData.identity}
                >
                  <Flex
                    direction={{ base: 'column', sm: 'row' }}
                    style={{
                      flexBasis: '60%',
                    }}
                  >
                    <Box
                      style={{
                        flexBasis: '55%',
                      }}
                    >
                      <AppText
                        title={`MODULE ${liveClassData.position}`}
                        size="xxs"
                        variant="light"
                      />
                      <AppText
                        title={liveClassData.identity}
                        size="sm"
                        color={
                          !liveClassData.is_access_granted &&
                          appColors.brandGrey['400']
                        }
                      />
                    </Box>
                    <Box
                      display={{ base: 'none', sm: 'block' }}
                      style={{
                        flexBasis: '10%',
                        cursor: 'pointer',
                      }}
                    >
                      <Flex>
                        <img src={CopyContentIcon} alt="" />
                        <AppText
                          title={liveClassData.content_count || '0'}
                          customStyles={{ ml: 2 }}
                        />
                      </Flex>
                    </Box>
                    <Box
                      display={{ base: 'block', sm: 'none' }}
                      style={{
                        flexBasis: '20%',
                      }}
                    >
                      {liveClassData.is_access_granted ? (
                        <AppText
                          title={`Unlocked on ${getFormattedDate(
                            liveClassData.access_granted_on,
                          )}`}
                          size="xs"
                        />
                      ) : (
                        <Flex justifyContent="start" alignItems="center">
                          <Image src={LockDark} mr={2} />
                          <AppText
                            title="Locked"
                            color={appColors.brandGrey['500']}
                            size="xs"
                          />
                        </Flex>
                      )}
                    </Box>
                  </Flex>
                  {liveClassData.is_access_granted ? (
                    <Box
                      style={{
                        flexBasis: '40%',
                      }}
                    >
                      <Flex
                        justifyContent="end"
                        alignItems="center"
                        style={{
                          cursor: 'pointer',
                        }}
                      >
                        <AppText
                          title={`Unlocked on ${getFormattedDate(
                            liveClassData.access_granted_on,
                          )}`}
                          customStyles={{
                            display: { base: 'none', sm: 'inline' },
                            fontSize: { sm: '11px', md: '13px' },
                          }}
                        />
                        {/* <Menu> */}
                        {/*  <MenuButton as={Button} variant="tripleIconButton"> */}
                        {/*    <Image src={TripleDotsIcon} margin="auto" /> */}
                        {/*  </MenuButton> */}
                        {/*  <MenuList> */}
                        {/*    <MenuItem */}
                        {/*      onClick={() => { */}
                        {/*        setActiveModule(liveClassData); */}
                        {/*        lockDialogOnOpen(); */}
                        {/*      }} */}
                        {/*    > */}
                        {/*      Lock access */}
                        {/*    </MenuItem> */}
                        {/*  </MenuList> */}
                        {/* </Menu> */}
                      </Flex>
                    </Box>
                  ) : (
                    <div />
                    // ---------- COMMENTED BASED ON CLIENT REQUIREMENT -------------
                    // <Box
                    //   style={{
                    //     flexBasis: '40%',
                    //   }}
                    // >
                    //   <Flex justifyContent="end" alignItems="center">
                    //     <Flex
                    //       justifyContent="end"
                    //       alignItems="center"
                    //       display={{ base: 'none', sm: 'flex' }}
                    //       style={{
                    //         flexBasis: '40%',
                    //       }}
                    //     >
                    //       <Image src={LockDark} mr={2} />
                    //       <AppText
                    //         title="Locked"
                    //         color={appColors.brandGrey['500']}
                    //         customStyles={{
                    //           fontSize: { sm: '11px', md: '13px' },
                    //         }}
                    //       />
                    //     </Flex>
                    //     {studentProgramDetailData.data.student
                    //       .live_class_status === 'active' && (
                    //       <Menu>
                    //         <MenuButton as={Button} variant="tripleIconButton">
                    //           <Image src={TripleDotsIcon} margin="auto" />
                    //         </MenuButton>
                    //         <MenuList>
                    //           <MenuItem
                    //             onClick={() => {
                    //               unlockDialogOnOpen();
                    //               setActiveModule(liveClassData);
                    //             }}
                    //           >
                    //             Unlock
                    //           </MenuItem>
                    //         </MenuList>
                    //       </Menu>
                    //     )}
                    //   </Flex>
                    // </Box>
                  )}
                </AppFlex>
              ))}
            </Box>
            {studentProgramDetailData.data.student.live_class_status ===
              'break' && (
              <AppText
                title="Note: This student is on a break"
                customStyles={{ mt: 3, fontWeight: fontWeights.semibold }}
                variant="lightXS"
              />
            )}
          </>
        )}
      </AppLoader>
      {unlockDialogIsOpen && (
        <ConfirmationDialog
          leastDestructiveRef={unlockDialogRef}
          isOpen={unlockDialogIsOpen}
          onClose={unlockDialogOnClose}
          title="Unlock Access"
          handleSuccessClick={() => handleModuleUnlock(activeModule.id)}
          description={`${studentProgramDetailData.data.student.name} will 
          be able to access all files in the module ${activeModule.identity}. 
          Please note that this doesnt give access to your Teachers Notes for 
          this module, which you may want to share individually.`}
          successBtnText="Unlock"
        />
      )}

      {lockDialogIsOpen && (
        <ConfirmationDialog
          leastDestructiveRef={lockDialogRef}
          isOpen={lockDialogIsOpen}
          onClose={lockDialogOnClose}
          title="Lock Access"
          handleSuccessClick={() => handleModuleLock(activeModule.id)}
          description={`${studentProgramDetailData.data.student.name} will not 
          be able to access the module ${activeModule.identity} after this.
          Please confirm that you have not started teaching this 
          module to him/her.`}
          successBtnText="Lock"
        />
      )}
    </TeacherDashboardLayout>
  );
}

export default TeacherProgramDetailSingleStudent;
