import AppVideoPlayer from 'version-two/pages/common/AppVideoPlayer/AppVideoPlayer';
import { useParams } from 'react-router-dom';
import { GET_TEACHER_ID_SCHEDULE } from 'version-two/api/urls/teacher';
import makeGetRequest from 'version-two/api/utils/makeGetRequest';
import { useQuery } from 'react-query';

function AppClassRecordingsWrapper() {
  const { programSlug, weekId } = useParams();

  const { data: programDetail } = useQuery(
    GET_TEACHER_ID_SCHEDULE(programSlug),
    () => makeGetRequest(GET_TEACHER_ID_SCHEDULE(programSlug)),
  );
  const targetedWeek = programDetail?.data?.schedules.filter(
    (weekData) => weekData.id === weekId,
  );
  return (
    targetedWeek && (
      <AppVideoPlayer url={targetedWeek[0].file} loading={false} />
    )
  );
}

export default AppClassRecordingsWrapper;
