import { useMutation } from 'react-query';
import makeGetRequest from 'version-two/api/utils/makeGetRequest';
import { GET_FILE_DATA_TEACHER } from 'version-two/api/urls';
import { useParams } from 'react-router-dom';
import { APP_STUDENT, APP_TEACHER } from 'version-two/utils/constants';
import getFileDatafromAPI from 'version-two/utils/getFileDatafromAPI';
import { useEffect } from 'react';

const useHandleFileOpen = () => {
  const { contentSlug, userType, pitch } = useParams();

  const {
    mutate: intiateFileOpenTeacher,
    data: fileDataTeacher,
    isLoading: fileDataTeacherLoading,
  } = useMutation(() =>
    makeGetRequest(GET_FILE_DATA_TEACHER(contentSlug), {
      pitch: pitch === 'no-pitch' ? undefined : pitch,
    }),
  );

  const {
    mutate: initiateFileOpenStudent,
    data: fileDataStudent,
    isLoading: fileDataStudentIsLoading,
  } = useMutation(() =>
    makeGetRequest(GET_FILE_DATA_TEACHER(contentSlug), {
      pitch: pitch === 'no-pitch' ? undefined : pitch,
    }),
  );

  useEffect(() => {
    document.addEventListener('contextmenu', (e) => {
      e.preventDefault();
    });
    document.onkeydown = (e) => {
      if (e.keyCode === 123) {
        return false;
      }
      if (e.ctrlKey && e.shiftKey && e.keyCode === 'I'.charCodeAt(0)) {
        return false;
      }
      if (e.ctrlKey && e.shiftKey && e.keyCode === 'C'.charCodeAt(0)) {
        return false;
      }
      if (e.ctrlKey && e.shiftKey && e.keyCode === 'J'.charCodeAt(0)) {
        return false;
      }
      if (e.ctrlKey && e.keyCode === 'U'.charCodeAt(0)) {
        return false;
      }
      return e;
    };
  }, []);

  useEffect(() => {
    if (userType === APP_TEACHER) {
      intiateFileOpenTeacher();
    } else if (userType === APP_STUDENT) {
      initiateFileOpenStudent();
    }
  }, []);

  const data = getFileDatafromAPI(fileDataTeacher, fileDataStudent);

  return {
    data,
    loading: fileDataStudentIsLoading || fileDataTeacherLoading,
  };
};

export default useHandleFileOpen;
