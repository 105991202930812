import AppLoader from 'version-two/components/AppLoader/AppLoader';
import { CloseButton } from '@chakra-ui/react';
import useGetFileData from 'version-two/hooks/useGetFileData';
import getIsWebView from 'version-two/utils/getIsWebView';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

function AppAudioPlayer() {
  const { data, loading } = useGetFileData();
  const isWebView = useMemo(() => getIsWebView(), []);
  const navigate = useNavigate();
  return (
    <AppLoader isLoading={loading}>
      {!loading && (
        <>
          {isWebView && (
            <CloseButton
              onClick={() => navigate(-1)}
              size="md"
              style={{
                position: 'absolute',
                top: '10px',
                left: '10px',
              }}
              zIndex="100"
            />
          )}
          {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
          <div
            style={{
              // border: "1px solid red",
              height: '95vh',
              width: '80%',
              margin: 'auto',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <audio
              controls
              onContextMenu="return false;"
              controlsList="nodownload"
              style={{ width: '100%' }}
              loop
            >
              <source
                type="audio/mp3"
                // src="https://voxguru-v2.s3.amazonaws.com/files/common/file_example_MP3_700KB.mp3"
                src={data}
              />
              <source type="audio/x-m4a" src={data} />
            </audio>
          </div>
        </>
      )}
    </AppLoader>
  );
}

export default AppAudioPlayer;
