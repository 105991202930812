import { fontSizes, fontWeights, lineHeights } from 'version-two/theme/fonts';
import appColors from 'version-two/theme/appColors';
import { appBorderRadius, appBreakPoints } from 'version-two/theme/sizes';

const AppTextStyles = {
  sizes: {
    xxs: {
      fontSize: fontSizes.xxs,
    },
    md: {
      fontSize: fontSizes.md,
      fontWeight: fontWeights.md,
      lineHeight: lineHeights.md,
    },
    sm: {
      fontSize: fontSizes.sm,
      fontWeight: fontWeights.semibold,
      lineHeight: lineHeights.sm,
    },
    xs: {
      fontSize: fontSizes.xs,
      fontWeight: fontWeights.sm,
      lineHeight: lineHeights.xs,
    },
  },
  variants: {
    bold: {
      fontWeight: fontWeights.bold,
    },
    extraBold: {
      fontWeight: fontWeights.extrabold,
    },
    header: {
      fontSize: fontSizes.xl,
      fontWeight: fontWeights.xl,
      lineHeight: lineHeights.xl,
      color: appColors.brandGrey['900'],
    },

    navbarBreadCrumb: {
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.sm,
      fontWeight: fontWeights.bold,

      ml: 2,

      color: appColors.brandGrey['500'],
    },
    navbar: {
      fontSize: fontSizes.sm,
      fontWeight: fontWeights.extrabold,

      color: appColors.brandGrey['900'],

      margin: 'auto',
      marginX: 1,
    },

    errorMessage: {
      fontSize: fontSizes.xs,
      fontWeight: fontWeights.bold,

      color: appColors.brandRed['100'],
      marginY: 3,

      textAlign: 'center',
    },
    label: {
      fontSize: fontSizes.xs,
      fontWeight: fontWeights.sm,
      lineHeight: lineHeights.xs,
      marginBottom: 1,
      color: appColors.brandGrey['700'],
    },

    light: {
      color: appColors.brandGrey['300'],
      fontWeight: fontWeights.bold,
    },

    lightXS: {
      color: appColors.brandGrey['300'],

      fontSize: fontSizes.xs,
      lineHeight: lineHeights.xs,
      fontWeight: fontWeights.bold,
    },

    tile: {
      color: appColors.brandPrimary['300'],
      backgroundColor: appColors.brandWhite['900'],

      padding: 1,

      textTransform: 'uppercase',

      fontSize: 9,
      fontWeight: fontWeights.xl,
      lineHeight: '12px',

      borderRadius: appBorderRadius.xs,
    },

    programHeader: {
      color: appColors.brandWhite['900'],

      fontSize: { base: fontSizes.xl, md: fontSizes.xxl },
      fontWeight: fontWeights.xl,
      lineHeight: lineHeights.xxl,

      marginTop: 1,
    },

    manageLabel: {
      display: 'inline',

      marginLeft: 2,

      color: appColors.brandSecondary['500'],

      fontWeight: fontWeights.xl,

      cursor: 'pointer',

      [`@media screen and (max-width: ${appBreakPoints.sm})`]: {
        display: 'none',
      },
    },

    tableHeader: {
      fontSize: fontSizes.xxs,
      fontWeight: fontWeights.bold,

      color: appColors.brandGrey['900'],

      lineHeight: '12px',
      textTransform: 'uppercase',
    },

    programDrawableSubTitle: {
      fontSize: fontSizes.sm,
      fontWeight: fontWeights.bold,

      lineHeight: lineHeights.sm,

      color: appColors.brandGrey['500'],
    },

    navbarActive: {
      fontSize: fontSizes.sm,
      fontWeight: fontWeights.extrabold,

      color: appColors.brandGrey['900'],
    },

    headerLargeLight: {
      fontSize: '23px',
      lineHeight: '32px',

      fontWeight: fontWeights.semibold,

      color: appColors.brandGrey['500'],
    },

    lightXXS: {
      fontSize: fontSizes.xxs,
      fontWeight: fontWeights.bold,

      lineHeight: lineHeights.xxs,

      color: appColors.brandGrey['500'],
    },

    orangeXXS: {
      fontSize: fontSizes.xxs,
      fontWeight: fontWeights.bold,

      lineHeight: lineHeights.xxs,

      color: appColors.brandPrimary['300'],
    },

    mobileNavbarText: {
      fontSize: fontSizes.md,
      fontWeight: fontWeights.xl,

      lineHeight: lineHeights.md,

      color: appColors.brandGrey['700'],

      textAlign: 'center',

      padding: '0.875rem',
      marginInline: 'auto',

      position: 'relative',
      right: '1em',
    },

    mobileNavbarTitle: {
      fontSize: fontSizes.sm,
      fontWeight: fontWeights.bold,

      lineHeight: lineHeights.sm,

      color: appColors.brandGrey['700'],
    },

    breakLine: {
      fontSize: fontSizes.xxl,

      color: appColors.brandGrey['100'],

      mx: 2,

      fontWeight: fontWeights.thin,
    },

    contactSupport: {
      fontSize: fontSizes.md,
      fontWeight: fontWeights.bold,
      lineHeight: lineHeights.md,

      color: appColors.brandSecondary['500'],
    },

    completed: {
      color: appColors.brandGrey['500'],
    },

    upcoming: {
      color: appColors.brandRed['100'],
    },
    zoomSubHead: {
      fontSize: { base: '14px', sm: '19px' },
      fontWeight: '600',
    },
    zoomHead: {
      fontSize: { base: '16px', sm: '23px' },
      fontWeight: '700',
    },
  },
};

export default AppTextStyles;
