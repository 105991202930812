import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useBreakpointValue,
} from '@chakra-ui/react';
import AppText from 'version-two/components/Typography/AppText';
import { fontWeights } from 'version-two/theme/fonts';
import appColors from 'version-two/theme/appColors';
import AppSelectCustomMenuList from 'version-two/components/AppSelect/AppSelectCustomMenuList';
import { useMutation, useQuery } from 'react-query';
import {
  GET_INACCESSIBLE_STUDENTS_LIST,
  SHARE_NOTE_BULK_ACCESS,
} from 'version-two/api/urls';
import makeGetRequest from 'version-two/api/utils/makeGetRequest';
import AppLoader from 'version-two/components/AppLoader/AppLoader';
import SingleStudentItem from 'version-two/components/ManageModuleAccessBulk/SingleStudentItem';
import { useRef, useState } from 'react';
// import makePatchRequest from 'version-two/api/utils/makePostRequest';
// import makePatchRequest from 'version-two/api/utils/getPathRequest';
import makePostRequest from 'version-two/api/utils/makePostRequest';
import AppSelect from 'version-two/components/AppSelect/AppSelect';
import AppSelectClearIndicator from 'version-two/components/AppSelect/AppSelectClearIndicator';
import { useParams } from 'react-router-dom';
import getFormattedDate from 'version-two/utils/getFormattedData';

const getSelectOptionsForStudents = (data) =>
  (data || []).map((student) => ({
    label: student.name || student.student_name,
    value: student.id || student.student_id,
    isDisabled: !!student?.access_given_date,
    unlockedDate:
      student?.access_given_date &&
      `Unlocked on ${getFormattedDate(student?.access_given_date)}`,
  }));

function TeacherNoteShareDialog({
  noteName,
  isOpen,
  onClose,
  activeModule,
  shareCount,
  studentsWithAccess,
}) {
  const [selectedStudents, setSelectedStudents] = useState([]);
  // console.log('selectedStudents', selectedStudents);

  // useEffect(() => {
  //   const existingStudentsState = existingStudents.map((studentData) => ({
  //     label: studentData.name,
  //     value: studentData.id,
  //   }));
  //   setSelectedStudents(existingStudentsState);
  // }, []);

  // const getSelectOptionsForStudents = (data) => {
  //   const AllStudentsList = [...data, ...studentsWithAccess];
  //   console.log('AllStudentsList', AllStudentsList);
  //   console.log('data', data);

  //   // const optionsFormat = data?.map((studentData) => ({
  //   //   label: studentData.name,
  //   //   value: studentData.id,
  //   // }));
  //   // const newItems = optionsFormat.filter(
  //   //   (allStudent) =>
  //   //     !existingStudents.some(
  //   //       (existingStu) => existingStu.student_id === allStudent.id,
  //   //     ),
  //   // );
  //   return getSelectOptionsForStudents;
  // };

  const { programSlug } = useParams();

  const selectRef = useRef();

  const { data: studentData, isLoading: studentDataIsLoading } = useQuery(
    GET_INACCESSIBLE_STUDENTS_LIST(programSlug),
    () =>
      makeGetRequest(GET_INACCESSIBLE_STUDENTS_LIST(programSlug, activeModule)),
  );

  const { mutate: shareNoteMutation, isLoading: shareNoteMutationIsLoading } =
    useMutation(
      (body) => makePostRequest(SHARE_NOTE_BULK_ACCESS(activeModule), body),
      {
        onSuccess: () => window?.location.reload(),
      },
    );

  const handleStudentUnlock = () => {
    const users = selectedStudents.map((data) => data.value);

    const reqBody = {
      batch_id: programSlug,
      students: users,
    };

    shareNoteMutation(reqBody);

    onClose();

    setSelectedStudents([]);

    selectRef.current.blur();
  };

  const handleListClick = (individualStudentData) => {
    // console.log('individualStudentData', individualStudentData);
    // const processedData = getSelectOptionsForStudents([individualStudentData]);
    setSelectedStudents([...selectedStudents, individualStudentData]);
  };

  const modalSize = useBreakpointValue({ base: 'full', sm: 'md', md: 'lg' });

  // const getNumberOfSharedStudentsData = (studentsData) => {
  //   let totalCount = 0;
  //   if (Array.isArray(studentsData) && studentsData.length > 0) {
  //     studentsData.map((stData) => {
  //       if (stData.is_access_granted) {
  //         totalCount += 1;
  //       }
  //       return totalCount;
  //     });
  //   }
  //   return totalCount;
  // };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size={modalSize}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <AppText
            title="Share note"
            size="md"
            customStyles={{
              fontWeight: fontWeights.bold,
            }}
          />
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <AppLoader isLoading={studentDataIsLoading} height="100%">
            {!studentDataIsLoading && (
              <>
                <AppText
                  title={`Share "${noteName}" with`}
                  size="xs"
                  customStyles={{
                    color: appColors.brandGrey['500'],
                    mb: 2,
                  }}
                />

                <AppSelect
                  isMulti
                  value={selectedStudents}
                  onChange={(value) => setSelectedStudents(value)}
                  options={
                    studentData?.data &&
                    getSelectOptionsForStudents([
                      ...studentData.data,
                      ...studentsWithAccess,
                    ])
                  }
                  ref={selectRef}
                  customComponents={{
                    Option: AppSelectCustomMenuList,
                    // eslint-disable-next-line react/no-unstable-nested-components
                    ClearIndicator: (props) => (
                      <AppSelectClearIndicator
                        {...props}
                        isLoading={shareNoteMutationIsLoading}
                        handleClick={handleStudentUnlock}
                      />
                    ),
                    DropdownIndicator: null,
                  }}
                />

                <AppText
                  title={`NOTES SHARED WITH ${shareCount} ACTIVE STUDENTS`}
                  size="xs"
                  customStyles={{
                    fontWeight: fontWeights.extrabold,
                    color: appColors.brandGrey['900'],
                    my: 3,
                  }}
                />

                {getSelectOptionsForStudents([
                  ...studentData.data,
                  ...studentsWithAccess,
                ]).map((individualData) => (
                  <SingleStudentItem
                    image={individualData.live_class_profile_photo}
                    name={individualData.label}
                    unlockDate={getFormattedDate(individualData?.unlockedDate)}
                    datePreText="Shared On"
                    instanceData={individualData}
                    handleOnClick={handleListClick}
                    disabled={individualData?.isDisabled}
                    batchId={individualData?.batch_id}
                  />
                ))}
              </>
            )}
          </AppLoader>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default TeacherNoteShareDialog;
