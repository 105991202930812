import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import React from 'react';

function AppModal({
  isOpen,
  onClose,
  className,
  headerClassName,
  header,
  closeButton,
  bodyClassName,
  children,
  size = 'xl',
}) {
  const modalSizeProp = size
    ? {
        size,
      }
    : {};
  return (
    <Modal isOpen={isOpen} onClose={onClose} {...modalSizeProp}>
      <ModalOverlay />
      <ModalContent className={className}>
        {header ? (
          <ModalHeader className={headerClassName}>{header}</ModalHeader>
        ) : null}
        {closeButton ? <ModalCloseButton /> : null}
        <ModalBody className={bodyClassName}>{children}</ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default AppModal;
