import { Box, Flex } from '@chakra-ui/react';
import AppText from 'version-two/components/Typography/AppText';
import AppBox from 'version-two/components/chakraLayoutOverrides/AppBox';
import TeacherDashboardLayout from 'version-two/layout/TeacherDashboardLayout';
import { useNavigate } from 'react-router-dom';
import { MAKE_TEACHER_PROGRAM_DETAIL } from 'version-two/navigation/routes';
import { useQuery } from 'react-query';
import { GET_TEACHER_LIST_PROGRAMS } from 'version-two/api/urls/teacher';
import makeGetRequest from 'version-two/api/utils/makeGetRequest';
import AppLoader from 'version-two/components/AppLoader/AppLoader';
import appColors from 'version-two/theme/appColors';

function TeacherCompletedProgram() {
  const { data: programListData, isLoading: programListIsLoading } = useQuery(
    GET_TEACHER_LIST_PROGRAMS,
    () => makeGetRequest(GET_TEACHER_LIST_PROGRAMS),
  );

  const navigate = useNavigate();
  return (
    <TeacherDashboardLayout>
      <AppLoader isLoading={programListIsLoading}>
        {!programListIsLoading && (
          <Box>
            <Box mb={5}>
              <AppText title="Completed batches" variant="header" />
              <Flex flexWrap="wrap">
                {programListData?.data?.completed_batch_data.length !== 0 ? (
                  programListData?.data?.completed_batch_data.map(
                    (moduleData) => (
                      <AppBox
                        variant="programCard"
                        role="group"
                        onClick={() => {
                          navigate(MAKE_TEACHER_PROGRAM_DETAIL(moduleData.id));
                        }}
                        key={moduleData.identity}
                        className="maintxt"
                      >
                        <AppText
                          title={`${moduleData?.week_day}s ${moduleData?.batch_timings}`}
                          size="md"
                          groupHover={{ color: appColors.brandPrimary['300'] }}
                          className="txt"
                        />
                        {/* <AppText
                          title={`${moduleData?.batch_id}`}
                          size="md"
                          groupHover={{ color: appColors.brandPrimary['300'] }}
                          className="txt"
                        /> */}
                        <AppText
                          title={moduleData?.title_teacher}
                          variant="lightXS"
                        />
                      </AppBox>
                    ),
                  )
                ) : (
                  <AppText
                    title="You have no completed batches"
                    customStyles={{
                      fontSize: '20px',
                      margin: '15px 0px',
                      fontWeight: '500',
                    }}
                    variant="lightXS"
                  />
                )}
              </Flex>
            </Box>
          </Box>
        )}
      </AppLoader>
    </TeacherDashboardLayout>
  );
}

export default TeacherCompletedProgram;
