import styled from '@emotion/styled';

const PdfWrapperBlock = styled.div`
  display: flex;

  align-items: center;
  justify-content: center;

  max-width: 600px;
  margin: auto;
  margin-top: 40px;

  position: relative;
`;

const IconWrapper = styled.div`
  display: flex;
  position: absolute;
  bottom: 40px;
  right: ${(props) => (props.isMobile ? '37%' : '40%')};
  align-items: center;
`;
export { PdfWrapperBlock, IconWrapper };
