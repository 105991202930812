import { Flex, useStyleConfig } from '@chakra-ui/react';

function AppFlex({
  size,
  variant,
  customStyles,
  onClick,
  children,
  className,
}) {
  const styles = useStyleConfig('AppFlex', { size, variant });
  return (
    <Flex className={className} sx={styles} onClick={onClick} {...customStyles}>
      {children}
    </Flex>
  );
}
export default AppFlex;
