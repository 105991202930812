import ProgramDrawableHeader from 'voxguru-v1/components/ProgramDrawableHeader/ProgramDrawableHeader';
import AppBox from 'voxguru-v1/components/chakraLayoutOverrides/AppBox';
import AppText from 'voxguru-v1/components/Typography/AppText';
import {
  Drawer,
  DrawerContent,
  DrawerOverlay,
  ListItem,
  UnorderedList,
  useDisclosure,
  useBreakpointValue,
} from '@chakra-ui/react';
import AppFlex from 'voxguru-v1/components/chakraLayoutOverrides/AppFlex';
import AppButton from 'voxguru-v1/components/Typography/AppButton';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import appColors from 'voxguru-v1/theme/appColors';
import { Mp3Icon } from 'voxguru-v1/assets';
import FileUploadDialog from 'voxguru-v1/components/FileUploadDialog/FileUploadDialog';
import { useRef } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  GET_TEACHER_LIVE_CLASS_MODULE_IN_DETAIL,
  LIVE_CLASS_TEACHER_FILE_UPLOAD,
} from 'voxguru-v1/api/urls/teacher';
import makeGetRequest from 'voxguru-v1/api/utils/makeGetRequest';
import AppLoader from 'voxguru-v1/components/AppLoader/AppLoader';
import makePostRequest from 'voxguru-v1/api/utils/makePostRequest';
import ContentTitleCard from 'voxguru-v1/components/ContentTitleCard/ContentTitleCard';

function TeacherProgramDetailDrawable({
  isOpen,
  onClose,
  activeModule,
  activeModuleName,
  activeStudentManage,
  canManageAccess,
}) {
  const {
    isOpen: fileDialogIsOpen,
    onOpen: fileDialogOnOpen,
    onClose: fileDialogOnClose,
  } = useDisclosure();

  const fileDialogCancelRef = useRef();

  const { data: liveClassModuleData, isLoading: liveClassModuleDataIsLoading } =
    useQuery(GET_TEACHER_LIVE_CLASS_MODULE_IN_DETAIL(activeModule), () =>
      makeGetRequest(GET_TEACHER_LIVE_CLASS_MODULE_IN_DETAIL(activeModule)),
    );

  const queryClient = useQueryClient();
  const { mutate: teacherFileUploadMutate } = useMutation(
    (body) =>
      makePostRequest(LIVE_CLASS_TEACHER_FILE_UPLOAD(activeModule), body),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(
          GET_TEACHER_LIVE_CLASS_MODULE_IN_DETAIL(activeModule),
        ),
    },
  );

  const handleTeacherNoteUpload = ({ fileData, name }) => {
    const reqBody = {
      identity: name,
      file: fileData.id,
    };

    teacherFileUploadMutate(reqBody);
    fileDialogOnClose();
  };
  const drawerSize = useBreakpointValue({ base: 'xs', sm: 'sm', md: 'md' });
  return (
    <>
      <Drawer
        isOpen={isOpen}
        onClose={onClose}
        placement="right"
        size={drawerSize}
      >
        <DrawerOverlay>
          <DrawerContent overflow="scroll">
            <AppLoader isLoading={liveClassModuleDataIsLoading}>
              {!liveClassModuleDataIsLoading && (
                <>
                  <ProgramDrawableHeader
                    programModule={`MODULE ${liveClassModuleData.data.position}`}
                    programTitle={liveClassModuleData.data.identity}
                  />

                  <AppBox variant="programDrawableBlock">
                    <AppText
                      title="Objectives"
                      variant="programDrawableSubTitle"
                    />
                    <UnorderedList>
                      {Array.isArray(liveClassModuleData.data.objectives) &&
                        liveClassModuleData.data.objectives.map((data) => (
                          <ListItem>
                            <AppText title={data} size="xs" />
                          </ListItem>
                        ))}
                    </UnorderedList>
                  </AppBox>

                  {/* Active Students */}
                  <AppFlex variant="programDrawableBlock">
                    <AppText
                      title={`Active Students 
                      (${
                        liveClassModuleData.data.accessible_students_count || 0
                      })`}
                      variant="programDrawableSubTitle"
                    />
                    {canManageAccess && (
                      <AppButton
                        title="Manage"
                        variant="programDrawableButton"
                        onClick={() =>
                          activeStudentManage({
                            id: activeModule,
                            identity: activeModuleName,
                          })
                        }
                      />
                    )}
                  </AppFlex>

                  {/* Core Content */}
                  <AppBox variant="programDrawableBlock">
                    <AppFlex
                      customStyles={{
                        alignItems: 'center',
                      }}
                    >
                      <AppText
                        title="Core Content"
                        variant="programDrawableSubTitle"
                      />
                      <InfoOutlineIcon
                        ml={2}
                        color={appColors.brandGrey['200']}
                      />
                    </AppFlex>

                    {Array.isArray(
                      liveClassModuleData.data.related_core_contents,
                    ) &&
                      liveClassModuleData.data.related_core_contents.map(
                        (data) => (
                          <ContentTitleCard
                            file={data.file}
                            icon={Mp3Icon}
                            fileId={data.id}
                            title={data.identity}
                            corecontent
                          />
                        ),
                      )}
                  </AppBox>

                  {/* Teachers Note */}
                  <AppFlex variant="programDrawableBlock">
                    <AppFlex
                      customStyles={{
                        alignItems: 'center',
                      }}
                    >
                      <AppText
                        title="Teachers Note"
                        variant="programDrawableSubTitle"
                      />
                      <InfoOutlineIcon
                        ml={2}
                        color={appColors.brandGrey['200']}
                      />
                    </AppFlex>

                    <AppButton
                      title="Upload"
                      variant="programDrawableButton"
                      onClick={fileDialogOnOpen}
                    />
                  </AppFlex>
                  <AppBox variant="programDrawableBlock">
                    {liveClassModuleData.data.related_teacher_contents.map(
                      (teacherNoteData) => (
                        <ContentTitleCard
                          file={teacherNoteData.file}
                          fileId={teacherNoteData.id}
                          title={teacherNoteData.identity}
                          shareCount={teacherNoteData.accessible_students_count}
                          activeModule={activeModule}
                          hasShare
                        />
                      ),
                    )}
                  </AppBox>
                </>
              )}
            </AppLoader>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>

      <FileUploadDialog
        isOpen={fileDialogIsOpen}
        onClose={fileDialogOnClose}
        leastDestructiveRef={fileDialogCancelRef}
        fileUploadCallback={handleTeacherNoteUpload}
      />
    </>
  );
}
export default TeacherProgramDetailDrawable;
