import { components as reactSelectComponents } from 'chakra-react-select';
import AppButton from 'version-two/components/Typography/AppButton';

function AppSelectClearIndicator({ isLoading, handleClick, ...props }) {
  const customProps = props;
  customProps.innerProps.onTouchEnd = handleClick;
  customProps.innerProps.onMouseDown = handleClick;
  customProps.clearValue = handleClick;

  return (
    <reactSelectComponents.ClearIndicator {...customProps}>
      <AppButton
        {...props}
        isLoading={isLoading}
        onClick={handleClick}
        title="Share"
      />
    </reactSelectComponents.ClearIndicator>
  );
}

export default AppSelectClearIndicator;
