import appColors from 'version-two/theme/appColors';
import { appBorderRadius } from 'version-two/theme/sizes';

const AppFlexStyles = {
  variants: {
    appNavbar: {
      height: '80px',

      px: 10,
      mb: 5,

      alignItems: 'center',

      boxShadow: `0px 1px 0px ${appColors.brandGrey['100']}`,
    },

    appMobileNavbar: {
      height: '50px',
      mb: 5,
      px: 3,

      alignItems: 'center',

      boxShadow: `0px 1px 0px ${appColors.brandGrey['100']}`,
    },
    programTitleHeader: {
      minHeight: { base: '104px', sm: '130px' },
      width: '100%',

      flexFlow: 'column',
      alignItems: 'center',
      justifyContent: 'center',

      borderRadius: appBorderRadius.md,

      backgroundColor: appColors.brandPrimary['300'],

      marginTop: { base: '0px', sm: '40px' },
    },

    programDrawableHeader: {
      paddingY: 3,
      paddingX: 5,

      justifyContent: 'space-between',
      alignItems: 'center',

      boxShadow: '0px -1px 0px 0px #F2F2F2 inset;',
    },

    programDrawableBlock: {
      paddingX: 5,
      paddingY: 3,

      justifyContent: 'space-between',
      alignItems: 'center',
    },

    contentItem: {
      border: `2px solid ${appColors.brandGrey['50']}`,
      borderRadius: appBorderRadius.md,

      justifyContent: 'space-between',
      alignItems: 'center',

      marginTop: 3,

      paddingX: 3,
      paddingY: 4,

      cursor: 'pointer',
    },

    alertDialogFooter: {
      paddingY: 4,
      paddingX: 3,

      boxShadow: `0px 1px 0px 0px ${appColors.brandGrey['50']} inset`,
    },

    studentProgramCard: {
      border: `2px solid ${appColors.brandGrey['50']}`,
      borderRadius: appBorderRadius.md,

      paddingY: 6,
      paddingX: 4,

      justifyContent: 'space-between',
      alignItems: 'center',

      marginTop: 4,

      cursor: 'pointer',
    },

    moduleListingCard: {
      boxShadow: '0px -1px 0px 0px #F2F2F2 inset',
      paddingY: 4,
    },

    moduleAccessList: {
      alignItems: 'center',

      boxShadow: '0px -1px 0px 0px #F2F2F2 inset',
      paddingBottom: 2,
      paddingTop: 2,
    },
  },
};

export default AppFlexStyles;
