import {
  CometChatUIKit,
  UIKitSettingsBuilder,
} from '@cometchat/chat-uikit-react';
import { useQuery } from 'react-query';
import { GET_TEACHER_CHAT_LISTING } from 'version-two/api/urls';
import makeGetRequest from 'version-two/api/utils/makeGetRequest';
import useAppStore from 'version-two/store';
import {
  COMET_CHAT_APP_ID,
  COMET_CHAT_APP_REGION,
  COMET_CHAT_AUTH_KEY,
} from 'version-two/utils/constants';

const useInitiateCometChat = () => {
  const { setCometChatLoading } = useAppStore();

  const loginNewUser = (uid) => {
    const UID = uid;
    // CometChatUIKit.getLoggedinUser().then((user) => {
    //   if (user) {
    //     CometChatUIKit.logout().then(() => {
    //       // eslint-disable-next-line
    //       console.log("User logged out");
    //     });
    //   }
    // });

    CometChatUIKit.login(UID).then(
      (user) => {
        // eslint-disable-next-line
        console.log("Login Successful:", { user });
        setCometChatLoading(false);
      },
      (error) => {
        // eslint-disable-next-line
        console.log("Login failed with exception:", { error });
      },
    );
  };

  const UIKitSettings = new UIKitSettingsBuilder()
    .setAppId(COMET_CHAT_APP_ID)
    .setRegion(COMET_CHAT_APP_REGION)
    .setAuthKey(COMET_CHAT_AUTH_KEY)
    .subscribePresenceForAllUsers()
    .build();

  const initializeCometChat = (uid) => {
    CometChatUIKit.init(UIKitSettings)
      .then(() => {
        // CometChatUIKit.getLoggedinUser().then((user) => {
        //   if (user) {
        //     CometChatUIKit.logout().then(() => {
        //       // eslint-disable-next-line
        //       console.log("User logged out");
        //       loginNewUser(uid);
        //     });
        //   } else {
        //     loginNewUser(uid);
        //   }
        // });

        loginNewUser(uid);
      })
      .catch((err) => {
        // eslint-disable-next-line
        console.log("Error in initialization", err);
      });
  };

  useQuery(
    [`GET_TEACHER_CHAT_LISTING+chat`],
    () => makeGetRequest(GET_TEACHER_CHAT_LISTING),
    {
      onSuccess: (res) => {
        initializeCometChat(res?.data?.logged_in_user_id);
      },
    },
  );

  return { UIKitSettings };
};

export default useInitiateCometChat;
