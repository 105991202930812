import { Box, DrawerCloseButton } from '@chakra-ui/react';
import AppText from 'version-two/components/Typography/AppText';
import appColors from 'version-two/theme/appColors';
import AppFlex from 'version-two/components/chakraLayoutOverrides/AppFlex';
import RenderIf from 'version-two/components/RenderIf/RenderIf';
import { fontSizes } from 'version-two/theme/fonts';

function ProgramDrawableHeader({ programModule, programTitle, moduleName }) {
  return (
    <AppFlex variant="programDrawableHeader">
      <Box>
        <AppFlex customStyles={{ alignItems: 'center' }}>
          <RenderIf isTrue={moduleName}>
            <AppText
              title={moduleName}
              variant="orangeXXS"
              customStyles={{ fontSize: fontSizes.xs }}
            />
            <AppText title="/" customStyles={{ mx: 1 }} variant="lightXS" />
          </RenderIf>
          <AppText title={programModule} variant="lightXS" />
        </AppFlex>
        <AppText title={programTitle} size="md" />
      </Box>
      <Box>
        <DrawerCloseButton color={appColors.brandGrey['300']} />
      </Box>
    </AppFlex>
  );
}

export default ProgramDrawableHeader;
