import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useBreakpointValue,
} from '@chakra-ui/react';
import AppText from 'version-two/components/Typography/AppText';
import { fontWeights } from 'version-two/theme/fonts';
import appColors from 'version-two/theme/appColors';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  GET_STUDENTS_FOR_MANAGE,
  UNLOCK_MODULES_BULK_ACCESS,
} from 'version-two/api/urls';
import makeGetRequest from 'version-two/api/utils/makeGetRequest';
import AppLoader from 'version-two/components/AppLoader/AppLoader';
import { useRef, useState } from 'react';
import SingleStudentItem from 'version-two/components/ManageModuleAccessBulk/SingleStudentItem';
import makePostRequest from 'version-two/api/utils/makePostRequest';
import getFormattedDate from 'version-two/utils/getFormattedData';
import AppSelect from 'version-two/components/AppSelect/AppSelect';
import AppSelectCustomMenuList from 'version-two/components/AppSelect/AppSelectCustomMenuList';
import AppSelectClearIndicator from 'version-two/components/AppSelect/AppSelectClearIndicator';

const getSelectOptionsForStudents = (data) =>
  (data || []).map((studentData) => ({
    label: studentData.name,
    value: studentData.id,
    isDisabled: studentData.is_access_granted,
    imageSrc: studentData.live_class_profile_photo,
    unlockedDate:
      studentData.access_granted_on &&
      `Unlocked on ${getFormattedDate(studentData.access_granted_on)}`,
  }));

function ManageModuleAccessBulk({ moduleName, isOpen, onClose, activeModule }) {
  const [selectedStudents, setSelectedStudents] = useState([]);
  const selectRef = useRef();

  const queryClient = useQueryClient();

  const { data: studentData, isLoading: studentDataIsLoading } = useQuery(
    GET_STUDENTS_FOR_MANAGE(activeModule),
    () => makeGetRequest(GET_STUDENTS_FOR_MANAGE(activeModule)),
  );

  const {
    mutate: studentUnlockMutation,
    isLoading: studentUnlockMutationIsLoading,
  } = useMutation(
    (body) => makePostRequest(UNLOCK_MODULES_BULK_ACCESS(activeModule), body),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(GET_STUDENTS_FOR_MANAGE(activeModule)),
    },
  );

  const handleStudentUnlock = () => {
    const users = selectedStudents.map((data) => data.value);

    const reqBody = {
      users,
    };

    studentUnlockMutation(reqBody);
    setSelectedStudents([]);
    selectRef.current.blur();
  };

  const handleListClick = (individualStudentData) => {
    const processedData = getSelectOptionsForStudents([individualStudentData]);
    setSelectedStudents([...selectedStudents, ...processedData]);
  };
  const modalSize = useBreakpointValue({ base: 'full', sm: 'md', md: 'lg' });

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size={modalSize}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <AppText
            title="Manage module access"
            size="md"
            customStyles={{
              fontWeight: fontWeights.bold,
            }}
          />
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <AppLoader isLoading={studentDataIsLoading} height="100%">
            {!studentDataIsLoading && (
              <>
                <AppText
                  title={`Module enabled for "${moduleName}" eligible students`}
                  size="xs"
                  customStyles={{
                    color: appColors.brandGrey['500'],
                    mb: 2,
                  }}
                />

                <AppSelect
                  ref={selectRef}
                  isMulti
                  value={selectedStudents}
                  onChange={(value) => setSelectedStudents(value)}
                  options={
                    studentData?.data &&
                    getSelectOptionsForStudents(studentData?.data)
                  }
                  customComponents={{
                    Option: AppSelectCustomMenuList,
                    // eslint-disable-next-line react/no-unstable-nested-components
                    ClearIndicator: (props) => (
                      <AppSelectClearIndicator
                        isLoading={studentUnlockMutationIsLoading}
                        handleClick={handleStudentUnlock}
                        {...props}
                      />
                    ),
                    DropdownIndicator: null,
                  }}
                  /* eslint-disable-next-line react/no-unstable-nested-components */
                />

                <AppText
                  title={`MODULE SHARED WITH ${studentData.data.length} ACTIVE STUDENTS`}
                  size="xs"
                  customStyles={{
                    fontWeight: fontWeights.extrabold,
                    color: appColors.brandGrey['900'],
                    my: 3,
                  }}
                />

                {studentData.data.map((individualData) => (
                  <SingleStudentItem
                    image={individualData.live_class_profile_photo}
                    name={individualData.name}
                    unlockDate={getFormattedDate(
                      individualData.access_granted_on,
                    )}
                    datePreText="Unlocked On"
                    instanceData={individualData}
                    handleOnClick={handleListClick}
                    disabled={individualData.is_access_granted}
                    batchId={individualData.batch_id}
                  />
                ))}
              </>
            )}
          </AppLoader>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default ManageModuleAccessBulk;
