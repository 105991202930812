import create from 'zustand';

const useAppStore = create((set) => ({
  user_token: null,
  user_name: null,
  is_authenticated: false,
  isTrainer: false,
  isStudent: false,

  // Actions
  setUserNameAndToken: ({ token, name }) =>
    set(() => ({
      user_token: token,
      user_name: name,
      is_authenticated: true,
    })),
  removeUserNameAndToken: () =>
    set(() => ({
      user_token: null,
      user_name: null,
      is_authenticated: false,
    })),
  setIsStudent: (status = true) => set(() => ({ isStudent: status })),
  setIsTrainer: (status = true) => set(() => ({ isTrainer: status })),
}));

export default useAppStore;
