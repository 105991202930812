import {
  Image,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useBreakpointValue,
} from '@chakra-ui/react';
import StudentDashboardLayout from 'voxguru-v1/layout/StudentDashboardLayout';
import StudentProgramContent from 'voxguru-v1/pages/studentDashboard/StudentProgramDetailWrapper/studentProgramContent/StudentProgramContent';
import getIsWebView from 'voxguru-v1/utils/getIsWebView';
import AppFlex from 'voxguru-v1/components/chakraLayoutOverrides/AppFlex';
import AppBox from 'voxguru-v1/components/chakraLayoutOverrides/AppBox';
import AppText from 'voxguru-v1/components/Typography/AppText';
import { fontWeights } from 'voxguru-v1/theme/fonts';
import AppAvatar from 'voxguru-v1/components/AppAvatar/AppAvatar';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { GET_STUDENT_LIVE_CLASS_DETAIL_DATA } from 'voxguru-v1/api/urls';
import makeGetRequest from 'voxguru-v1/api/utils/makeGetRequest';
import AppLoader from 'voxguru-v1/components/AppLoader/AppLoader';
import AppButton from 'voxguru-v1/components/Typography/AppButton';
import { PurpleChatIcon } from 'voxguru-v1/assets';
import { MAKE_STUDENT_CHAT_PORTAL } from 'voxguru-v1/navigation/routes';
import StudentProgramClassRecordings from 'voxguru-v1/pages/studentDashboard/StudentProgramDetailWrapper/StudentProgramClassRecordings/StudentProgramClassRecordings';

function StudentProgramDetailWrapper() {
  const { programSlug } = useParams();
  const { data: studentProgramData, isLoading: studentProgramDataIsLoading } =
    useQuery(GET_STUDENT_LIVE_CLASS_DETAIL_DATA(programSlug), () =>
      makeGetRequest(GET_STUDENT_LIVE_CLASS_DETAIL_DATA(programSlug)),
    );
  const isMobile = useBreakpointValue({ base: true, xl: false });
  const navigate = useNavigate();

  const handleNavigateToStudentChat = () => {
    navigate(
      MAKE_STUDENT_CHAT_PORTAL(studentProgramData?.data?.logged_in_user_id),
    );
  };
  return (
    <StudentDashboardLayout>
      <AppLoader isLoading={studentProgramDataIsLoading}>
        {!studentProgramDataIsLoading && (
          <>
            {!getIsWebView() && (
              <AppFlex
                customStyles={{
                  justifyContent: 'space-between',
                  mb: 10,
                  display: { base: 'none', sm: 'flex' },
                }}
              >
                <AppBox>
                  <AppText
                    title="Trainer"
                    variant="lightXS"
                    customStyles={{ mb: 1, fontWeight: fontWeights.normal }}
                  />

                  <AppFlex>
                    <AppAvatar
                      name={studentProgramData.data.teacher.name}
                      src={
                        studentProgramData.data.teacher.live_class_profile_photo
                      }
                    />

                    <AppBox customStyles={{ ml: 5 }}>
                      <AppText
                        title={studentProgramData.data.teacher.name}
                        size="sm"
                      />
                      {/* Teacher phone number -- commented -- Client requirement -- */}
                      {/* <AppFlex> */}
                      {/*  <Image src={PhoneCustomIcon} mr={2} /> */}
                      {/*  <AppLink */}
                      {/*    href={`tel:${data.data.teacher.live_class_phone_number}`} */}
                      {/*    title={data.data.teacher.live_class_phone_number} */}
                      {/*  /> */}
                      {/* </AppFlex> */}
                    </AppBox>
                  </AppFlex>
                </AppBox>
                {/* Schedule -- commented -- Client requirement -- */}
                {/* <AppBox> */}
                {/*  <AppText */}
                {/*    title="Schedule" */}
                {/*    variant="lightXS" */}
                {/*    customStyles={{ mb: 1, fontWeight: fontWeights.normal }} */}
                {/*  /> */}
                {/*  <AppText title={data.data.schedule_display} size="sm" /> */}
                {/* </AppBox> */}
              </AppFlex>
            )}

            <AppText
              title={studentProgramData?.data?.group_chat?.batch_name}
              size="sm"
              customStyles={{ fontWeight: fontWeights.bold }}
            />
            <AppText
              title={studentProgramData?.data?.group_chat?.title_line}
              variant="lightXS"
              customStyles={{ mb: 3 }}
            />
            {!isMobile ? (
              <AppFlex
                customStyles={{ mb: 5 }}
                onClick={handleNavigateToStudentChat}
              >
                <AppButton
                  variant="outline"
                  customStyles={{ width: '100%', py: 6 }}
                >
                  <AppFlex customStyles={{ alignItems: 'center' }}>
                    <Image src={PurpleChatIcon} mr={2} />
                    <AppText title="Group Chat" variant="contactSupport" />
                  </AppFlex>
                </AppButton>
              </AppFlex>
            ) : null}
            <Tabs variant="studentDetailTab">
              <TabList>
                <Tab>Content</Tab>
                <Tab>Recordings</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <StudentProgramContent currentPitchVal={null} />
                </TabPanel>
                <TabPanel>
                  <StudentProgramClassRecordings />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </>
        )}
      </AppLoader>
    </StudentDashboardLayout>
  );
}

export default StudentProgramDetailWrapper;
