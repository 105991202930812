import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useBreakpointValue,
} from '@chakra-ui/react';
import AppText from 'voxguru-v1/components/Typography/AppText';
import { fontWeights } from 'voxguru-v1/theme/fonts';
import appColors from 'voxguru-v1/theme/appColors';
import AppSelectCustomMenuList from 'voxguru-v1/components/AppSelect/AppSelectCustomMenuList';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  GET_STUDENT_FOR_SHARE_NOTE_ACCESS,
  SHARE_NOTE_BULK_ACCESS,
} from 'voxguru-v1/api/urls';
import makeGetRequest from 'voxguru-v1/api/utils/makeGetRequest';
import AppLoader from 'voxguru-v1/components/AppLoader/AppLoader';
import { useRef, useState } from 'react';
import SingleStudentItem from 'voxguru-v1/components/ManageModuleAccessBulk/SingleStudentItem';
import makePostRequest from 'voxguru-v1/api/utils/makePostRequest';
import getFormattedDate from 'voxguru-v1/utils/getFormattedData';
import AppSelect from 'voxguru-v1/components/AppSelect/AppSelect';
import AppSelectClearIndicator from 'voxguru-v1/components/AppSelect/AppSelectClearIndicator';

const getSelectOptionsForStudents = (data) =>
  (data || []).map((studentData) => ({
    label: studentData.name,
    value: studentData.id,
    isDisabled: studentData.is_access_granted,
    unlockedDate:
      studentData.access_granted_on &&
      `Unlocked on ${getFormattedDate(studentData.access_granted_on)}`,
  }));

function TeacherNoteShareDialog({ noteName, isOpen, onClose, activeModule }) {
  const [selectedStudents, setSelectedStudents] = useState([]);

  const selectRef = useRef();

  const queryClient = useQueryClient();

  const { data: studentData, isLoading: studentDataIsLoading } = useQuery(
    GET_STUDENT_FOR_SHARE_NOTE_ACCESS(activeModule),
    () => makeGetRequest(GET_STUDENT_FOR_SHARE_NOTE_ACCESS(activeModule)),
  );

  const { mutate: shareNoteMutation, isLoading: shareNoteMutationIsLoading } =
    useMutation(
      (body) => makePostRequest(SHARE_NOTE_BULK_ACCESS(activeModule), body),
      {
        onSuccess: () =>
          queryClient.invalidateQueries(
            GET_STUDENT_FOR_SHARE_NOTE_ACCESS(activeModule),
          ),
      },
    );

  const handleStudentUnlock = () => {
    const users = selectedStudents.map((data) => data.value);

    const reqBody = {
      users,
    };

    shareNoteMutation(reqBody);

    setSelectedStudents([]);

    selectRef.current.blur();
  };

  const handleListClick = (individualStudentData) => {
    const processedData = getSelectOptionsForStudents([individualStudentData]);
    setSelectedStudents([...selectedStudents, ...processedData]);
  };

  const modalSize = useBreakpointValue({ base: 'full', sm: 'md', md: 'lg' });

  const getNumberOfSharedStudentsData = (studentsData) => {
    let totalCount = 0;
    if (Array.isArray(studentsData) && studentsData.length > 0) {
      studentsData.map((stData) => {
        if (stData.is_access_granted) {
          totalCount += 1;
        }
        return totalCount;
      });
    }
    return totalCount;
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size={modalSize}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <AppText
            title="Share note"
            size="md"
            customStyles={{
              fontWeight: fontWeights.bold,
            }}
          />
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <AppLoader isLoading={studentDataIsLoading} height="100%">
            {!studentDataIsLoading && (
              <>
                <AppText
                  title={`Share "${noteName}" with`}
                  size="xs"
                  customStyles={{
                    color: appColors.brandGrey['500'],
                    mb: 2,
                  }}
                />

                <AppSelect
                  isMulti
                  value={selectedStudents}
                  onChange={(value) => setSelectedStudents(value)}
                  options={
                    studentData?.data &&
                    getSelectOptionsForStudents(studentData?.data)
                  }
                  ref={selectRef}
                  customComponents={{
                    Option: AppSelectCustomMenuList,
                    // eslint-disable-next-line react/no-unstable-nested-components
                    ClearIndicator: (props) => (
                      <AppSelectClearIndicator
                        {...props}
                        isLoading={shareNoteMutationIsLoading}
                        handleClick={handleStudentUnlock}
                      />
                    ),
                    DropdownIndicator: null,
                  }}
                />

                <AppText
                  title={`NOTES SHARED WITH ${getNumberOfSharedStudentsData(
                    studentData.data,
                  )} ACTIVE STUDENTS`}
                  size="xs"
                  customStyles={{
                    fontWeight: fontWeights.extrabold,
                    color: appColors.brandGrey['900'],
                    my: 3,
                  }}
                />

                {studentData.data.map((individualData) => (
                  <SingleStudentItem
                    image={individualData.live_class_profile_photo}
                    name={individualData.name}
                    unlockDate={getFormattedDate(
                      individualData.access_granted_on,
                    )}
                    datePreText="Shared On"
                    instanceData={individualData}
                    handleOnClick={handleListClick}
                    disabled={individualData.is_access_granted}
                    batchId={individualData.batch_id}
                  />
                ))}
              </>
            )}
          </AppLoader>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default TeacherNoteShareDialog;
