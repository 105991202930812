import AppTeacherNavbar from 'voxguru-v1/components/AppTeacherNavbar/AppTeacherNavbar';
import { Box, Container } from '@chakra-ui/react';

function TeacherDashboardLayout({ children, isProgram, breadCrumb }) {
  return (
    <Box>
      <AppTeacherNavbar isProgram={isProgram} breadCrumb={breadCrumb} />
      <Container maxW="1000px">{children}</Container>
    </Box>
  );
}

export default TeacherDashboardLayout;
