import TeacherDashboardLayout from 'version-two/layout/TeacherDashboardLayout';
import { Box, Flex, Image } from '@chakra-ui/react';
import appColors from 'version-two/theme/appColors';
import AppText from 'version-two/components/Typography/AppText';
import AppFlex from 'version-two/components/chakraLayoutOverrides/AppFlex';
import { useQuery } from 'react-query';
import { GET_TEACHER_CHAT_LISTING } from 'version-two/api/urls';
import makeGetRequest from 'version-two/api/utils/makeGetRequest';
import AppLoader from 'version-two/components/AppLoader/AppLoader';
import { TEACHER_CHAT_PORTAL } from 'version-two/navigation/routes';
import { useNavigate } from 'react-router-dom';
import { ChatIcon } from 'version-two/assets';

function TeacherChatList() {
  const { data: teacherChatListing, isLoading: teacherChatListingIsLoading } =
    useQuery(GET_TEACHER_CHAT_LISTING, () =>
      makeGetRequest(GET_TEACHER_CHAT_LISTING),
    );

  const navigate = useNavigate();
  return (
    <TeacherDashboardLayout>
      <AppLoader isLoading={teacherChatListingIsLoading}>
        <AppText title="Your Classes" variant="header" />
        {!teacherChatListingIsLoading && (
          <Box>
            <Flex
              alignItems="center"
              mt={3}
              py={5}
              boxShadow={`0px 1px 0px 0px ${appColors.brandGrey['100']} inset;`}
            >
              <Box flexBasis="45%">
                <AppText title="BATCHES" variant="tableHeader" />
              </Box>
              <Box flexBasis="45%">
                <AppText title="PROGRAM" variant="tableHeader" />
              </Box>
              <Box flexBasis="10%">
                <AppText title="Chats" variant="tableHeader" />
              </Box>
            </Flex>

            {teacherChatListing.data.chat_groups.map((teacherChatData) => (
              <AppFlex
                customStyles={{
                  alignItems: 'center',
                }}
                variant="moduleListingCard"
              >
                <Flex
                  flexBasis="100%"
                  cursor="pointer"
                  onClick={() => navigate(TEACHER_CHAT_PORTAL)}
                >
                  <Box flexBasis="45%">
                    <AppText title={teacherChatData.batch_name} size="sm" />
                    <AppText
                      title={teacherChatData.title_line}
                      size="xxs"
                      variant="light"
                    />
                  </Box>
                  <Box flexBasis="45%">
                    <Flex>
                      <AppText title={teacherChatData.live_class.identity} />
                    </Flex>
                  </Box>
                  <Box flexBasis="10%">
                    <Image src={ChatIcon} />
                  </Box>
                </Flex>
              </AppFlex>
            ))}
          </Box>
        )}
      </AppLoader>
    </TeacherDashboardLayout>
  );
}
export default TeacherChatList;
