import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
  Image,
  useBreakpointValue,
} from '@chakra-ui/react';
import AppText from 'version-two/components/Typography/AppText';
import AppInput from 'version-two/components/Typography/AppInput';
import AppFlex from 'version-two/components/chakraLayoutOverrides/AppFlex';
import AppButton from 'version-two/components/Typography/AppButton';
import { useRef, useState } from 'react';
import { Mp3Icon, PdfIcon } from 'version-two/assets';
import appColors from 'version-two/theme/appColors';
import { fontWeights } from 'version-two/theme/fonts';
import { appBorderRadius } from 'version-two/theme/sizes';
import { useMutation } from 'react-query';
import makePostRequest from 'version-two/api/utils/makePostRequest';
import { COMMON_FILE_UPLOAD } from 'version-two/api/urls/teacher';

function FileUploadDialog({
  isOpen,
  onClose,
  leastDestructiveRef,
  fileUploadCallback,
}) {
  const [currentFile, setCurrentFile] = useState();
  const [userFileName, setUserFileName] = useState();
  const fileRef = useRef();

  const {
    mutate: fileUploadMutate,
    isLoading,
    data: fileData,
    reset: fileUploadReset,
    isError,
  } = useMutation(COMMON_FILE_UPLOAD, (body) =>
    makePostRequest(COMMON_FILE_UPLOAD, body),
  );

  const handleDialogClose = () => {
    setCurrentFile(null);
    setUserFileName(null);
    onClose();
  };

  const handleTriggerUpload = () => {
    fileRef.current.click();
  };

  const handleFileUpload = (e) => {
    const [file] = e.target.files;
    setCurrentFile(file);
    setUserFileName(file.name);
    const formData = new FormData();
    formData.append('file', file);
    fileUploadMutate(formData);
  };

  const handleCustomUserFileName = (e) => {
    setUserFileName(e.target.value);
  };

  const isPdfFile =
    currentFile?.name && currentFile.name.split('.')[1] === 'pdf';

  const alertDialogSize = useBreakpointValue({ base: 'full', sm: 'sm' });
  return (
    <AlertDialog
      leastDestructiveRef={leastDestructiveRef}
      isOpen={isOpen}
      onClose={handleDialogClose}
      isCentered
      size={alertDialogSize}
    >
      <AlertDialogOverlay />

      <AlertDialogContent
        style={{
          borderRadius: appBorderRadius.md,
          paddingTop: 10,
        }}
        variant="uploadDialog"
      >
        <AlertDialogHeader>
          <AppText
            title={isError ? 'Oops!' : 'New Note'}
            size="md"
            customStyles={{
              fontWeight: fontWeights.bold,
            }}
          />
        </AlertDialogHeader>
        <AlertDialogCloseButton
          color={appColors.brandGrey['300']}
          mt={3}
          mr={2}
        />

        <AlertDialogBody>
          <AppText
            title={
              isError
                ? 'The file size exceeds the maximum upload limit of 5Mb.'
                : 'A short recognizable title'
            }
            size="xs"
          />
          <AppInput
            customStyles={{
              mb: 7,
              hidden: isError,
            }}
            value={userFileName}
            onChange={handleCustomUserFileName}
          />
        </AlertDialogBody>
        <AppFlex
          variant="alertDialogFooter"
          customStyles={{ justifyContent: 'space-between' }}
        >
          <AppFlex
            customStyles={{
              alignItems: 'center',
              hidden: isError,
            }}
          >
            <Image
              src={currentFile?.name && (isPdfFile ? PdfIcon : Mp3Icon)}
              width={currentFile?.name && '25px'}
              height={currentFile?.name && '25px'}
              ml={3}
            />
            <AppText
              title={currentFile?.name}
              size="xs"
              customStyles={{
                ml: 3,
                maxWidth: '200px',
              }}
            />
          </AppFlex>
          <AppInput
            onChange={handleFileUpload}
            ref={fileRef}
            type="file"
            customStyles={{
              hidden: true,
              multiple: false,
              accept: '.pdf,.mp3,.mp4,.png,.jpg,.jpeg,.m4a,.aac',
            }}
          />
          {fileData ? (
            <AppButton
              title="Submit"
              customStyles={{
                marginTop: 0,
              }}
              onClick={() => {
                fileUploadCallback({
                  fileData: fileData.data,
                  name: userFileName,
                });

                handleDialogClose();

                fileUploadReset();
              }}
            />
          ) : (
            <AppButton
              title={isError ? 'Choose another file' : 'Upload'}
              variant={isError ? 'programDrawableButton' : 'solid'}
              onClick={handleTriggerUpload}
              customStyles={{
                marginTop: 0,
              }}
              isLoading={isLoading}
            />
          )}
        </AppFlex>
      </AlertDialogContent>
    </AlertDialog>
  );
}
export default FileUploadDialog;
