import AppBox from 'voxguru-v1/components/chakraLayoutOverrides/AppBox';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { GET_STUDENT_LIVE_CLASS_DETAIL_DATA } from 'voxguru-v1/api/urls';
import makeGetRequest from 'voxguru-v1/api/utils/makeGetRequest';
import AppLoader from 'voxguru-v1/components/AppLoader/AppLoader';
import AppFlex from 'voxguru-v1/components/chakraLayoutOverrides/AppFlex';
import AppText from 'voxguru-v1/components/Typography/AppText';
import { fontWeights } from 'voxguru-v1/theme/fonts';
import { Image } from '@chakra-ui/react';
import { PlayIcon } from 'voxguru-v1/assets';
import { MAKE_APP_WEB_RECORDINGS_VIDEO_PLAYER } from 'voxguru-v1/navigation//routes';
import { useRef } from 'react';
import getIsWebView from 'voxguru-v1/utils/getIsWebView';

const getWebRecordingsURL = ({ programSlug, index }) =>
  MAKE_APP_WEB_RECORDINGS_VIDEO_PLAYER(programSlug, index);

const handleNavigateToWebRecordings = ({ programSlug, index, linkRef }) => {
  if (getIsWebView()) {
    linkRef.current.click();
  } else {
    window.open(getWebRecordingsURL({ programSlug, index }));
  }
};

function RecordingsItems({ programSlug, recordingsData, index }) {
  const linkRef = useRef();

  return (
    <AppFlex
      variant="moduleListingCard"
      customStyles={{ justifyContent: 'space-between' }}
    >
      <AppBox>
        <AppFlex>
          <AppText title={recordingsData.line1} variant="orangeXXS" />
        </AppFlex>
        <AppText
          title={recordingsData.line2}
          size="sm"
          customStyles={{
            cursor: 'pointer',
            width: 'fit-content',
          }}
        />
        <AppText
          title={recordingsData.line3}
          variant="lightXXS"
          customStyles={{
            fontWeight: fontWeights.normal,
          }}
        />
      </AppBox>

      <AppFlex
        customStyles={{ alignItems: 'center', cursor: 'pointer' }}
        onClick={() =>
          handleNavigateToWebRecordings({
            programSlug,
            index,
            linkRef,
          })
        }
      >
        {/* eslint-disable-next-line */}
        <a
          href={getWebRecordingsURL({ programSlug, index })}
          ref={linkRef}
          style={{ display: 'none' }}
        />
        <AppText title={recordingsData.resource_duration} variant="lightXS" />
        <Image src={PlayIcon} ml={2} alt="" />
      </AppFlex>
    </AppFlex>
  );
}

function StudentProgramClassRecordings() {
  const { programSlug } = useParams();
  const { data: studentProgramData, isLoading: studentProgramDataIsLoading } =
    useQuery(GET_STUDENT_LIVE_CLASS_DETAIL_DATA(programSlug), () =>
      makeGetRequest(GET_STUDENT_LIVE_CLASS_DETAIL_DATA(programSlug)),
    );

  return (
    <AppBox>
      <AppLoader isLoading={studentProgramDataIsLoading}>
        {!studentProgramDataIsLoading &&
          studentProgramData?.data?.recordings.map((recordingsData, index) => (
            <RecordingsItems
              recordingsData={recordingsData}
              programSlug={programSlug}
              index={index}
            />
          ))}
      </AppLoader>
    </AppBox>
  );
}

export default StudentProgramClassRecordings;
