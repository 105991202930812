import { Input } from '@chakra-ui/react';
import AppText from 'version-two/components/Typography/AppText';
import { forwardRef } from 'react';

const AppInput = forwardRef(
  (
    {
      placeholder,
      name,
      value,
      onChange,
      label,
      type = 'text',
      customStyles,
      id,
      isRequired,
      ...restProps
    },
    ref,
  ) => (
    <>
      <AppText title={label} variant="label" />
      <Input
        id={id}
        ref={ref}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        type={type}
        required={isRequired}
        variant="outline"
        {...customStyles}
        {...restProps}
      />
    </>
  ),
);

export default AppInput;
