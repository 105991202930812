import StudentDashboardLayout from 'voxguru-v1/layout/StudentDashboardLayout';
import AppText from 'voxguru-v1/components/Typography/AppText';
import AppBox from 'voxguru-v1/components/chakraLayoutOverrides/AppBox';
import AppFlex from 'voxguru-v1/components/chakraLayoutOverrides/AppFlex';
import AppAvatar from 'voxguru-v1/components/AppAvatar/AppAvatar';
import { useNavigate } from 'react-router-dom';
import { MAKE_STUDENT_PROGRAM_DETAIL } from 'voxguru-v1/navigation//routes';
import { useQuery } from 'react-query';
import { GET_STUDENT_DASHBOARD_DATA } from 'voxguru-v1/api/urls';
import makeGetRequest from 'voxguru-v1/api/utils/makeGetRequest';
import AppLoader from 'voxguru-v1/components/AppLoader/AppLoader';

function StudentProgramList() {
  const navigate = useNavigate();
  const { data, isLoading } = useQuery(GET_STUDENT_DASHBOARD_DATA, () =>
    makeGetRequest(GET_STUDENT_DASHBOARD_DATA),
  );

  return (
    <StudentDashboardLayout>
      <AppLoader isLoading={isLoading}>
        {!isLoading && (
          <>
            <AppText title="Live Classes" variant="header" />
            {data.data.subscriptions_data.map((liveClassData) => (
              <AppFlex
                variant="studentProgramCard"
                onClick={() =>
                  navigate(MAKE_STUDENT_PROGRAM_DETAIL(liveClassData.id))
                }
              >
                <AppBox>
                  <AppText
                    title={
                      liveClassData.live_class.title_line_for_student_listing ||
                      liveClassData.teacher.name
                    }
                    size="sm"
                  />
                  <AppText
                    title={
                      liveClassData.live_class
                        .sub_title_line_for_student_listing || ''
                    }
                    variant="lightXS"
                  />
                </AppBox>
                <AppAvatar
                  name={liveClassData.teacher.name}
                  src={liveClassData.teacher.live_class_profile_photo}
                />
              </AppFlex>
            ))}
          </>
        )}
      </AppLoader>
    </StudentDashboardLayout>
  );
}

export default StudentProgramList;
