import React from 'react';
import AppFlex from 'voxguru-v1/components/chakraLayoutOverrides/AppFlex';
import AppAvatar from 'voxguru-v1/components/AppAvatar/AppAvatar';
import appColors from 'voxguru-v1/theme/appColors';
import AppText from 'voxguru-v1/components/Typography/AppText';

function SingleStudentItem({
  image,
  name,
  unlockDate,
  datePreText = 'Unlocked on',
  instanceData,
  handleOnClick,
  disabled,
  batchId,
}) {
  return (
    <AppFlex
      customStyles={{
        justifyContent: 'space-between',
        cursor: disabled ? 'not-allowed' : 'pointer',
      }}
      variant="moduleAccessList"
      onClick={() => !disabled && handleOnClick(instanceData)}
    >
      <AppFlex
        customStyles={{
          alignItems: 'center',
        }}
      >
        <AppAvatar
          src={image}
          name={name}
          bgColor={appColors.brandSecondary['100']}
          color={appColors.brandSecondary['500']}
          variant="profile"
          size="sm"
        />
        <AppText
          title={`${name} (${batchId})`}
          customStyles={{ ml: 2 }}
          size="sm"
          variant="bold"
        />
      </AppFlex>
      {unlockDate && (
        <AppText
          title={`${datePreText} ${unlockDate}`}
          size="xs"
          color={appColors.brandGrey['500']}
        />
      )}
    </AppFlex>
  );
}

export default React.memo(SingleStudentItem);
