import appAxios from 'version-two/api/appAxios';
import getUserToken from './getUserToken';

const makePostRequest = async (endpoint, body) => {
  const { data } = await appAxios.post(endpoint, body);
  getUserToken();
  return data;
};

export default makePostRequest;
