import axios from 'axios';
import { getHostAPIUrl, isTeacherEnvironment } from 'appConfig';
import { APP_TEACHER_IS_AUTHENTICATED } from '../urls/teacher';
import { APP_STUDENT_AUTHENTICATED } from '../urls/student';
import getUserToken from './getUserToken';

const isTeacherAuthenticated = async () => {
  const token = getUserToken();
  let response;

  if (isTeacherEnvironment()) {
    response = axios.get(getHostAPIUrl() + APP_TEACHER_IS_AUTHENTICATED, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
  } else {
    response = axios.get(getHostAPIUrl() + APP_STUDENT_AUTHENTICATED, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
  }

  return response;
};

export default isTeacherAuthenticated;
