import { extendTheme, theme as baseTheme } from '@chakra-ui/react';
import appColors from 'version-two/theme/appColors';
import {
  fontSizes,
  fonts,
  lineHeights,
  fontWeights,
} from 'version-two/theme/fonts';
import components from 'version-two/theme/components';

const theme = extendTheme({
  colors: appColors,
  fonts,
  fontSizes,
  lineHeights,
  fontWeights,
  baseTheme,
  components,
});

export default theme;
