import styled from '@emotion/styled';
import appColors from 'version-two/theme/appColors';

const Card = styled.div`
  display: flex;
  flex-flow: column;

  min-width: 375px;
  max-width: 375px;

  padding: 3rem 1.4rem;

  border-radius: 32px;

  background: ${appColors.brandWhite['900']};
`;

export { Card };
