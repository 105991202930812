import appColors from 'version-two/theme/appColors';
import { fontSizes, fontWeights } from 'version-two/theme/fonts';
import { appBorderRadius } from 'version-two/theme/sizes';

const AppButtonStyles = {
  variants: {
    solid: {
      backgroundColor: appColors.brandSecondary['500'],
      color: appColors.brandWhite['900'],

      borderRadius: appBorderRadius.sm,
      _hover: {
        backgroundColor: appColors.brandSecondary['500'],
        opacity: 0.8,
        _disabled: {
          backgroundColor: appColors.brandSecondary['500'],
          opacity: 0.4,
        },
      },
    },
    outline: {
      borderRadius: appBorderRadius.sm,

      borderColor: appColors.brandSecondary['500'],
    },
    programDrawableButton: {
      backgroundColor: appColors.brandGrey['50'],
      color: appColors.brandGrey['700'],

      fontSize: fontSizes.xs,
      fontWeight: fontWeights.bold,
    },

    tripleIconButton: {
      background: 'none',

      padding: 0,
      margin: 0,

      _hover: {
        backgroundColor: appColors.brandGrey['50'],
        boxShadow: 'none',
      },

      _focus: {
        boxShadow: 'none',
      },
    },

    navButton: {
      backgroundColor: 'none',
      padding: 0,
      margin: 0,

      _focus: {
        boxShadow: 'none',
      },
    },
    confirmDeleteButton: {
      backgroundColor: appColors.brandPrimary['400'],
      color: appColors.brandWhite['900'],
    },
  },
};

export default AppButtonStyles;
