import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
} from '@chakra-ui/react';
import { appBorderRadius } from 'version-two/theme/sizes';
import AppText from 'version-two/components/Typography/AppText';
import AppFlex from 'version-two/components/chakraLayoutOverrides/AppFlex';
import AppButton from 'version-two/components/Typography/AppButton';
import { fontWeights } from 'version-two/theme/fonts';
import appColors from 'version-two/theme/appColors';

function ConfirmationDialog({
  leastDestructiveRef,
  isOpen,
  onClose,
  title,
  description,
  handleSuccessClick,
  successBtnText,
}) {
  return (
    <AlertDialog
      leastDestructiveRef={leastDestructiveRef}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
    >
      <AlertDialogOverlay />

      <AlertDialogContent
        style={{
          minWidth: '530px',
          borderRadius: appBorderRadius.md,
          paddingTop: 10,
        }}
      >
        <AlertDialogHeader>
          <AppText
            title={title}
            size="md"
            customStyles={{ fontWeight: fontWeights.bold }}
          />
        </AlertDialogHeader>

        <AlertDialogCloseButton
          color={appColors.brandGrey['300']}
          mt={3}
          mr={2}
        />

        <AlertDialogBody>
          <AppText title={description} size="xs" customStyles={{ mb: 5 }} />
        </AlertDialogBody>

        <AppFlex
          customStyles={{ justifyContent: 'end' }}
          variant="alertDialogFooter"
        >
          <AppButton
            title="Cancel"
            variant="programDrawableButton"
            customStyles={{ mr: 5 }}
            onClick={onClose}
          />
          <AppButton title={successBtnText} onClick={handleSuccessClick} />
        </AppFlex>
      </AlertDialogContent>
    </AlertDialog>
  );
}

export default ConfirmationDialog;
