import React from 'react';
import { CheckedIcon } from 'version-two/assets';
import { Image, Spinner } from '@chakra-ui/react';
import { fontSizes } from 'version-two/theme/fonts';
import AppFlex from './chakraLayoutOverrides/AppFlex';
import AppText from './Typography/AppText';

function PitchListItem({ isSelected, onChange, data, isLoading = false }) {
  return (
    <AppFlex
      className="pitch-list-hover"
      customStyles={{
        width: '100%',
        maxWidth: '390px',
        padding: '15px 6px',
        justifyContent: 'space-between',
        border: '1px solid transparent',
        borderBottom: '1px solid #D9D9D9',
        cursor: 'pointer',
        alignItems: 'center',
      }}
      onClick={() => onChange(data)}
    >
      <AppText
        customStyles={{
          fontSize: fontSizes?.sm,
          fontWeight: 600,
          color: isSelected ? '#A6A6A6' : '#0D0D0D',
        }}
      >
        {data?.identity}
      </AppText>
      <AppFlex>
        {/* eslint-disable-next-line no-nested-ternary */}
        {isLoading ? (
          <Spinner h="20px" w="20px" color="#E87663" />
        ) : isSelected ? (
          <Image src={CheckedIcon} />
        ) : null}
      </AppFlex>
    </AppFlex>
  );
}

export default PitchListItem;
