import ProgramDrawableHeader from 'version-two/components/ProgramDrawableHeader/ProgramDrawableHeader';
import AppBox from 'version-two/components/chakraLayoutOverrides/AppBox';
import AppText from 'version-two/components/Typography/AppText';
import {
  Drawer,
  DrawerContent,
  DrawerOverlay,
  ListItem,
  UnorderedList,
  useBreakpointValue,
} from '@chakra-ui/react';
import AppFlex from 'version-two/components/chakraLayoutOverrides/AppFlex';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import appColors from 'version-two/theme/appColors';
import { Mp3Icon } from 'version-two/assets';

import ContentTitleCard from 'version-two/components/ContentTitleCard/ContentTitleCard';

function StudentProgramDetailDrawable({
  isOpen,
  onClose,
  activeModule,
  moduleName,
  // contentIndex,
  wholeData,
}) {
  // const { data: liveClassModuleData, isLoading: liveClassModuleDataIsLoading } =
  //   useQuery(GET_STUDENT_LIVE_CLASS_MODULE_DETAIL_DATA(activeModule), () =>
  //     makeGetRequest(GET_STUDENT_LIVE_CLASS_MODULE_DETAIL_DATA(activeModule)),
  //   );
  const drawerSize = useBreakpointValue({ base: 'xs', sm: 'sm', md: 'md' });

  // Return file based on whether it is pitch audio or normal file
  const getFile = (val) => {
    if (val?.file_type === 'pitch_audio') return val?.pitch_file?.url;
    return val?.file;
  };
  // console.log('show Index in', activeModule.position);

  // const selectedModule = data.data.module.filter(
  //   (mod) => mod.id === activeModule,
  // );
  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      placement="right"
      size={drawerSize}
    >
      <DrawerOverlay>
        <DrawerContent overflow="scroll">
          {/* <AppLoader isLoading={liveClassModuleDataIsLoading}>
            {!liveClassModuleDataIsLoading && ( */}
          <>
            <ProgramDrawableHeader
              moduleName={moduleName}
              programModule={`MODULE ${activeModule?.position}`}
              programTitle={wholeData.data.live_class_v2.title_student}
            />

            <AppBox variant="programDrawableBlock">
              <AppText title="Objectives" variant="programDrawableSubTitle" />
              <UnorderedList>
                {Array.isArray(activeModule?.objectives) &&
                  activeModule.objectives.map((data) => (
                    <ListItem>
                      <AppText title={data} size="xs" />
                    </ListItem>
                  ))}
              </UnorderedList>
            </AppBox>

            {/* Core Content */}
            <AppBox variant="programDrawableBlock">
              <AppFlex
                customStyles={{
                  alignItems: 'center',
                }}
              >
                <AppText
                  title="Core Content"
                  variant="programDrawableSubTitle"
                />
                <InfoOutlineIcon ml={2} color={appColors.brandGrey['200']} />
              </AppFlex>

              {Array.isArray(activeModule.core_content_files) &&
                activeModule?.core_content_files.map((data) => (
                  <ContentTitleCard
                    file={getFile(data)}
                    icon={Mp3Icon}
                    fileId={data?.id}
                    title={data?.identity}
                    pitch={data?.pitch_file?.pitch}
                  />
                ))}
            </AppBox>

            {/* Teachers Note */}
            <AppFlex variant="programDrawableBlock">
              <AppFlex
                customStyles={{
                  alignItems: 'center',
                }}
              >
                <AppText
                  title="Teachers Note"
                  variant="programDrawableSubTitle"
                />
                <InfoOutlineIcon ml={2} color={appColors.brandGrey['200']} />
              </AppFlex>
            </AppFlex>
            <AppBox variant="programDrawableBlock">
              {activeModule?.teacher_content_files?.map((teacherNoteData) => (
                <ContentTitleCard
                  file={teacherNoteData.file}
                  fileId={teacherNoteData.id}
                  title={teacherNoteData.identity}
                />
              ))}
            </AppBox>
          </>
          {/* )}
          </AppLoader> */}
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
}
export default StudentProgramDetailDrawable;
