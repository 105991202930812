import AppBox from 'version-two/components/chakraLayoutOverrides/AppBox';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { GET_STUDENT_LIVE_CLASS_RECORDINGS } from 'version-two/api/urls';
import makeGetRequest from 'version-two/api/utils/makeGetRequest';
import AppLoader from 'version-two/components/AppLoader/AppLoader';
import AppFlex from 'version-two/components/chakraLayoutOverrides/AppFlex';
import AppText from 'version-two/components/Typography/AppText';
// import { fontWeights } from 'version-two/theme/fonts';
import { Image } from '@chakra-ui/react';
import { PlayIcon } from 'version-two/assets';
import { MAKE_APP_CLASS_RECORDINGS_VIDEO_PLAYER } from 'version-two/navigation/routes';
import { useRef } from 'react';
import getIsWebView from 'version-two/utils/getIsWebView';

const getWebRecordingsURL = ({ programSlug, index }) =>
  MAKE_APP_CLASS_RECORDINGS_VIDEO_PLAYER(programSlug, index);

const handleNavigateToWebRecordings = ({ programSlug, index, linkRef }) => {
  if (getIsWebView()) {
    linkRef.current.click();
  } else {
    window.open(MAKE_APP_CLASS_RECORDINGS_VIDEO_PLAYER(programSlug, index));
  }
};
const showValidDate = (date) => {
  const dateArr = date.split(':');
  const dateToShow = `${dateArr[0]}hr ${dateArr[1]}min`;
  return dateToShow;
};

function RecordingsItems({ programSlug, recordingsData, index }) {
  const linkRef = useRef();

  return (
    <AppFlex
      variant="moduleListingCard"
      customStyles={{ justifyContent: 'space-between' }}
    >
      <AppBox>
        <AppFlex>
          <AppText
            title={`Week ${recordingsData.week_number}`}
            variant="orangeXXS"
            size="sm"
            customStyles={{
              fontSize: '16px',
            }}
          />
        </AppFlex>
        {/* <AppText
          title={wholeData.data.live_class_v2.title_student}
          size="sm"
          customStyles={{
            cursor: 'pointer',
            width: 'fit-content',
          }}
        /> */}
        {/* {<AppText
          title={`${wholeData.data.week_day} ${wholeData.data.batch_timings}`}
          variant="lightXXS"
          customStyles={{
            fontWeight: fontWeights.normal,
          }}
        /> */}
      </AppBox>

      <AppFlex
        customStyles={{ alignItems: 'center', cursor: 'pointer' }}
        onClick={() =>
          handleNavigateToWebRecordings({
            programSlug,
            index,
            linkRef,
          })
        }
      >
        {/* eslint-disable-next-line */}
        <a
          href={getWebRecordingsURL({ programSlug, index })}
          ref={linkRef}
          style={{ display: 'none' }}
        />

        {recordingsData.file_duration && (
          <>
            <AppText
              title={showValidDate(recordingsData.file_duration)}
              variant="lightXS"
            />
            <Image src={PlayIcon} ml={2} alt="" />
          </>
        )}
      </AppFlex>
    </AppFlex>
  );
}

function StudentProgramClassRecordings() {
  const { programSlug } = useParams();
  const { data: studentProgramData, isLoading: studentProgramDataIsLoading } =
    useQuery(GET_STUDENT_LIVE_CLASS_RECORDINGS(programSlug), () =>
      makeGetRequest(GET_STUDENT_LIVE_CLASS_RECORDINGS(programSlug)),
    );

  return (
    <AppBox>
      <AppLoader isLoading={studentProgramDataIsLoading}>
        {!studentProgramDataIsLoading &&
          studentProgramData?.data?.schedules.map((recordingsData, index) => (
            <RecordingsItems
              recordingsData={recordingsData}
              programSlug={programSlug}
              index={index}
              wholeData={studentProgramData}
              key={recordingsData.id}
            />
          ))}
      </AppLoader>
    </AppBox>
  );
}

export default StudentProgramClassRecordings;
