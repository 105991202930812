import appColors from 'version-two/theme/appColors';
import { fontSizes, fontWeights, lineHeights } from 'version-two/theme/fonts';

const AppLinkStyles = {
  sizes: {
    xs: {
      fontSize: fontSizes.xs,
      fontWeight: fontWeights.xl,
      lineHeight: lineHeights.xs,
      color: appColors.brandSecondary['500'],
    },
  },
  variants: {
    authLink: {
      color: appColors.brandSecondary['500'],
      fontSize: fontSizes.sm,
      fontWeight: fontWeights.xl,
      lineHeight: lineHeights.sm,
    },

    navbarActive: {
      fontSize: fontSizes.sm,
      fontWeight: fontWeights.extrabold,

      color: appColors.brandGrey['900'],

      display: 'block',
      backgroundColor: appColors.brandGrey['50'],
      padding: '8px 16px 8px 16px',

      borderRadius: '8px',

      textDecoration: 'none',

      _hover: {
        textDecoration: 'none',
      },
    },

    navbar: {
      fontSize: fontSizes.sm,
      fontWeight: fontWeights.extrabold,

      color: appColors.brandGrey['900'],

      margin: 'auto',
      marginX: 3,

      textDecoration: 'none',

      _hover: {
        textDecoration: 'none',
      },
    },
  },
};

export default AppLinkStyles;
