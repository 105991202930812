import axios from 'axios';
import getUserToken from 'voxguru-v1/api/utils/getUserToken';
import { getHostAPIUrl } from 'appConfig';

const appAxios = axios.create({
  baseURL: getHostAPIUrl(),
  timeout: 30000,
  responseType: 'json',
});

appAxios.interceptors.request.use(async (config) => {
  if (getUserToken()) {
    // eslint-disable-next-line no-param-reassign
    config.headers = {
      Authorization: `Token ${getUserToken()}`,
    };
  }
  return config;
});

export default appAxios;
