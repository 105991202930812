const getHostAPIUrl = () => {
  if (process.env.REACT_APP_NODE_ENV === 'production')
    return 'https://api-v2.voxguru.in';
  //   return 'https://voxguru-v2-dev.ajaidanial.wtf';
  return 'https://api-staging-v2.voxguru.in';
};

const getVoxguruVersion = () =>
  process.env.REACT_APP_VERSION_ENVIRONMENT === 'v1';

const isTeacherEnvironment = () =>
  process.env.REACT_APP_USER_ENVIRONMENT === 'teacher';

export { getHostAPIUrl, isTeacherEnvironment, getVoxguruVersion };
