import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  useBreakpointValue,
} from '@chakra-ui/react';
import AppText from 'version-two/components/Typography/AppText';
import AppButton from 'version-two/components/Typography/AppButton';
import appColors from 'version-two/theme/appColors';
import { fontWeights } from 'version-two/theme/fonts';
import { appBorderRadius } from 'version-two/theme/sizes';

function FileDeleteDialog({ isOpen, onClose, leastDestructiveRef, onClick }) {
  const handleDeleteModalClose = () => {
    onClick();
    onClose();
  };
  const alertDialogSize = useBreakpointValue({ base: 'xs', sm: 'md' });

  return (
    <AlertDialog
      leastDestructiveRef={leastDestructiveRef}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size={alertDialogSize}
    >
      <AlertDialogOverlay />

      <AlertDialogContent
        style={{
          borderRadius: appBorderRadius.md,
          paddingTop: 10,
        }}
        variant="uploadDialog"
      >
        <AlertDialogHeader>
          <AppText
            title="Confirm Delete"
            size="md"
            customStyles={{
              fontWeight: fontWeights.bold,
            }}
          />
        </AlertDialogHeader>
        <AlertDialogCloseButton
          color={appColors.brandGrey['300']}
          mt={3}
          mr={2}
        />

        <AlertDialogBody>
          <AppText
            title="Deleting this note will remove its access for everyone with whom it is shared. Do you want to proceed? This can’t be undone."
            size="xs"
          />
        </AlertDialogBody>
        <AlertDialogFooter>
          <AppButton
            title="Cancel"
            ref={leastDestructiveRef}
            variant="programDrawableButton"
            customStyles={{
              marginTop: 0,
              marginRight: 1.5,
            }}
            onClick={onClose}
          />
          <AppButton
            title="Yes, proceed"
            variant="confirmDeleteButton"
            customStyles={{
              marginTop: 0,
            }}
            onClick={handleDeleteModalClose}
          />
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
export default FileDeleteDialog;
