import {
  Button,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useBreakpointValue,
} from '@chakra-ui/react';
import {
  RightArrowIcon,
  VoxGuruIcon,
  VoxGuruLogo,
  VoxGuruTrainersLogo,
} from 'voxguru-v1/assets';
import AppBox from 'voxguru-v1/components/chakraLayoutOverrides/AppBox';
import AppText from 'voxguru-v1/components/Typography/AppText';
import {
  APP_TEACHER_LOGIN_SCREEN,
  TEACHER_CHAT_PORTAL,
  TEACHER_DASHBOARD,
} from 'voxguru-v1/navigation/routes';
import AppNavLink from 'voxguru-v1/components/Typography/AppNavLink';
import AppFlex from 'voxguru-v1/components/chakraLayoutOverrides/AppFlex';
import { ChevronDownIcon } from '@chakra-ui/icons';
import appColors from 'voxguru-v1/theme/appColors';
import useAppStore from 'voxguru-v1/store';
import { useMutation } from 'react-query';
import makePostRequest from 'voxguru-v1/api/utils/makePostRequest';
import { APP_TEACHER_LOGOUT } from 'voxguru-v1/api/urls/teacher';
import removeUserToken from 'voxguru-v1/api/utils/removeUserToken';
import { useNavigate } from 'react-router-dom';
import RenderIf from 'voxguru-v1/components/RenderIf/RenderIf';
import logoutIcon from '../../assets/Vector.png';

function GetUserProfile({ userName, userLogout, isMobile }) {
  return (
    <AppFlex
      customStyles={{
        alignItems: 'center',
      }}
    >
      <RenderIf isTrue={!isMobile}>
        <AppText
          title="Hello"
          size="xs"
          customStyles={{
            color: appColors.brandGrey['900'],
          }}
        />
      </RenderIf>

      <Menu>
        <MenuButton as={Button} variant="navButton">
          <AppFlex customStyles={{ alignItems: 'center' }}>
            {isMobile ? (
              <Image src={logoutIcon} alt="" />
            ) : (
              <AppText
                customStyles={{ as: 'span' }}
                title={userName}
                size="xs"
                variant="manageLabel"
              />
            )}

            <ChevronDownIcon />
          </AppFlex>
        </MenuButton>
        <MenuList>
          <MenuItem onClick={userLogout}>Logout</MenuItem>
        </MenuList>
      </Menu>
    </AppFlex>
  );
}
function DashboardNavbar({ userLogout, userName }) {
  const isMobile = useBreakpointValue({
    base: true,
    xl: false,
  });

  const navigate = useNavigate();
  const handleNavigateToDashboard = () => {
    navigate(TEACHER_DASHBOARD);
  };

  return isMobile ? (
    <AppFlex
      variant="appMobileNavbar"
      customStyles={{
        justifyContent: 'space-between',
      }}
    >
      <AppFlex customStyles={{ alignItems: 'center', width: '100%' }}>
        <Image
          src={VoxGuruIcon}
          alt=""
          onClick={handleNavigateToDashboard}
          cursor="pointer"
        />
        {/* <AppNavLink title="Live Classes" to={TEACHER_CHAT_PORTAL} />

        <AppText title="|" variant="breakLine" /> */}
        <AppFlex customStyles={{ marginInline: 'auto' }}>
          <AppNavLink title="Programs" to={TEACHER_DASHBOARD} />
        </AppFlex>
      </AppFlex>
      <GetUserProfile
        userLogout={userLogout}
        userName={userName}
        isMobile={isMobile}
      />
    </AppFlex>
  ) : (
    <AppFlex
      variant="appNavbar"
      customStyles={{
        justifyContent: 'space-between',
      }}
    >
      <AppBox>
        <Image
          src={VoxGuruTrainersLogo}
          alt=""
          onClick={handleNavigateToDashboard}
          cursor="pointer"
        />
      </AppBox>
      <AppFlex>
        <AppNavLink title="Live Classes" to={TEACHER_CHAT_PORTAL} />
        <AppNavLink title="Programs" to={TEACHER_DASHBOARD} />
      </AppFlex>
      <GetUserProfile userLogout={userLogout} userName={userName} />
    </AppFlex>
  );
}

function ProgramsNavbar({ breadCrumbDetail, userLogout, userName }) {
  const responsiveNavbar = useBreakpointValue({ base: true, sm: false });

  const navigate = useNavigate();
  return (
    <AppFlex
      customStyles={{
        justifyContent: 'space-between',
      }}
      variant={responsiveNavbar ? 'appMobileNavbar' : 'appNavbar'}
    >
      <AppFlex customStyles={{ alignItems: 'center' }}>
        <img src={VoxGuruIcon} alt="" />
        {breadCrumbDetail.map((data, index) => (
          <AppFlex key={data.link}>
            <AppText
              title={data.name}
              variant="navbarBreadCrumb"
              customStyles={{
                cursor: 'pointer',
              }}
              onClick={() => {
                navigate(data.link);
              }}
            />
            {breadCrumbDetail.length !== index + 1 && (
              <Image src={RightArrowIcon} ml={2} />
            )}
          </AppFlex>
        ))}
      </AppFlex>
      <GetUserProfile
        userLogout={userLogout}
        userName={userName}
        isMobile={responsiveNavbar}
      />
    </AppFlex>
  );
}

function GetAuthenticatedNavbar({
  isProgram,
  userLogout,
  userName,
  breadCrumb,
}) {
  return isProgram ? (
    <ProgramsNavbar
      breadCrumbDetail={breadCrumb}
      userLogout={userLogout}
      userName={userName}
    />
  ) : (
    <DashboardNavbar userLogout={userLogout} userName={userName} />
  );
}

function AppTeacherNavbar({ isProgram, breadCrumb }) {
  const isAuthenticated = useAppStore.getState().is_authenticated;
  const username = useAppStore.getState().user_name;
  const navigate = useNavigate();
  const { mutate: teacherLogoutMutation } = useMutation(() =>
    makePostRequest(APP_TEACHER_LOGOUT),
  );

  const handleTeacherLogout = () => {
    teacherLogoutMutation();
    removeUserToken();
    navigate(APP_TEACHER_LOGIN_SCREEN);
  };

  return isAuthenticated ? (
    <GetAuthenticatedNavbar
      userLogout={handleTeacherLogout}
      userName={username}
      isProgram={isProgram}
      breadCrumb={breadCrumb}
    />
  ) : (
    <Flex height="80px" justifyContent="center" alignItems="center">
      <img src={VoxGuruLogo} alt="" />
    </Flex>
  );
}

export default AppTeacherNavbar;
