import React, { useState } from 'react';
import { SimpleGrid } from '@chakra-ui/react';
import AppBox from '../chakraLayoutOverrides/AppBox';
import PitchListItem from '../PitchListItem';
import AppLoader from '../AppLoader/AppLoader';

function SelectPitchListModal({
  onChange,
  currentPitchVal,
  isMutateLoading = false,
  pitchList,
  pitchListIsLoading = false,
}) {
  const [selPitch, setSelPitch] = useState(currentPitchVal);

  const handleSelect = (val) => {
    setSelPitch(val);
    onChange(val);
  };

  return (
    <AppBox>
      <AppLoader isLoading={pitchListIsLoading} height="50vh">
        <SimpleGrid columnGap="45px" columns={['1', '2']}>
          {pitchList?.map((m) => (
            <PitchListItem
              isLoading={selPitch?.id === m?.id && isMutateLoading}
              key={m?.id}
              isSelected={selPitch?.id === m?.id}
              data={m}
              onChange={(val) => handleSelect(val)}
            />
          ))}
        </SimpleGrid>
      </AppLoader>

      {/* <AppFlex customStyles={{ marginTop: '22px', justify: 'center' }}>
        <AppButton
          disabled={currentPitchVal?.identity == selPitch?.identity}
          title={'Change Pitch'}
          variant={'solid'}
          onClick={() => onChange(selPitch)}
          customStyles={{
            marginTop: 0,
            width: '100%',
            maxWidth: '824px',
          }}
          isLoading={false}
        />
      </AppFlex> */}
    </AppBox>
  );
}

export default SelectPitchListModal;
