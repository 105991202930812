import { Link } from '@chakra-ui/react';

function AppLink({ href, title, size, variant, children, customStyles }) {
  return (
    <Link href={href} size={size} variant={variant} {...customStyles}>
      {title || children}{' '}
    </Link>
  );
}

export default AppLink;
