// Authentication
export const APP_STUDENT_LOGIN =
  '/api/live-class-app/student/auth/obtain-auth-token/';
export const APP_STUDENT_AUTHENTICATED = `/api/live-class-app/student/auth/status/`;

// Dashboard
export const GET_STUDENT_DASHBOARD_DATA =
  '/api/live-class-app-v2/student/home/';

export const GET_STUDENT_LIVE_CLASS_DETAIL_DATA = (slug) =>
  `/api/live-class-app-v2/student/batches/${slug}/modules/`;
export const GET_STUDENT_LIVE_CLASS_MODULE_DETAIL_DATA = (slug) =>
  `/api/live-class-app/student/subscription/module/${slug}/view-in-detail/`;
export const GET_STUDENT_LIVE_CLASS_RECORDINGS = (slug) =>
  `/api/live-class-app-v2/student/batches/${slug}/recordings/`;
export const POST_PITCH_CHANGE = `/api/live-class-app-v2/student/pitch/update/`;
export const GET_PITCH_LIST = `/api/live-class-app-v2/student/pitch/meta/`;

// File
export const GET_FILE_DATA_STUDENT = (slug) =>
  `/api/live-class-app/student/content/${slug}/view-in-detail/`;
