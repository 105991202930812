import { Avatar } from '@chakra-ui/react';
import appColors from '../../theme/appColors';

function AppAvatar({ src, name, size, ...others }) {
  return (
    <Avatar
      size={size}
      name={name}
      src={src}
      bgColor={appColors.brandSecondary['100']}
      color={appColors.brandSecondary['500']}
      {...others}
    />
  );
}

export default AppAvatar;
