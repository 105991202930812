import appColors from 'version-two/theme/appColors';
import { appBorderRadius, appBreakPoints } from 'version-two/theme/sizes';

const AppBoxStyles = {
  variants: {
    programCard: {
      border: `2px solid`,
      borderColor: appColors.brandGrey['50'],
      borderRadius: appBorderRadius.md,

      padding: 4,

      marginTop: 4,
      marginRight: 5,

      minWidth: '290px',
      maxWidth: '290px',

      cursor: 'pointer',

      transition: 'all 0.2s ease-in-out',

      _hover: {
        borderColor: appColors.brandPrimary['300'],
      },

      [`@media screen and (max-width: ${appBreakPoints.sm})`]: {
        width: '100%',

        marginRight: 0,

        minWidth: '100%',
        maxWidth: '100%',
      },
    },

    programDrawableBlock: {
      paddingX: 5,
      paddingY: 3,
    },

    mobileNavbar: {
      boxShadow: `0px 1px 0px ${appColors.brandGrey['100']}`,
      display: 'flex',
      alignItems: 'center',
      marginBottom: '2.125rem',
    },
  },
};

export default AppBoxStyles;
