import {
  Button,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useBreakpointValue,
  TabList,
  Tab,
  useDisclosure,
  Drawer,
  DrawerContent,
  VStack,
  Box,
  Text,
  Img,
} from '@chakra-ui/react';
import {
  // RightArrowIcon,
  VoxGuruIcon,
  VoxGuruLogo,
  VoxGuruTrainersLogo,
  HamMenu,
  Close,
} from 'version-two/assets';
import AppBox from 'version-two/components/chakraLayoutOverrides/AppBox';
import AppText from 'version-two/components/Typography/AppText';
import {
  APP_TEACHER_LOGIN_SCREEN,
  TEACHER_CHAT_PORTAL,
  TEACHER_DASHBOARD,
  TEACHER_DASHBOARD_COMPLETED,
} from 'version-two/navigation/routes';
import AppFlex from 'version-two/components/chakraLayoutOverrides/AppFlex';
import appColors from 'version-two/theme/appColors';
import useAppStore from 'version-two/store';
import { useMutation } from 'react-query';
import makePostRequest from 'version-two/api/utils/makePostRequest';
import { APP_TEACHER_LOGOUT } from 'version-two/api/urls/teacher';
import removeUserToken from 'version-two/api/utils/removeUserToken';
import { useNavigate, useParams } from 'react-router-dom';
import RenderIf from 'version-two/components/RenderIf/RenderIf';
import { ChevronDownIcon, ArrowBackIcon } from '@chakra-ui/icons';
import AppNavLink from 'version-two/components/Typography/AppNavLink';
import { CometChatUIKit } from '@cometchat/chat-uikit-react';
import logoutIcon from '../../assets/Vector.png';

function GetUserProfile({ userName, userLogout, isMobile, mobileO }) {
  const { programSlug } = useParams();
  const navigate = useNavigate();
  const giveMobileNavBar = () => {
    const navigateToCompletedBatches = () => {
      navigate(TEACHER_DASHBOARD_COMPLETED);
    };
    const navigateToTrainerGroupChat = () => {
      navigate(TEACHER_CHAT_PORTAL);
    };
    if (programSlug === undefined) {
      return (
        <>
          <MenuButton as={Button} variant="navButton">
            <AppFlex customStyles={{ alignItems: 'center' }}>
              <Image src={logoutIcon} alt="" />
              <ChevronDownIcon />
            </AppFlex>
          </MenuButton>
          <MenuList>
            <MenuItem onClick={navigateToCompletedBatches}>
              Completed Batches
            </MenuItem>
            <MenuItem onClick={navigateToTrainerGroupChat}>Group Chat</MenuItem>
            <MenuItem onClick={userLogout}>Logout</MenuItem>
          </MenuList>
        </>
      );
    }
    return <Image src={HamMenu} mr="6px" alt="" onClick={mobileO} />;
  };
  return (
    <AppFlex
      customStyles={{
        alignItems: 'center',
        zIndex: 9,
      }}
    >
      <RenderIf isTrue={!isMobile}>
        <AppText
          title="Hello"
          size="xs"
          customStyles={{
            color: appColors.brandGrey['900'],
          }}
        />
      </RenderIf>
      <Menu>
        {isMobile ? (
          giveMobileNavBar()
        ) : (
          <>
            <MenuButton as={Button} variant="navButton">
              <AppFlex customStyles={{ alignItems: 'center' }}>
                <AppText
                  customStyles={{ as: 'span' }}
                  title={userName}
                  size="xs"
                  variant="manageLabel"
                />
                <ChevronDownIcon />
              </AppFlex>
            </MenuButton>
            <MenuList>
              <MenuItem onClick={userLogout}>Logout</MenuItem>
            </MenuList>
          </>
        )}
      </Menu>
    </AppFlex>
  );
}
function DashboardNavbar({ userLogout, userName }) {
  const isMobile = useBreakpointValue({
    base: true,
    xl: false,
  });

  const navigate = useNavigate();
  const handleNavigateToDashboard = () => {
    navigate(TEACHER_DASHBOARD);
  };

  return isMobile ? (
    <AppFlex
      variant="appMobileNavbar"
      customStyles={{
        justifyContent: 'space-between',
      }}
    >
      <AppFlex
        customStyles={{
          width: '100%',
        }}
      >
        <AppFlex
          customStyles={{
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: '10px',
          }}
        >
          <Image
            src={VoxGuruIcon}
            alt=""
            onClick={handleNavigateToDashboard}
            cursor="pointer"
          />
        </AppFlex>
        <AppFlex
          customStyles={{
            marginInline: 'auto',
          }}
        >
          <AppNavLink title="My Batches" to={TEACHER_DASHBOARD} />
          {/* <AppText title="|" variant="breakLine" />
          <AppNavLink title="Group Chat" to={TEACHER_CHAT_PORTAL} /> */}
          {/* <AppText title="|" variant="breakLine" />
          <AppNavLink
            title="Completed Batches"
            to={TEACHER_DASHBOARD_COMPLETED}
          /> */}
        </AppFlex>
      </AppFlex>
      <GetUserProfile
        userLogout={userLogout}
        userName={userName}
        isMobile={isMobile}
      />
    </AppFlex>
  ) : (
    <AppFlex
      variant="appNavbar"
      customStyles={{
        justifyContent: 'space-between',
      }}
    >
      <AppBox>
        <Image
          src={VoxGuruTrainersLogo}
          alt=""
          onClick={handleNavigateToDashboard}
          cursor="pointer"
        />
      </AppBox>
      <AppFlex>
        {/* {!disableNavLink === '/trainer/dashboard/' && ( */}
        <>
          <AppNavLink title="Batches" to={TEACHER_DASHBOARD} />

          <AppNavLink title="Group Chat" to={TEACHER_CHAT_PORTAL} />
          <AppNavLink
            title="Completed Batches"
            to={TEACHER_DASHBOARD_COMPLETED}
          />
        </>
        {/* )} */}
      </AppFlex>
      <GetUserProfile userLogout={userLogout} userName={userName} />
    </AppFlex>
  );
}

function ProgramsNavbar({ userLogout, userName, tabIndex }) {
  const responsiveNavbar = useBreakpointValue({ base: true, sm: false });
  const navigate = useNavigate();

  const handleNavigateToDashboard = () => {
    navigate(TEACHER_DASHBOARD);
  };
  const {
    isOpen: mobileNavIsOpen,
    onOpen: mobileNavOnOpen,
    onClose: mobileNaveOnClose,
  } = useDisclosure();

  return (
    <>
      <Flex flexDirection="column">
        <AppFlex
          customStyles={{
            justifyContent: 'space-between',
          }}
          variant={responsiveNavbar ? 'appMobileNavbar' : 'appNavbar'}
        >
          <AppFlex customStyles={{ alignItems: 'center' }}>
            {!responsiveNavbar ? (
              <Image
                src={VoxGuruLogo}
                alt=""
                onClick={handleNavigateToDashboard}
                cursor="pointer"
              />
            ) : (
              <AppFlex
                onClick={handleNavigateToDashboard}
                customStyles={{ alignItems: 'center' }}
              >
                <ArrowBackIcon />
                <AppText title="My Batches" variant="navbarBreadCrumb" />
              </AppFlex>
            )}

            {/* {breadCrumbDetail.map((data, index) => (
            <AppFlex key={data.link}>
              <AppText
                title={data.name}
                variant="navbarBreadCrumb"
                customStyles={{
                  cursor: 'pointer',
                }}
                onClick={() => {
                  navigate(data.link);
                }}
              />
              {breadCrumbDetail.length !== index + 1 && (
                <Image src={RightArrowIcon} ml={2} />
              )}
            </AppFlex>
          ))} */}
          </AppFlex>
          {/* <AppFlex>
        <AppNavLink title="Cousrse material" to={TEACHER_CHAT_PORTAL} />
        <AppNavLink title="Session flow" to={TEACHER_DASHBOARD} />
        <AppNavLink title="Schedule" to={TEACHER_DASHBOARD} />
        <AppNavLink title="Zoom details" to={TEACHER_DASHBOARD} />
      </AppFlex> */}

          {!responsiveNavbar && (
            <TabList>
              <Tab>Course material</Tab>
              <Tab>Session flow</Tab>
              <Tab>Schedule</Tab>
              <Tab>Zoom details</Tab>
              <Tab>Active Students</Tab>
            </TabList>
          )}
          <GetUserProfile
            userLogout={userLogout}
            userName={userName}
            isMobile={responsiveNavbar}
            mobileO={mobileNavOnOpen}
          />
        </AppFlex>
      </Flex>
      {responsiveNavbar && (
        <Drawer
          isOpen={mobileNavIsOpen}
          onClose={mobileNaveOnClose}
          placement="right"
        >
          <DrawerContent>
            <Box>
              <TabList
                ml="40px"
                mt="68px"
                width="80%"
                borderBottom="1px solid #D9D9D9"
              >
                <VStack textAlign="left" gap="14px" pb="22px">
                  <Tab
                    fontSize={23}
                    width="100%"
                    justifyContent="left"
                    color={tabIndex === 0 ? 'black' : '#8B5971'}
                    fontWeight="700"
                    onClick={mobileNaveOnClose}
                  >
                    Course material
                  </Tab>
                  <Tab
                    fontSize={23}
                    width="100%"
                    justifyContent="left"
                    color={tabIndex === 1 ? 'black' : '#8B5971'}
                    fontWeight="700"
                    onClick={mobileNaveOnClose}
                  >
                    Session flow
                  </Tab>
                  <Tab
                    fontSize={23}
                    width="100%"
                    justifyContent="left"
                    color={tabIndex === 2 ? 'black' : '#8B5971'}
                    fontWeight="700"
                    onClick={mobileNaveOnClose}
                  >
                    Schedule
                  </Tab>
                  <Tab
                    fontSize={23}
                    width="100%"
                    justifyContent="left"
                    color={tabIndex === 3 ? 'black' : '#8B5971'}
                    fontWeight="700"
                    onClick={mobileNaveOnClose}
                  >
                    Zoom details
                  </Tab>
                  <Tab
                    fontSize={23}
                    width="100%"
                    justifyContent="left"
                    color={tabIndex === 4 ? 'black' : '#8B5971'}
                    fontWeight="700"
                    onClick={mobileNaveOnClose}
                  >
                    Active Students
                  </Tab>
                </VStack>
              </TabList>
              <Text
                mt="15px"
                ml="40px"
                fontSize={23}
                color="#8B5971"
                fontWeight="700"
                onClick={userLogout}
              >
                Logout
              </Text>
              <Img
                pt="26px"
                pr="22px"
                position="absolute"
                top="0"
                right="0"
                src={Close}
                alt="close"
                onClick={mobileNaveOnClose}
              />
            </Box>
          </DrawerContent>
        </Drawer>
      )}
    </>
  );
}

function GetAuthenticatedNavbar({
  isProgram,
  userLogout,
  userName,
  breadCrumb,
  tabIndex,
}) {
  return isProgram ? (
    <ProgramsNavbar
      breadCrumbDetail={breadCrumb}
      userLogout={userLogout}
      userName={userName}
      tabIndex={tabIndex}
    />
  ) : (
    <DashboardNavbar userLogout={userLogout} userName={userName} />
  );
}

function AppTeacherNavbar({ isProgram, breadCrumb, tabIndex }) {
  const isAuthenticated = useAppStore.getState().is_authenticated;
  const { setCometChatLoading } = useAppStore();
  const username = useAppStore.getState().user_name;
  const navigate = useNavigate();
  const { mutate: teacherLogoutMutation } = useMutation(
    () => makePostRequest(APP_TEACHER_LOGOUT),
    {
      onSuccess: () => {
        CometChatUIKit.getLoggedinUser().then((user) => {
          if (user) {
            CometChatUIKit.logout().then(() => {
              // eslint-disable-next-line
              console.log("User logged out");
            });
          }
        });
        setCometChatLoading(true);
        removeUserToken();
        navigate(APP_TEACHER_LOGIN_SCREEN);
      },
    },
  );

  const handleTeacherLogout = () => {
    teacherLogoutMutation();
  };

  return isAuthenticated ? (
    <GetAuthenticatedNavbar
      tabIndex={tabIndex}
      userLogout={handleTeacherLogout}
      userName={username}
      isProgram={isProgram}
      breadCrumb={breadCrumb}
    />
  ) : (
    <Flex height="80px" justifyContent="center" alignItems="center">
      <img src={VoxGuruLogo} alt="" />
    </Flex>
  );
}

export default AppTeacherNavbar;
