import {
  Button,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  useDisclosure,
  useBreakpointValue,
} from '@chakra-ui/react';
import AppText from 'voxguru-v1/components/Typography/AppText';
import AppFlex from 'voxguru-v1/components/chakraLayoutOverrides/AppFlex';
import {
  ImageIcon,
  Mp3Icon,
  PdfIcon,
  ShareIcon,
  TripleDotsIcon,
  VideoIcon,
} from 'voxguru-v1/assets';
import { useMutation, useQueryClient } from 'react-query';
import {
  GET_TEACHER_LIVE_CLASS_MODULE_IN_DETAIL,
  LIVE_CLASS_TEACHER_FILE_DELETE,
} from 'voxguru-v1/api/urls/teacher';
import appColors from 'voxguru-v1/theme/appColors';
import { useRef } from 'react';
import AppBox from 'voxguru-v1/components/chakraLayoutOverrides/AppBox';
import makeDeleteRequest from 'voxguru-v1/api/utils/makeDeleteRequest';
import TeacherNoteShareDialog from 'voxguru-v1/components/TeacherNoteShareDialog/TeacherNoteShareDialog';
import FileDeleteDialog from 'voxguru-v1/components/FileDeleteDialog/FileDeleteDialog';
import {
  MAKE_APP_AUDIO_PLAYER,
  MAKE_APP_IMAGE_VIEWER,
  MAKE_APP_PDF_VIEWER,
  MAKE_APP_VIDEO_PLAYER,
} from 'voxguru-v1/navigation//routes';
import getCurrentUserType from 'voxguru-v1/utils/getCurrentUserType';
import { FROM_WEB_VIEW } from 'voxguru-v1/utils/constants';

function ContentTitleCard({
  file,
  fileId,
  title,
  shareCount,
  hasShare,
  activeModule,
  corecontent,
}) {
  const cancelRef = useRef();
  const fileLink = useRef();

  const {
    isOpen: fileDialogIsOpen,
    onOpen: fileDialogOnOpen,
    onClose: fileDialogOnClose,
  } = useDisclosure();

  const {
    isOpen: shareNoteIsOpen,
    onOpen: shareNoteOnOpen,
    onClose: shareNoteOnClose,
  } = useDisclosure();

  const queryClient = useQueryClient();

  const { mutate: fileDeleteTeacher, isLoading: fileDeleteIsLoading } =
    useMutation(
      () => makeDeleteRequest(LIVE_CLASS_TEACHER_FILE_DELETE(fileId)),
      {
        onSuccess: () => {
          queryClient.invalidateQueries(
            GET_TEACHER_LIVE_CLASS_MODULE_IN_DETAIL(activeModule),
          );
        },
      },
    );

  const getFileType = () => file && file.split('.').pop();

  const getIconForCard = () => {
    const fileType = getFileType();
    if (fileType === 'pdf') {
      return PdfIcon;
    }
    if (fileType === 'mp4') {
      return VideoIcon;
    }
    if (fileType === 'mp3') {
      return Mp3Icon;
    }
    return ImageIcon;
  };

  // eslint-disable-next-line consistent-return
  const getFileUrl = () => {
    const fileType = getFileType();

    const imageFileTypes = ['jpg', 'png', 'jpeg'];
    if (imageFileTypes.includes(fileType)) {
      return MAKE_APP_IMAGE_VIEWER(getCurrentUserType(), fileId);
    }
    if (fileType === 'mp4') {
      return MAKE_APP_VIDEO_PLAYER(getCurrentUserType(), fileId);
    }
    if (fileType === 'mp3' || fileType === 'm4a' || fileType === 'aac') {
      return MAKE_APP_AUDIO_PLAYER(getCurrentUserType(), fileId);
    }
    if (fileType === 'pdf') {
      return MAKE_APP_PDF_VIEWER(getCurrentUserType(), fileId);
    }
  };

  const handleFileOpen = () => {
    const isWebView = localStorage.getItem(FROM_WEB_VIEW);

    if (isWebView) {
      fileLink.current.click();
    } else {
      window.open(getFileUrl());
    }
  };

  const handleFileDelete = () => {
    fileDeleteTeacher();
  };

  const responsive = useBreakpointValue({ base: corecontent ? '95%' : '70%' });

  return (
    <AppBox customStyles={{ position: 'relative' }}>
      <a href={getFileUrl()} ref={fileLink} style={{ display: 'none' }}>
        Testing
      </a>
      <AppFlex variant="contentItem" onClick={handleFileOpen}>
        <AppFlex customStyles={{ width: responsive, alignItems: 'center' }}>
          {fileDeleteIsLoading && (
            <Spinner mr={3} color={appColors.brandPrimary['300']} />
          )}
          <Image
            src={getIconForCard()}
            alt=""
            mr={3}
            height="22px"
            width="22px"
          />
          <AppText title={title} size="sm" customStyles={{ width: '80%' }} />
        </AppFlex>
      </AppFlex>

      {hasShare && (
        <AppFlex
          customStyles={{
            alignItems: 'center',
            position: 'absolute',
            top: '8px',
            right: '15px',
          }}
        >
          <Image src={ShareIcon} mr={2} />
          <AppText title={shareCount.toString()} variant="lightXS" />
          <Menu>
            <MenuButton as={Button} variant="tripleIconButton">
              <Image src={TripleDotsIcon} margin="auto" />
            </MenuButton>
            <MenuList>
              <MenuItem onClick={handleFileOpen}>Open Note</MenuItem>
              <MenuItem onClick={shareNoteOnOpen}>Share Note</MenuItem>
              <MenuItem onClick={fileDialogOnOpen}>Delete Note</MenuItem>
            </MenuList>
          </Menu>
        </AppFlex>
      )}

      {shareNoteIsOpen && (
        <TeacherNoteShareDialog
          isOpen={shareNoteIsOpen}
          onClose={shareNoteOnClose}
          activeModule={fileId}
        />
      )}
      {fileDialogIsOpen && (
        <FileDeleteDialog
          isOpen={fileDialogIsOpen}
          onClose={fileDialogOnClose}
          leastDestructiveRef={cancelRef}
          onClick={handleFileDelete}
        />
      )}
      {shareNoteIsOpen && (
        <TeacherNoteShareDialog
          noteName={title}
          isOpen={shareNoteIsOpen}
          onClose={shareNoteOnClose}
          activeModule={fileId}
        />
      )}
    </AppBox>
  );
}

export default ContentTitleCard;
