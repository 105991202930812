export const APP_TEACHER = 'teacher';
export const APP_STUDENT = 'student';

export const FROM_WEB_VIEW = 'from_web_view';
export const IS_IOS_DEVICE = 'is_ios_device';

export const COMET_CHAT_APP_ID = process.env.REACT_APP_COMET_CHAT_APP_ID;
export const COMET_CHAT_APP_REGION = process.env.REACT_APP_APP_REGION;
export const COMET_CHAT_AUTH_KEY = process.env.REACT_APP_COMET_CHAT_AUTH_KEY;

export const REACT_NATIVE_MESSAGES = {
  GO_BACK: 'go-back',
  SWITCH_TO_PORTRAIT: 'switch-to-portrait',
  SWITCH_TO_LANDSCAPE: 'switch-to-landscape',
};
