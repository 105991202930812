import { CloseButton } from '@chakra-ui/react';
import AppLoader from 'version-two/components/AppLoader/AppLoader';
import getIsWebView from 'version-two/utils/getIsWebView';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { REACT_NATIVE_MESSAGES } from '../../../utils/constants';

const handleTurnToLandscape = () => {
  window.ReactNativeWebView.postMessage(
    REACT_NATIVE_MESSAGES.SWITCH_TO_LANDSCAPE,
  );
};

const handleTurnToPotrait = () => {
  window.ReactNativeWebView.postMessage(
    REACT_NATIVE_MESSAGES.SWITCH_TO_PORTRAIT,
  );
};

function AppVideoPlayer({ url, loading }) {
  const navigate = useNavigate();
  const isWebView = useMemo(() => getIsWebView(), []);

  useEffect(() => {
    if (getIsWebView()) {
      handleTurnToLandscape();
    }
  }, []);

  return (
    <AppLoader isLoading={loading}>
      {!loading && (
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <>
          {isWebView && (
            <CloseButton
              onClick={() => {
                navigate(-1);
                if (isWebView) {
                  handleTurnToPotrait();
                }
              }}
              size="lg"
              style={{
                position: 'absolute',
                top: '15px',
                left: '15px',
              }}
              zIndex="100"
            />
          )}
          {/* eslint-disable-next-line jsx-a11y/media-has-caption  */}
          <video
            controls
            onContextMenu="return false;"
            controlsList="nodownload"
            style={{
              height: '100vh',
              width: '100vw',
            }}
          >
            <source
              type="video/mp4"
              // src="https://voxguru-v2.s3.amazonaws.com/Raga%20Mohanam%20and%20Pantuvarali/RMP_148_PAN_SA_Full%20Varnam%202nd%20KALA.mp4"
              src={url}
            />
          </video>
        </>
      )}
    </AppLoader>
  );
}

export default AppVideoPlayer;
