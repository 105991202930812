import { NavLink } from 'react-router-dom';
import { forwardRef } from 'react';
import AppText from 'version-two/components/Typography/AppText';
import { Link } from '@chakra-ui/react';

const AppNavLink = forwardRef(({ to, title }, ref) => (
  <NavLink
    to={to}
    ref={ref}
    style={{ margin: 'auto', display: 'flex', textDecoration: 'none' }}
  >
    {({ isActive }) => (
      <Link href={to} as="span" variant={isActive ? 'navbarActive' : 'navbar'}>
        <AppText title={title} variant="navText" />
      </Link>
    )}
  </NavLink>
));

export default AppNavLink;
