import { Box, Flex } from '@chakra-ui/react';
import AppText from 'voxguru-v1/components/Typography/AppText';
import AppBox from 'voxguru-v1/components/chakraLayoutOverrides/AppBox';
import TeacherDashboardLayout from 'voxguru-v1/layout/TeacherDashboardLayout';
import { useNavigate } from 'react-router-dom';
import { MAKE_TEACHER_PROGRAM_DETAIL } from 'voxguru-v1/navigation//routes';
import { useQuery } from 'react-query';
import { GET_TEACHER_LIST_PROGRAMS } from 'voxguru-v1/api/urls/teacher';
import makeGetRequest from 'voxguru-v1/api/utils/makeGetRequest';
import AppLoader from 'voxguru-v1/components/AppLoader/AppLoader';

function TeacherProgramList() {
  const { data: programListData, isLoading: programListIsLoading } = useQuery(
    GET_TEACHER_LIST_PROGRAMS,
    () => makeGetRequest(GET_TEACHER_LIST_PROGRAMS),
  );

  const navigate = useNavigate();
  return (
    <TeacherDashboardLayout>
      <AppLoader isLoading={programListIsLoading}>
        {!programListIsLoading &&
          programListData.data.map((data) => (
            <Box key={data.identity} mb={5}>
              <AppText title={data.identity} variant="header" />

              <Flex flexWrap="wrap">
                {data.related_live_classes.map((moduleData) => (
                  <AppBox
                    variant="programCard"
                    onClick={() => {
                      navigate(MAKE_TEACHER_PROGRAM_DETAIL(moduleData.id));
                    }}
                    key={moduleData.identity}
                  >
                    <AppText title={moduleData.identity} size="md" />
                    <AppText
                      title={`${moduleData.modules_count} Modules`}
                      variant="lightXS"
                    />
                  </AppBox>
                ))}
              </Flex>
            </Box>
          ))}
      </AppLoader>
    </TeacherDashboardLayout>
  );
}

export default TeacherProgramList;
