import ProgramTitleCard from 'version-two/components/ProgramTitleCard/ProgramTitleCard';
import { Box, Flex, Avatar } from '@chakra-ui/react';
import AppText from 'version-two/components/Typography/AppText';
// import GroupUserProfileImage from 'version-two/components/GroupUserProfileImage/GroupUserProfileImage';
import { useQuery } from 'react-query';
import { GET_ACTIVE_STUDENTS_LIST } from 'version-two/api/urls/teacher';
import makeGetRequest from 'version-two/api/utils/makeGetRequest';
import { useParams } from 'react-router-dom';
import AppFlex from 'version-two/components/chakraLayoutOverrides/AppFlex';
import appColors from 'version-two/theme/appColors';

function ActiveStudentsTab() {
  const { programSlug } = useParams();

  const { data: programDetail, isLoading: programDetailIsLoading } = useQuery(
    GET_ACTIVE_STUDENTS_LIST(programSlug),
    () => makeGetRequest(GET_ACTIVE_STUDENTS_LIST(programSlug)),
  );

  // const { searchDefaultProps } = useStudentSearch({ programSlug });

  //   const drawable = useBreakpointValue({ base: true, sm: false });

  return (
    <Box>
      {!programDetailIsLoading && (
        <>
          <ProgramTitleCard
            title="Active Students"
            type={programDetail.data?.identity}
          />
          <Box textTransform="capitalize">
            <Flex
              alignItems="center"
              mt={3}
              py={5}
              boxShadow={`0px 1px 0px 0px ${appColors.brandGrey['100']} inset;`}
              display={{ base: 'none', sm: 'flex' }}
              width="100%"
            >
              <Box
                ml={3}
                style={{
                  flexBasis: '55%',
                }}
              >
                <AppText title="Active Students" variant="tableHeader" />
              </Box>
              <Box
                style={{
                  flexBasis: '20%',
                }}
              >
                <AppText title="" variant="tableHeader" />
              </Box>
            </Flex>
            {programDetail.data.students.map((student) => (
              <AppFlex
                customStyles={{
                  alignItems: 'center',
                }}
                variant="moduleListingCard"
                key={student.id}
              >
                <Flex
                  direction={{ base: 'column', sm: 'row' }}
                  alignItems={{ base: 'left', md: 'center', lg: 'center' }}
                  style={{
                    flexBasis: '80%',
                  }}
                >
                  <Flex
                    style={{
                      flexBasis: '70%',
                      alignItems: 'center',
                      gap: '6px',
                    }}
                  >
                    <Avatar
                      variant="profile"
                      // bg="yellow.300"
                      size="sm"
                      key={student?.id}
                      name={student?.name}
                      src={student?.image}
                      bg="#8B5971"
                      color="white"
                    />
                    <AppText
                      title={student.name}
                      size="sm"
                      customStyles={{
                        cursor: 'pointer',
                        width: 'fit-content',
                      }}
                    />
                  </Flex>
                </Flex>
              </AppFlex>
            ))}
          </Box>
        </>
      )}
    </Box>
  );
}

export default ActiveStudentsTab;
