// Authentication
export const APP_STUDENT_LOGIN =
  '/api/live-class-app/student/auth/obtain-auth-token/';
export const APP_STUDENT_AUTHENTICATED = `/api/live-class-app/student/auth/status/`;

// Dashboard
export const GET_STUDENT_DASHBOARD_DATA = '/api/live-class-app/student/home/';
export const GET_STUDENT_LIVE_CLASS_DETAIL_DATA = (slug) =>
  `/api/live-class-app/student/subscription/${slug}/view-in-detail/`;
export const GET_STUDENT_LIVE_CLASS_MODULE_DETAIL_DATA = (slug) =>
  `/api/live-class-app/student/subscription/module/${slug}/view-in-detail/`;

// File
export const GET_FILE_DATA_STUDENT = (slug) =>
  `/api/live-class-app/student/content/${slug}/view-in-detail/`;
