import { Box, Container } from '@chakra-ui/react';
import AppStudentNavbar from 'voxguru-v1/components/AppStudentNavbar/AppStudentNavbar';

function StudentDashboardLayout({ children }) {
  return (
    <Box>
      <AppStudentNavbar />
      <Container maxW="1000px">{children}</Container>
    </Box>
  );
}

export default StudentDashboardLayout;
