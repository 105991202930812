import React from 'react';
import AppText from 'version-two/components/Typography/AppText';
import AppFlex from 'version-two/components/chakraLayoutOverrides/AppFlex';
import { RightArrowIcon } from 'version-two/assets';
import { useNavigate } from 'react-router-dom';
import { Image } from '@chakra-ui/react';

function PageBreadCrumb({ breadCrumb }) {
  const navigate = useNavigate();
  return (
    <AppFlex
      customStyles={{ alignItems: 'center', position: 'absolute', left: '2vw' }}
    >
      {breadCrumb?.map((data, index) => (
        <AppFlex key={data.link}>
          <AppText
            title={data.name}
            variant="navbarBreadCrumb"
            customStyles={{
              cursor: 'pointer',
            }}
            onClick={() => {
              navigate(data.link);
            }}
          />
          {breadCrumb?.length !== index + 1 && (
            <Image src={RightArrowIcon} ml={2} />
          )}
        </AppFlex>
      ))}
    </AppFlex>
  );
}
export default PageBreadCrumb;
