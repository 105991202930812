import { Box, useStyleConfig } from '@chakra-ui/react';

function AppBox({ size, variant, children, customStyles, onClick, role }) {
  const styles = useStyleConfig('AppBox', { size, variant });
  return (
    <Box sx={styles} role={role} {...customStyles} onClick={onClick}>
      {children}
    </Box>
  );
}

export default AppBox;
