import getUserToken from 'version-two/api/utils/getUserToken';
import { APP_TEACHER_IS_AUTHENTICATED } from 'version-two/api/urls/teacher';
import axios from 'axios';
import { APP_STUDENT_AUTHENTICATED } from 'version-two/api/urls';
import { getHostAPIUrl, isTeacherEnvironment } from 'appConfig';

const isTeacherAuthenticated = async () => {
  const token = getUserToken();
  let response;

  if (isTeacherEnvironment()) {
    response = axios.get(getHostAPIUrl() + APP_TEACHER_IS_AUTHENTICATED, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
  } else {
    response = axios.get(getHostAPIUrl() + APP_STUDENT_AUTHENTICATED, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
  }

  return response;
};

export default isTeacherAuthenticated;
