import AppText from 'version-two/components/Typography/AppText';
import AppFlex from 'version-two/components/chakraLayoutOverrides/AppFlex';

function ProgramTitleCard({ title, type }) {
  return (
    <AppFlex
      variant="programTitleHeader"
      customStyles={{ marginBottom: '2.25em' }}
    >
      <AppText title={type} variant="tile" />
      <AppText
        fontSize={{ sm: 'xl', md: '2xl' }}
        title={title}
        variant="programHeader"
      />
    </AppFlex>
  );
}

export default ProgramTitleCard;
