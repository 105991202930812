import ProgramDrawableHeader from 'voxguru-v1/components/ProgramDrawableHeader/ProgramDrawableHeader';
import AppBox from 'voxguru-v1/components/chakraLayoutOverrides/AppBox';
import AppText from 'voxguru-v1/components/Typography/AppText';
import {
  Drawer,
  DrawerContent,
  DrawerOverlay,
  ListItem,
  UnorderedList,
  useBreakpointValue,
} from '@chakra-ui/react';
import AppFlex from 'voxguru-v1/components/chakraLayoutOverrides/AppFlex';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import appColors from 'voxguru-v1/theme/appColors';
import { Mp3Icon } from 'voxguru-v1/assets';
import { useQuery } from 'react-query';

import makeGetRequest from 'voxguru-v1/api/utils/makeGetRequest';
import AppLoader from 'voxguru-v1/components/AppLoader/AppLoader';
import { GET_STUDENT_LIVE_CLASS_MODULE_DETAIL_DATA } from 'voxguru-v1/api/urls';
import ContentTitleCard from 'voxguru-v1/components/ContentTitleCard/ContentTitleCard';

function StudentProgramDetailDrawable({
  isOpen,
  onClose,
  activeModule,
  moduleName,
}) {
  const { data: liveClassModuleData, isLoading: liveClassModuleDataIsLoading } =
    useQuery(GET_STUDENT_LIVE_CLASS_MODULE_DETAIL_DATA(activeModule), () =>
      makeGetRequest(GET_STUDENT_LIVE_CLASS_MODULE_DETAIL_DATA(activeModule)),
    );
  const drawerSize = useBreakpointValue({ base: 'xs', sm: 'sm', md: 'md' });
  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      placement="right"
      size={drawerSize}
    >
      <DrawerOverlay>
        <DrawerContent overflow="scroll">
          <AppLoader isLoading={liveClassModuleDataIsLoading}>
            {!liveClassModuleDataIsLoading && (
              <>
                <ProgramDrawableHeader
                  moduleName={moduleName}
                  programModule={`MODULE ${liveClassModuleData.data.position}`}
                  programTitle={liveClassModuleData.data.identity}
                />

                <AppBox variant="programDrawableBlock">
                  <AppText
                    title="Objectives"
                    variant="programDrawableSubTitle"
                  />
                  <UnorderedList>
                    {Array.isArray(liveClassModuleData.data.objectives) &&
                      liveClassModuleData.data.objectives.map((data) => (
                        <ListItem>
                          <AppText title={data} size="xs" />
                        </ListItem>
                      ))}
                  </UnorderedList>
                </AppBox>

                {/* Core Content */}
                <AppBox variant="programDrawableBlock">
                  <AppFlex
                    customStyles={{
                      alignItems: 'center',
                    }}
                  >
                    <AppText
                      title="Core Content"
                      variant="programDrawableSubTitle"
                    />
                    <InfoOutlineIcon
                      ml={2}
                      color={appColors.brandGrey['200']}
                    />
                  </AppFlex>

                  {Array.isArray(liveClassModuleData.data.core_contents) &&
                    liveClassModuleData.data.core_contents.map((data) => (
                      <ContentTitleCard
                        file={data.file}
                        icon={Mp3Icon}
                        fileId={data.id}
                        title={data.identity}
                      />
                    ))}
                </AppBox>

                {/* Teachers Note */}
                <AppFlex variant="programDrawableBlock">
                  <AppFlex
                    customStyles={{
                      alignItems: 'center',
                    }}
                  >
                    <AppText
                      title="Teachers Note"
                      variant="programDrawableSubTitle"
                    />
                    <InfoOutlineIcon
                      ml={2}
                      color={appColors.brandGrey['200']}
                    />
                  </AppFlex>
                </AppFlex>
                <AppBox variant="programDrawableBlock">
                  {liveClassModuleData.data.enabled_teacher_contents.map(
                    (teacherNoteData) => (
                      <ContentTitleCard
                        file={teacherNoteData.teacher_content.file}
                        fileId={teacherNoteData.teacher_content.id}
                        title={teacherNoteData.teacher_content.identity}
                      />
                    ),
                  )}
                </AppBox>
              </>
            )}
          </AppLoader>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
}
export default StudentProgramDetailDrawable;
