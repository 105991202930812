import appAxios from 'version-two/api/appAxios';
import getUserToken from './getUserToken';

const makeGetRequest = async (endpoint, params = {}) => {
  const { data } = await appAxios.get(endpoint, {
    params,
  });
  getUserToken();
  return data;
};

export default makeGetRequest;
