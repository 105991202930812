import { components as reactSelectComponents } from 'chakra-react-select';
import AppFlex from 'voxguru-v1/components/chakraLayoutOverrides/AppFlex';
import AppAvatar from 'voxguru-v1/components/AppAvatar/AppAvatar';
import AppText from 'voxguru-v1/components/Typography/AppText';

function AppSelectCustomMenuList({ children, data, ...props }) {
  return (
    <reactSelectComponents.Option {...props}>
      <AppFlex
        variant="moduleAccessList"
        customStyles={{
          opacity: data?.isDisabled && 0.6,
          justifyContent: 'space-between',
        }}
      >
        <AppFlex>
          <AppAvatar size="sm" name={children} src={data?.imageSrc} />
          <AppText
            title={children}
            customStyles={{ ml: 3 }}
            size="sm"
            variant="bold"
          />
        </AppFlex>

        {data?.unlockedDate && <AppText title={data?.unlockedDate} />}
      </AppFlex>
    </reactSelectComponents.Option>
  );
}

export default AppSelectCustomMenuList;
