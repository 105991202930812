import useGetFileData from 'voxguru-v1/hooks/useGetFileData';
import AppVideoPlayer from 'voxguru-v1/pages/common/AppVideoPlayer/AppVideoPlayer';

function AppVideoPlayerWrapper() {
  const { data, loading } = useGetFileData();

  return <AppVideoPlayer url={data} loading={loading} />;
}

export default AppVideoPlayerWrapper;
