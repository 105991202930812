import AppInput from 'version-two/components/Typography/AppInput';
import { Select } from 'chakra-react-select';
import { forwardRef } from 'react';

const AppSelect = forwardRef(
  ({ options, isMulti, onChange, value, customComponents }, ref) => {
    // Need for chakra ui to work with `InputLeftElement`
    AppInput.id = 'Input';

    return (
      <Select
        isClearable
        ref={ref}
        isSearchable
        onChange={onChange}
        value={value}
        options={options}
        isMulti={isMulti}
        components={{
          ...customComponents,
        }}
      />
    );
  },
);
export default AppSelect;
