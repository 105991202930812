// Authentication
export const APP_TEACHER_LOGIN =
  '/api/live-class-app/teacher/auth/obtain-auth-token/';
export const APP_TEACHER_IS_AUTHENTICATED = `/api/live-class-app/teacher/auth/status/`;
export const APP_TEACHER_LOGOUT = `/api/live-class-app/teacher/auth/logout/`;

// Program Listing
export const GET_TEACHER_LIST_PROGRAMS =
  '/api/live-class-app-v2/teacher/home/batches/';
// '/api/live-class-app/teacher/home/teaching/programs/';

export const GET_TEACHER_ID_MODULE = (slug) =>
  `/api/live-class-app-v2/teacher/batches/${slug}/modules/`;

export const GET_TEACHER_ID_SESSION_FLOW = (slug) =>
  `/api/live-class-app-v2/teacher/batches/${slug}/session-flow/`;

export const GET_TEACHER_ID_SCHEDULE = (slug) =>
  `/api/live-class-app-v2/teacher/batches/${slug}/schedules/`;

// Program Detail
export const GET_TEACHER_LIVE_CLASS_DETAIL = (slug) =>
  `/api/live-class-app/teacher/live-class/${slug}/view-in-detail/`;

// Program Module Detail
export const GET_TEACHER_LIVE_CLASS_MODULE_IN_DETAIL = (slug) =>
  `/api/live-class-app-v2/modules/${slug}/view-in-detail/`;

// Program Session Detail
export const GET_TEACHER_LIVE_CLASS_SESSION_IN_DETAIL = (slug) =>
  `/api/live-class-app-v2/session-flow/${slug}/view-in-detail/`;

// Get Students List for the Program
export const GET_ACTIVE_STUDENTS_LIST = (slug) =>
  `/api/live-class-app-v2/teacher/batches/${slug}/students/`;

export const GET_INACCESSIBLE_STUDENTS_LIST = (batchId, teacherContentId) =>
  `/api/live-class-app-v2/teacher-content/${teacherContentId}/${batchId}/share-access-bulk/`;

// View File
export const GET_FILE_DATA_TEACHER = (slug) =>
  `/api/live-class-app-v2/content/${slug}/view-in-detail/`;
// `/api/live-class-app/teacher/content/${slug}/view-in-detail/`;

// File Upload
export const LIVE_CLASS_TEACHER_FILE_UPLOAD = (slug) =>
  `/api/live-class-app-v2/modules/${slug}/create-teacher-content/`;
export const COMMON_FILE_UPLOAD = `/api/live-class-app/teacher/common/file/upload/`;

// File Delete
export const LIVE_CLASS_TEACHER_FILE_DELETE = (slug) =>
  `/api/live-class-app-v2/teacher-content/${slug}/delete/`;

// GET STUDENTS FOR MANAGE MODULE
export const GET_STUDENTS_FOR_MANAGE = (slug) =>
  `/api/live-class-app/teacher/live-class/module/${slug}/share-access-bulk/`;

export const UNLOCK_MODULES_BULK_ACCESS = (slug) =>
  `/api/live-class-app/teacher/live-class/module/${slug}/share-access-bulk/`;

// GET STUDENTS FOR SHARE NOTE

export const GET_STUDENT_FOR_SHARE_NOTE_ACCESS = (slug) =>
  `/api/live-class-app/teacher/teacher-content/${slug}/share-access-bulk/`;

export const SHARE_NOTE_BULK_ACCESS = (slug) =>
  `/api/live-class-app-v2/teacher-content/${slug}/share-access-bulk/`;

// Student Search
export const PROGRAM_DETAIL_STUDENT_SEARCH = (searchVal, liveClassId) =>
  // eslint-disable-next-line max-len
  `/api/live-class-app/teacher/my-students/search/?search=${searchVal}&live_classes_eligible=${liveClassId}`;

export const GET_INDIVIDUAL_STUDENT_PROGRAM_DETAIL = (programSlug, studentId) =>
  `/api/live-class-app/teacher/live-class/${programSlug}/student/${studentId}/actions-display/`;

export const INDIVIDUAL_STUDENT_MODULE_COURSE_LOCK =
  '/api/live-class-app/teacher/live-class/module/student-actions/lock-unlock/lock/';

export const INDIVIDUAL_STUDENT_MODULE_COURSE_UNLOCK =
  '/api/live-class-app/teacher/live-class/module/student-actions/lock-unlock/unlock/';

export const GET_TEACHER_CHAT_LISTING = `/api/live-class-app/teacher/home/chat-groups/`;
