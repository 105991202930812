import { Button } from '@chakra-ui/react';

function AppButton({
  title,
  children,
  onClick,
  variant,
  customStyles = 'solid',
  isLoading,
  type,
  ref,
  disabled = false,
}) {
  return (
    <Button
      type={type}
      onClick={onClick}
      variant={variant}
      isLoading={isLoading}
      {...customStyles}
      ref={ref}
      disabled={disabled}
    >
      {title || children}
    </Button>
  );
}

export default AppButton;
