import appColors from 'version-two/theme/appColors';

const AppInputStyles = {
  baseStyle: {
    height: '48px',
  },
  variants: {
    outline: {
      field: {
        height: '48px',
        borderRadius: '12px',
        borderColor: appColors.brandSecondary['200'],
      },
    },
  },
};

export default AppInputStyles;
