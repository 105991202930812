import AppText from 'voxguru-v1/components/Typography/AppText';
import AppFlex from 'voxguru-v1/components/chakraLayoutOverrides/AppFlex';

function ProgramTitleCard({ title, type }) {
  return (
    <AppFlex variant="programTitleHeader">
      <AppText title={type} variant="tile" />
      <AppText title={title} variant="programHeader" />
    </AppFlex>
  );
}

export default ProgramTitleCard;
