import { getVoxguruVersion } from 'appConfig';
import AppSwitch from './version-two/navigation/switch/index';
import AppSwitchV1 from './voxguru-v1/navigation/switch/index';

// import AppSwitch as AppSecond from './voxguru-v2/navigation/switch/index';
// import {getHostAPIUrl} from 'appConfig';

function App() {
  return getVoxguruVersion() ? <AppSwitchV1 /> : <AppSwitch />;
}

export default App;
