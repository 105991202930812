import StudentDashboardLayout from 'version-two/layout/StudentDashboardLayout';
import AppText from 'version-two/components/Typography/AppText';
import AppBox from 'version-two/components/chakraLayoutOverrides/AppBox';
import AppFlex from 'version-two/components/chakraLayoutOverrides/AppFlex';
import AppAvatar from 'version-two/components/AppAvatar/AppAvatar';
import { useNavigate } from 'react-router-dom';
import { MAKE_STUDENT_PROGRAM_DETAIL } from 'version-two/navigation/routes';
import { useQuery } from 'react-query';
import { GET_STUDENT_DASHBOARD_DATA } from 'version-two/api/urls';
import makeGetRequest from 'version-two/api/utils/makeGetRequest';
import AppLoader from 'version-two/components/AppLoader/AppLoader';

function StudentProgramList() {
  const navigate = useNavigate();
  const { data, isLoading } = useQuery(GET_STUDENT_DASHBOARD_DATA, () =>
    makeGetRequest(GET_STUDENT_DASHBOARD_DATA),
  );

  return (
    <StudentDashboardLayout>
      <AppLoader isLoading={isLoading}>
        {!isLoading && (
          <>
            <AppText title="Live Classes" variant="header" />
            {data.data.map((liveClassData) => (
              <AppFlex
                key={liveClassData.id}
                variant="studentProgramCard"
                onClick={() =>
                  navigate(MAKE_STUDENT_PROGRAM_DETAIL(liveClassData?.id))
                }
              >
                <AppBox>
                  <AppText
                    title={
                      liveClassData?.live_class_v2.title_student ||
                      liveClassData?.teacher?.name
                    }
                    size="sm"
                  />
                  <AppText
                    title={
                      `${liveClassData?.week_day} ${liveClassData?.batch_timings}` ||
                      ''
                    }
                    variant="lightXS"
                  />
                </AppBox>
                <AppAvatar
                  name={liveClassData?.teacher?.name}
                  src={liveClassData?.teacher?.live_class_profile_photo}
                />
              </AppFlex>
            ))}
          </>
        )}
      </AppLoader>
    </StudentDashboardLayout>
  );
}

export default StudentProgramList;
