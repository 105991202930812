import { useState } from 'react';

const useHandlePassword = () => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () =>
    setShowPassword((prevShowPassword) => !prevShowPassword);

  return { showPassword, togglePasswordVisibility };
};

export default useHandlePassword;
