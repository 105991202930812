import { useMutation } from 'react-query';
import makeGetRequest from 'version-two/api/utils/makeGetRequest';
import { PROGRAM_DETAIL_STUDENT_SEARCH } from 'version-two/api/urls';
import { useDebouncedCallback } from 'use-debounce';
import {
  MAKE_TEACHER_PROGRAM_DETAIL,
  MAKE_TEACHER_PROGRAM_DETAIL_INDIVIDUAL_STUDENT,
} from 'version-two/navigation/routes';
import { useNavigate } from 'react-router-dom';

const useStudentSearch = ({ programSlug }) => {
  const {
    mutate: studentListingMutate,
    data: studentListingData,
    isLoading: studentListingIsLoading,
  } = useMutation((searchVal) =>
    makeGetRequest(PROGRAM_DETAIL_STUDENT_SEARCH(searchVal, programSlug)),
  );
  const navigate = useNavigate();
  const debouncedSearchMutation = useDebouncedCallback(
    (val) => studentListingMutate(val),
    200,
  );

  const handleStudentChange = (options) => {
    if (options.length === 0) {
      navigate(MAKE_TEACHER_PROGRAM_DETAIL(programSlug));
      return;
    }
    navigate(
      MAKE_TEACHER_PROGRAM_DETAIL_INDIVIDUAL_STUDENT(
        programSlug,
        options.pop().value,
      ),
    );
  };

  const handleLoadOptions = (val) => {
    debouncedSearchMutation(val);
  };

  const getStudentSearchOptions = (data) =>
    (data || []).map((studentData) => ({
      value: studentData.id,
      label: studentData.name,
    }));

  const options = getStudentSearchOptions(studentListingData?.data);

  const searchDefaultProps = {
    isMulti: true,
    onInputChange: handleLoadOptions,
    onChange: handleStudentChange,
    onFocus: () => handleLoadOptions(''),
    isLoading: studentListingIsLoading,
    options,
    placeholder: 'Find a student',
    noOptionsMessage: () =>
      studentListingIsLoading ? `Loading ...` : 'No Options',
  };
  return {
    handleLoadOptions,
    handleStudentChange,
    isLoading: studentListingIsLoading,
    options,
    searchDefaultProps,
  };
};

export default useStudentSearch;
