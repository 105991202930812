import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { GET_STUDENT_LIVE_CLASS_DETAIL_DATA } from 'voxguru-v1/api/urls';
import makeGetRequest from 'voxguru-v1/api/utils/makeGetRequest';
import AppVideoPlayer from 'voxguru-v1/pages/common/AppVideoPlayer/AppVideoPlayer';

function WebRecordingsVideo() {
  const { recordingsIndex = 0, programSlug } = useParams();
  const { data: studentProgramData, isLoading: studentProgramDataIsLoading } =
    useQuery(GET_STUDENT_LIVE_CLASS_DETAIL_DATA(programSlug), () =>
      makeGetRequest(GET_STUDENT_LIVE_CLASS_DETAIL_DATA(programSlug)),
    );

  return (
    <AppVideoPlayer
      loading={studentProgramDataIsLoading}
      url={studentProgramData?.data?.recordings?.[recordingsIndex].resource_url}
    />
  );
}

export default WebRecordingsVideo;
