// ---------------------------------------------------------------------------------------------------------------------
// -------------------------------------- TEACHER ----------------------------------------------------------------------
export const TEACHER_DASHBOARD = '/trainer/dashboard';
export const TEACHER_DASHBOARD_COMPLETED = '/trainer/completed-batches';
export const TEACHER_PROGRAM_DETAIL = '/trainer/dashboard/:programSlug/detail';
export const TEACHER_PROGRAM_DETAIL_INDIVIDUAL_STUDENT =
  '/trainer/dashboard/:programSlug/detail/:studentId/student';
export const TEACHER_PROGRAM_DETAIL_CONTENT =
  '/trainer/dashboard/:programSlug/detail/:moduleSlug/content';
export const TEACHER_CHAT_LISTING = '/trainer/chat';
export const TEACHER_CHAT_PORTAL = '/trainer/chat/portal';
export const TEACHER_CHAT_PORTAL_GROUP_DETAIL = '/trainer/chat/portal/:groupID';

// URL MAKERS
export const MAKE_TEACHER_PROGRAM_DETAIL = (programSlug) =>
  `/trainer/dashboard/${programSlug}/detail`;
export const MAKE_TEACHER_PROGRAM_DETAIL_INDIVIDUAL_STUDENT = (
  programSlug,
  studentId,
) => `/trainer/dashboard/${programSlug}/detail/${studentId}/student`;

export const MAKE_TEACHER_PROGRAM_DETAIL_CONTENT = (programSlug, moduleSlug) =>
  `/trainer/dashboard/${programSlug}/detail/${moduleSlug}/content`;

export const MAKE_TEACHER_CHAT_PORTAL_GROUP_DETAIL = (groupID) =>
  `/trainer/chat/portal/${groupID}`;

// ---------------------------------------------------------------------------------------------------------------------
// -------------------------------------- STUDENT ----------------------------------------------------------------------
export const STUDENT_DASHBOARD = '/student/dashboard';
export const STUDENT_PROGRAM_DETAIL = '/student/dashboard/:programSlug/detail';
export const STUDENT_PROGRAM_DETAIL_CONTENT =
  '/student/dashboard/:programSlug/detail/:moduleSlug/content';
export const STUDENT_CHAT_PORTAL =
  '/student/dashboard/:cometChatUserID/group-chat';
export const STUDENT_CHAT_PORTAL_GROUP_DETAIL =
  '/student/dashboard/:cometChatUserID/group-chat/:groupID';

export const MAKE_STUDENT_PROGRAM_DETAIL = (slug) =>
  `/student/dashboard/${slug}/detail`;
export const MAKE_STUDENT_PROGRAM_DETAIL_CONTENT = (contentSlug, moduleSlug) =>
  `/student/dashboard/${contentSlug}/detail/${moduleSlug}/content`;
export const MAKE_STUDENT_CHAT_PORTAL = (cometUserId) =>
  `/student/dashboard/${cometUserId}/group-chat`;

export const MAKE_STUDENT_CHAT_PORTAL_GROUP_DETAIL = (cometUserId, groupID) =>
  `/student/dashboard/${cometUserId}/group-chat/${groupID}`;
// ---------------------------------------------------------------------------------------------------------------------
// -------------------------------------- COMMON ----------------------------------------------------------------------
export const APP_VIDEO_PLAYER = '/:userType/:contentSlug/video/:pitch';
export const APP_AUDIO_PLAYER = '/:userType/:contentSlug/audio/:pitch';
export const APP_PDF_VIEWER = '/:userType/:contentSlug/pdf/:pitch';
export const APP_IMAGE_VIEWER = '/:userType/:contentSlug/image/:pitch';
export const APP_WEB_RECORDINGS_VIDEO_PLAYER_STUDENT =
  '/student/:programSlug/:week/web-recordings';
export const APP_CLASS_RECORDINGS_VIDEO_PLAYER =
  '/:programSlug/:weekId/class-recordings';

export const MAKE_APP_VIDEO_PLAYER = (
  userType,
  contentSlug,
  pitch = 'no-pitch',
) => `/${userType}/${contentSlug}/video/${pitch}`;
export const MAKE_APP_AUDIO_PLAYER = (
  userType,
  contentSlug,
  pitch = 'no-pitch',
) => `/${userType}/${contentSlug}/audio/${pitch}`;
export const MAKE_APP_PDF_VIEWER = (
  userType,
  contentSlug,
  pitch = 'no-pitch',
) => `/${userType}/${contentSlug}/pdf/${pitch}`;
export const MAKE_APP_IMAGE_VIEWER = (
  userType,
  contentSlug,
  pitch = 'no-pitch',
) => `/${userType}/${contentSlug}/image/${pitch}`;
export const MAKE_APP_WEB_RECORDINGS_VIDEO_PLAYER = (programSlug, weekId) =>
  `/${programSlug}/${weekId}/class-recordings`;
export const MAKE_APP_CLASS_RECORDINGS_VIDEO_PLAYER = (programSlug, week) =>
  `/student/${programSlug}/${week}/web-recordings`;
