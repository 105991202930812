import appColors from '../appColors';

const AppAvatarStyles = {
  parts: ['container', 'excessLabel', 'label'],
  baseStyle: {
    backgroundColor: appColors.brandSecondary['200'],
    color: appColors.brandSecondary['500'],
  },
  variants: {
    profile: {
      backgroundColor: appColors.brandSecondary['200'],
      color: appColors.brandSecondary['500'],
    },
  },
};

export default AppAvatarStyles;
