// import TeacherDashboardLayout from 'version-two/layout/TeacherDashboardLayout';
import ProgramTitleCard from 'version-two/components/ProgramTitleCard/ProgramTitleCard';
import { Box, Flex, Image } from '@chakra-ui/react';
import AppText from 'version-two/components/Typography/AppText';
// import ManageModuleAccessBulk from 'version-two/components/ManageModuleAccessBulk/ManageModuleAccessBulk';
import { useQuery } from 'react-query';
import { GET_TEACHER_ID_SESSION_FLOW } from 'version-two/api/urls/teacher';
import makeGetRequest from 'version-two/api/utils/makeGetRequest';
// import AppLoader from 'version-two/components/AppLoader/AppLoader';
import { useParams } from 'react-router-dom';
import AppFlex from 'version-two/components/chakraLayoutOverrides/AppFlex';
// import { Select } from 'chakra-react-select';
// import useStudentSearch from 'version-two/hooks/useStudentSearch';
import { MAKE_APP_PDF_VIEWER } from 'version-two/navigation/routes';
import appColors from 'version-two/theme/appColors';
import { PdfIcon } from 'version-two/assets';

function TeacherScheduleFlow() {
  // const navigate = useNavigate();
  const { programSlug } = useParams();

  const { data: programDetail } = useQuery(
    GET_TEACHER_ID_SESSION_FLOW(programSlug),
    () => makeGetRequest(GET_TEACHER_ID_SESSION_FLOW(programSlug)),
  );

  // const [activeModule, setActiveModule] = useState();

  // const {
  //   isOpen: programDrawableIsOpen,
  //   onOpen: programDrawableOnOpen,
  //   onClose: programDrawableOnClose,
  // } = useDisclosure();

  // const {
  //   isOpen: moduleManageIsOpen,
  //   onClose: moduleManageOnClose,
  // } = useDisclosure();

  // const handleManageClick = (module) => {
  //   setActiveModule(module);
  //   moduleManageOnOpen();
  // };

  // const handleManageClose = () => {
  //   moduleManageOnClose();
  // };

  // const handleNavigateToDrawable = () => {
  //   navigate(MAKE_TEACHER_PROGRAM_DETAIL_CONTENT(programSlug, programSlug));
  // };

  // const handleContentOpen = (module) => {
  //   setActiveModule(module);
  //   programDrawableOnOpen();
  // };

  // const handleDrawableClose = () => {
  //   setActiveModule(null);
  //   programDrawableOnClose();
  // };
  // const handleContentClose = () => {
  //   navigate(MAKE_TEACHER_PROGRAM_DETAIL(programSlug));
  //   handleDrawableClose();
  // };

  // const { searchDefaultProps } = useStudentSearch({ programSlug });

  // const drawable = useBreakpointValue({ base: true, sm: false });

  // useEffect(() => {
  //   if (programDetail && moduleSlug) {
  //     // programDetail.data.session_flow.content.some((programData) => {
  //     //   if (programData.id === moduleSlug) {
  //     //     handleContentOpen(programData);
  //     //     return true;
  //     //   }
  //     //   return false;
  //     handleContentOpen(programDetail);
  //     // });
  //   }

  //   if (!moduleSlug) {
  //     handleDrawableClose();
  //   }
  // }, [moduleSlug, programDetail]);

  // const openDrawer = (session) => {
  //   handleContentOpen(session);
  // };

  // const pageBreadCrumbDetail = [
  //   {
  //     name: 'Your Batches',
  //     link: TEACHER_DASHBOARD,
  //   },
  //   {
  //     name: programDetail?.data?.batch.identity,
  //     link: MAKE_TEACHER_PROGRAM_DETAIL(programSlug),
  //   },
  // ];

  const navigateToPDF = (id) => {
    window.open(MAKE_APP_PDF_VIEWER('teacher', id));
  };
  return (
    <>
      <ProgramTitleCard
        title="Session Flow"
        type={programDetail?.data?.batch.identity}
      />
      <Box>
        <Flex
          alignItems="center"
          mt={3}
          py={5}
          boxShadow={`0px 1px 0px 0px ${appColors.brandGrey['100']} inset;`}
          display={{ base: 'none', sm: 'flex' }}
          width="100%"
        >
          <Box
            style={{
              flexBasis: '55%',
            }}
          >
            <AppText title="WEEK" variant="tableHeader" />
          </Box>
          <Box
            style={{
              flexBasis: '20%',
            }}
          >
            <AppText title="CONTENT" variant="tableHeader" />
          </Box>
        </Flex>
        {programDetail?.data?.session_flow.content_files.map(
          (liveClassData) => (
            <AppFlex
              customStyles={{
                alignItems: 'center',
              }}
              variant="moduleListingCard"
              key={liveClassData.identity}
            >
              <Flex
                alignItems={{ base: 'left', md: 'center', lg: 'center' }}
                justifyContent={{ base: 'space-between', md: 'left' }}
                flexBasis={{ base: '100%', sm: '80%' }}
                // onClick={
                //   drawable
                //     ? () => handleNavigateToDrawable(liveClassData)
                //     : undefined
                // }
              >
                <Box
                  flexBasis={{ base: '50%', sm: '70%' }}
                  ml={{ base: '20px', sm: '0px' }}
                >
                  <AppText
                    title={`Week ${liveClassData.week_number}`}
                    size="sm"
                    // onClick={() => openDrawer(liveClassData)}
                    customStyles={{
                      cursor: 'pointer',
                      width: 'fit-content',
                    }}
                  />
                </Box>
                <Box
                  // display={{ base: 'none', sm: 'block' }}
                  style={{
                    flexBasis: '20%',
                    cursor: 'pointer',
                    alignItems: 'center',
                  }}
                  onClick={() => navigateToPDF(liveClassData.id)}
                >
                  <Flex>
                    <Image src={PdfIcon} alt="" height="22px" width="22px" />
                    {/* <AppText
                      title={
                        Array.isArray(liveClassData.files)
                          ? liveClassData.files.length
                          : '1'
                      }
                      customStyles={{ ml: 2 }}
                    /> */}
                  </Flex>
                </Box>
                {/* <Box>
                  <GroupUserProfileImage
                    studentsDisplay={
                      liveClassData.accessible_students_display || []
                    }
                  />
                </Box> */}
              </Flex>
              {/* {programDetail?.data?.teacher
              ?.can_provide_live_class_module_access && (
              <Box
                style={{
                  flexBasis: '20%',
                }}
                onClick={() => handleManageClick(liveClassData)}
              >
                <Flex
                  style={{
                    cursor: 'pointer',
                  }}
                >
                  <img src={ManageLockIcon} alt="" />
                  <AppText title="Manage" size="xs" variant="manageLabel" />
                </Flex>
              </Box>
            )} */}
            </AppFlex>
          ),
        )}
      </Box>
      {/* {programDrawableIsOpen && (
        <TeacherProgramSessionFlowDrawable
          isOpen={programDrawableIsOpen}
          onClose={handleContentClose}
          activeSession={activeModule}
        />
      )} */}
      {/* {moduleManageIsOpen && (
        <ManageModuleAccessBulk
          isOpen={moduleManageIsOpen}
          onClose={handleManageClose}
          activeModule={activeModule.id}
          moduleName={activeModule.identity}
        />
      )} */}
    </>
  );
}

export default TeacherScheduleFlow;
