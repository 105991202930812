import { Image } from '@chakra-ui/react';
import React from 'react';
import { CurrentPitch } from 'version-two/assets';
import AppText from 'version-two/components/Typography/AppText';
import AppBox from 'version-two/components/chakraLayoutOverrides/AppBox';
import AppFlex from 'version-two/components/chakraLayoutOverrides/AppFlex';

function ShowCurrentPitch({ currentPitchVal }) {
  return (
    <AppBox>
      <AppText
        title="Current Pitch"
        variant="extraBoldBlack"
        customStyles={{ mb: 1, fontSize: '19px', fontWeight: 700 }}
      />

      <AppFlex customStyles={{ alignItems: 'center' }}>
        <Image h={['30px', '60px']} w={['30px', '60px']} src={CurrentPitch} />
        <AppBox customStyles={{ ml: 5 }}>
          <AppText
            customStyles={{ mb: 1, fontSize: '19px', fontWeight: 700 }}
            title={currentPitchVal?.identity}
            size="sm"
          />
        </AppBox>
      </AppFlex>
    </AppBox>
  );
}

export default ShowCurrentPitch;
