import TeacherDashboardLayout from 'voxguru-v1/layout/TeacherDashboardLayout';
import ProgramTitleCard from 'voxguru-v1/components/ProgramTitleCard/ProgramTitleCard';
import {
  Box,
  Flex,
  Stack,
  useDisclosure,
  useBreakpointValue,
} from '@chakra-ui/react';
import AppText from 'voxguru-v1/components/Typography/AppText';
import { CopyContentIcon, ManageLockIcon } from 'voxguru-v1/assets';
import GroupUserProfileImage from 'voxguru-v1/components/GroupUserProfileImage/GroupUserProfileImage';
import TeacherProgramDetailDrawable from 'voxguru-v1/components/TeacherProgramDetailDrawable/TeacherProgramDetailDrawable';
import ManageModuleAccessBulk from 'voxguru-v1/components/ManageModuleAccessBulk/ManageModuleAccessBulk';
import { useQuery } from 'react-query';
import { GET_TEACHER_LIVE_CLASS_DETAIL } from 'voxguru-v1/api/urls/teacher';
import makeGetRequest from 'voxguru-v1/api/utils/makeGetRequest';
import AppLoader from 'voxguru-v1/components/AppLoader/AppLoader';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import AppFlex from 'voxguru-v1/components/chakraLayoutOverrides/AppFlex';
import { Select } from 'chakra-react-select';
import useStudentSearch from 'voxguru-v1/hooks/useStudentSearch';
import {
  MAKE_TEACHER_PROGRAM_DETAIL,
  MAKE_TEACHER_PROGRAM_DETAIL_CONTENT,
  TEACHER_DASHBOARD,
} from 'voxguru-v1/navigation//routes';
import appColors from 'voxguru-v1/theme/appColors';

function TeacherProgramDetail() {
  const navigate = useNavigate();
  const { programSlug, moduleSlug } = useParams();

  const { data: programDetail, isLoading: programDetailIsLoading } = useQuery(
    GET_TEACHER_LIVE_CLASS_DETAIL(programSlug),
    () => makeGetRequest(GET_TEACHER_LIVE_CLASS_DETAIL(programSlug)),
  );

  const [activeModule, setActiveModule] = useState();

  const {
    isOpen: programDrawableIsOpen,
    onOpen: programDrawableOnOpen,
    onClose: programDrawableOnClose,
  } = useDisclosure();

  const {
    isOpen: moduleManageIsOpen,
    onOpen: moduleManageOnOpen,
    onClose: moduleManageOnClose,
  } = useDisclosure();

  const handleManageClick = (module) => {
    setActiveModule(module);
    moduleManageOnOpen();
  };

  const handleManageClose = () => {
    moduleManageOnClose();
  };

  const handleNavigateToDrawable = (module) => {
    navigate(MAKE_TEACHER_PROGRAM_DETAIL_CONTENT(programSlug, module.id));
  };

  const handleContentOpen = (module) => {
    setActiveModule(module);
    programDrawableOnOpen();
  };

  const handleDrawableClose = () => {
    setActiveModule(null);
    programDrawableOnClose();
  };
  const handleContentClose = () => {
    navigate(MAKE_TEACHER_PROGRAM_DETAIL(programSlug));
    handleDrawableClose();
  };

  const { searchDefaultProps } = useStudentSearch({ programSlug });

  const drawable = useBreakpointValue({ base: true, sm: false });

  useEffect(() => {
    if (programDetail && moduleSlug) {
      programDetail.data.related_live_class_modules.some((programData) => {
        if (programData.id === moduleSlug) {
          handleContentOpen(programData);
          return true;
        }
        return false;
      });
    }

    if (!moduleSlug) {
      handleDrawableClose();
    }
  }, [moduleSlug, programDetail]);

  const pageBreadCrumbDetail = [
    {
      name: 'Program',
      link: TEACHER_DASHBOARD,
    },
    {
      name: programDetail?.data?.identity,
      link: MAKE_TEACHER_PROGRAM_DETAIL(programSlug),
    },
  ];
  return (
    <TeacherDashboardLayout isProgram breadCrumb={pageBreadCrumbDetail}>
      <AppLoader isLoading={programDetailIsLoading}>
        {!programDetailIsLoading && (
          <>
            <ProgramTitleCard
              title={programDetail.data.identity}
              type={programDetail.data.category.identity}
            />

            <Stack spacing={4} mt={5}>
              <Select {...searchDefaultProps} />
            </Stack>

            {/* Table */}
            <Box>
              <Flex
                alignItems="center"
                mt={3}
                py={5}
                boxShadow={`0px 1px 0px 0px ${appColors.brandGrey['100']} inset;`}
                display={{ base: 'none', sm: 'flex' }}
              >
                <Box
                  style={{
                    flexBasis: '40%',
                  }}
                >
                  <AppText title="Modules" variant="tableHeader" />
                </Box>
                <Box
                  style={{
                    flexBasis: '20%',
                  }}
                >
                  <AppText title="Content" variant="tableHeader" />
                </Box>
                <Box
                  style={{
                    flexBasis: '20%',
                  }}
                />
                <Box
                  style={{
                    flexBasis: '20%',
                  }}
                >
                  <AppText title="Module Access" variant="tableHeader" />
                </Box>
              </Flex>
              {programDetail.data.related_live_class_modules.map(
                (liveClassData) => (
                  <AppFlex
                    customStyles={{
                      alignItems: 'center',
                    }}
                    variant="moduleListingCard"
                    key={liveClassData.identity}
                  >
                    <Flex
                      direction={{ base: 'column', sm: 'row' }}
                      style={{
                        flexBasis: '80%',
                      }}
                      onClick={
                        drawable
                          ? () => handleNavigateToDrawable(liveClassData)
                          : undefined
                      }
                    >
                      <Box
                        style={{
                          flexBasis: '50%',
                        }}
                      >
                        <AppText
                          title={`MODULE ${liveClassData.position}`}
                          size="xxs"
                          variant="light"
                        />
                        <AppText
                          title={liveClassData.identity}
                          size="sm"
                          onClick={() =>
                            handleNavigateToDrawable(liveClassData)
                          }
                          customStyles={{
                            cursor: 'pointer',
                            width: 'fit-content',
                          }}
                        />
                      </Box>
                      <Box
                        display={{ base: 'none', sm: 'block' }}
                        style={{
                          flexBasis: '20%',
                          cursor: 'pointer',
                        }}
                        onClick={() => handleNavigateToDrawable(liveClassData)}
                      >
                        <Flex>
                          <img src={CopyContentIcon} alt="" />
                          <AppText
                            title={liveClassData.content_count || '0'}
                            customStyles={{ ml: 2 }}
                          />
                        </Flex>
                      </Box>
                      <Box
                        style={{
                          flexBasis: '30%',
                        }}
                      >
                        <GroupUserProfileImage
                          studentsDisplay={
                            liveClassData.accessible_students_display || []
                          }
                        />
                      </Box>
                    </Flex>
                    {programDetail?.data?.teacher
                      ?.can_provide_live_class_module_access && (
                      <Box
                        style={{
                          flexBasis: '20%',
                        }}
                        onClick={() => handleManageClick(liveClassData)}
                      >
                        <Flex
                          style={{
                            cursor: 'pointer',
                          }}
                        >
                          <img src={ManageLockIcon} alt="" />
                          <AppText
                            title="Manage"
                            size="xs"
                            variant="manageLabel"
                          />
                        </Flex>
                      </Box>
                    )}
                  </AppFlex>
                ),
              )}
            </Box>
            {programDrawableIsOpen && (
              <TeacherProgramDetailDrawable
                isOpen={programDrawableIsOpen}
                onClose={handleContentClose}
                activeModule={activeModule.id}
                activeModuleName={activeModule.identity}
                activeStudentManage={handleManageClick}
                canManageAccess={
                  programDetail?.data?.teacher
                    ?.can_provide_live_class_module_access
                }
              />
            )}
            {moduleManageIsOpen && (
              <ManageModuleAccessBulk
                isOpen={moduleManageIsOpen}
                onClose={handleManageClose}
                activeModule={activeModule.id}
                moduleName={activeModule.identity}
              />
            )}
          </>
        )}
      </AppLoader>
    </TeacherDashboardLayout>
  );
}

export default TeacherProgramDetail;
