import { Avatar, AvatarGroup } from '@chakra-ui/react';

function GroupUserProfileImage({ studentsDisplay }) {
  return (
    <AvatarGroup size="sm">
      {studentsDisplay.map((eachStudent) => (
        <Avatar
          key={eachStudent.id}
          name={eachStudent.name}
          src={eachStudent.image}
        />
      ))}
    </AvatarGroup>
  );
}

export default GroupUserProfileImage;
