import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { useLayoutEffect, useState, lazy, Suspense } from 'react';
// import AppStudentLoginScreen from 'voxguru-v1/pages/auth/AppStudentLoginScreen/AppStudentLoginScreen';
// import AppTeacherLoginScreen from 'voxguru-v1/pages/auth/AppTeacherLoginScreen/AppTeacherLoginScreen';
// import TeacherProgramList from 'voxguru-v1/pages/teacherDashboard/TeacherProgramList/TeacherProgramList';
// import TeacherProgramDetail from 'voxguru-v1/pages/teacherDashboard/TeacherProgramDetail/TeacherProgramDetail';
// import StudentProgramList from 'voxguru-v1/pages/studentDashboard/StudentProgramList/StudentProgramList';
// import TeacherProgramDetailSingleStudent from 'voxguru-v1/pages/teacherDashboard/TeacherProgramDetailSingleStudent/TeacherProgramDetailSingleStudent';
// import AppAudioPlayer from 'voxguru-v1/pages/common/AppAudioPlayer/AppAudioPlayer';
// import AppPdfViewer from 'voxguru-v1/pages/common/AppPdfViewer/AppPdfViewer';
// import AppImageViewer from 'voxguru-v1/pages/common/AppImageViewer/AppImageViewer';
// import TeacherChatList from 'voxguru-v1/pages/teacherDashboard/TeacherChatList/TeacherChatList';
// import AppLoader from 'voxguru-v1/components/AppLoader/AppLoader';
// import StudentProgramDetailWrapper from 'voxguru-v1/pages/studentDashboard/StudentProgramDetailWrapper/StudentProgramDetailWrapper';
// import AppVideoPlayerWrapper from 'voxguru-v1/pages/common/AppVideoPlayerWrapper/AppVideoPlayerWrapper';
// import WebRecordingsVideo from 'voxguru-v1/pages/common/WebRecordingsVideo/WebRecordingsVideo';
import { isTeacherEnvironment } from 'appConfig';
import { useBreakpointValue } from '@chakra-ui/react';
import useAppStore from '../../store';
import removeUserToken from '../../api/utils/removeUserToken';
import getUserToken from '../../api/utils/getUserToken';
import isUserAuthenticated from '../../api/utils/isUserAuthenticated';
import {
  APP_TEACHER_LOGIN_SCREEN,
  TEACHER_DASHBOARD,
  APP_STUDENT_LOGIN_SCREEN,
  TEACHER_PROGRAM_DETAIL,
  STUDENT_DASHBOARD,
  STUDENT_PROGRAM_DETAIL,
  TEACHER_PROGRAM_DETAIL_INDIVIDUAL_STUDENT,
  APP_VIDEO_PLAYER,
  APP_AUDIO_PLAYER,
  APP_PDF_VIEWER,
  STUDENT_PROGRAM_DETAIL_CONTENT,
  TEACHER_PROGRAM_DETAIL_CONTENT,
  APP_IMAGE_VIEWER,
  TEACHER_CHAT_LISTING,
  TEACHER_CHAT_PORTAL,
  STUDENT_CHAT_PORTAL,
  TEACHER_CHAT_PORTAL_GROUP_DETAIL,
  STUDENT_CHAT_PORTAL_GROUP_DETAIL,
  APP_WEB_RECORDINGS_VIDEO_PLAYER,
} from '../routes';
import AppVideoPlayerWrapper from '../../pages/common/AppVideoPlayerWrapper/AppVideoPlayerWrapper';
import AppAudioPlayer from '../../pages/common/AppAudioPlayer/AppAudioPlayer';
import AppImageViewer from '../../pages/common/AppImageViewer/AppImageViewer';
import WebRecordingsVideo from '../../pages/common/WebRecordingsVideo/WebRecordingsVideo';
import AppTeacherLoginScreen from '../../pages/auth/AppTeacherLoginScreen/AppTeacherLoginScreen';
import TeacherProgramList from '../../pages/teacherDashboard/TeacherProgramList/TeacherProgramList';
import TeacherProgramDetail from '../../pages/teacherDashboard/TeacherProgramDetail/TeacherProgramDetail';
import TeacherProgramDetailSingleStudent from '../../pages/teacherDashboard/TeacherProgramDetailSingleStudent/TeacherProgramDetailSingleStudent';
import TeacherChatList from '../../pages/teacherDashboard/TeacherChatList/TeacherChatList';
import AppStudentLoginScreen from '../../pages/auth/AppStudentLoginScreen/AppStudentLoginScreen';
import StudentProgramList from '../../pages/studentDashboard/StudentProgramList/StudentProgramList';
import StudentProgramDetailWrapper from '../../pages/studentDashboard/StudentProgramDetailWrapper/StudentProgramDetailWrapper';
import AppLoader from '../../components/AppLoader/AppLoader';
import AppPdfViewer from '../../pages/common/AppPdfViewer/AppPdfViewer';

const TeacherChatPortal = lazy(() =>
  import('../../pages/teacherDashboard/TeacherChatPortal/TeacherChatPortal'),
);
const StudentChatPortal = lazy(() =>
  import('../../pages/studentDashboard/studentChatPortal/StudentChatPortal'),
);

function CommonRoutes() {
  return (
    <>
      <Route path={APP_VIDEO_PLAYER} element={<AppVideoPlayerWrapper />} />
      <Route path={APP_AUDIO_PLAYER} element={<AppAudioPlayer />} />
      <Route path={APP_PDF_VIEWER} element={<AppPdfViewer />} />
      <Route path={APP_IMAGE_VIEWER} element={<AppImageViewer />} />
      <Route
        path={APP_WEB_RECORDINGS_VIDEO_PLAYER}
        element={<WebRecordingsVideo />}
      />
    </>
  );
}

function TeacherRoutes(redirectionalUrl, ismobile) {
  return (
    <>
      <Route
        path={APP_TEACHER_LOGIN_SCREEN}
        element={<AppTeacherLoginScreen />}
      />
      <Route path={TEACHER_DASHBOARD} element={<TeacherProgramList />} />
      <Route path={TEACHER_PROGRAM_DETAIL} element={<TeacherProgramDetail />} />
      <Route
        path={TEACHER_PROGRAM_DETAIL_CONTENT}
        element={<TeacherProgramDetail />}
      />
      <Route
        path={TEACHER_PROGRAM_DETAIL_INDIVIDUAL_STUDENT}
        element={<TeacherProgramDetailSingleStudent />}
      />
      <Route path={TEACHER_CHAT_LISTING} element={<TeacherChatList />} />
      {/* <Route path={TEACHER_CHAT_PORTAL} element={<TeacherChatPortal />} />
      <Route
        path={TEACHER_CHAT_PORTAL_GROUP_DETAIL}
        element={<TeacherChatPortal />}
      /> */}
      <Route
        path={TEACHER_CHAT_PORTAL}
        element={
          !ismobile ? <TeacherChatPortal /> : <Navigate to={redirectionalUrl} />
        }
      />
      <Route
        path={TEACHER_CHAT_PORTAL_GROUP_DETAIL}
        element={
          !ismobile ? <TeacherChatPortal /> : <Navigate to={redirectionalUrl} />
        }
      />
    </>
  );
}

function StudentRoutes(redirectionalUrl, ismobile) {
  return (
    <>
      <Route
        path={APP_STUDENT_LOGIN_SCREEN}
        element={<AppStudentLoginScreen />}
      />
      <Route path={STUDENT_DASHBOARD} element={<StudentProgramList />} />
      <Route
        path={STUDENT_PROGRAM_DETAIL}
        element={<StudentProgramDetailWrapper />}
      />
      <Route
        path={STUDENT_PROGRAM_DETAIL_CONTENT}
        element={<StudentProgramDetailWrapper />}
      />
      {/* <Route path={STUDENT_CHAT_PORTAL} element={<StudentChatPortal />} />
      <Route
        path={STUDENT_CHAT_PORTAL_GROUP_DETAIL}
        element={<StudentChatPortal />}
      /> */}
      <Route
        path={STUDENT_CHAT_PORTAL}
        element={
          !ismobile ? <StudentChatPortal /> : <Navigate to={redirectionalUrl} />
        }
      />
      <Route
        path={STUDENT_CHAT_PORTAL_GROUP_DETAIL}
        element={
          !ismobile ? <StudentChatPortal /> : <Navigate to={redirectionalUrl} />
        }
      />
    </>
  );
}

function AppSwitch() {
  const setUserNameAndToken = useAppStore((state) => state.setUserNameAndToken);
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const isMobile = useBreakpointValue({ base: true, xl: false });

  const navigate = useNavigate();

  const getRedirectionURL = () => {
    /**
     * This function is used to handle redirection url
     * TEACHER_DASHBOARD - if - Teacher Environment and Authenticated
     * STUDENT DASHBOARD - if - Authenticated and Not Teacher Environment
     * Else - Redirect to login screen based on environment
     * */
    if (isAuthenticated && isTeacherEnvironment()) {
      return TEACHER_DASHBOARD;
    }
    if (isAuthenticated && !isTeacherEnvironment()) {
      return STUDENT_DASHBOARD;
    }
    if (isTeacherEnvironment()) {
      return APP_TEACHER_LOGIN_SCREEN;
    }
    return APP_STUDENT_LOGIN_SCREEN;
  };

  useLayoutEffect(() => {
    isUserAuthenticated()
      .then((data) => {
        const token = getUserToken();
        setUserNameAndToken({
          token,
          name: data?.data?.data?.user?.name,
        });

        setIsAuthenticated(true);
      })
      .catch(() => {
        setIsAuthenticated(false);
        navigate(getRedirectionURL());
        removeUserToken();
      });
  }, []);

  return (
    <Suspense fallback={<AppLoader />}>
      <Routes>
        {isAuthenticated !== null ? (
          <>
            <Route path="/" element={<Navigate to={getRedirectionURL()} />} />

            {TeacherRoutes(getRedirectionURL(), isMobile)}
            {StudentRoutes(getRedirectionURL(), isMobile)}
            {CommonRoutes()}
          </>
        ) : (
          <Route path="/" element={<AppLoader />} />
        )}
      </Routes>
    </Suspense>
  );
}
export default AppSwitch;
