import AppTeacherNavbar from 'version-two/components/AppTeacherNavbar/AppTeacherNavbar';
import { Box, Container, useBreakpointValue } from '@chakra-ui/react';
import PageBreadCrumb from 'version-two/components/PageBreadCrumb/PageBreadCrumb';

function TeacherDashboardLayout({ children, isProgram, breadCrumb, tabIndex }) {
  const isMobile = useBreakpointValue({ base: true, sm: false });
  return (
    <Box>
      <AppTeacherNavbar
        isProgram={isProgram}
        tabIndex={tabIndex}
        breadCrumb={breadCrumb}
      />
      {!isMobile && <PageBreadCrumb breadCrumb={breadCrumb} />}
      <Container maxW="1000px">{children}</Container>
    </Box>
  );
}

export default TeacherDashboardLayout;
