import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  CloseButton,
} from '@chakra-ui/react';
import { VoxGuruLogo } from 'version-two/assets';
import AppBox from 'version-two/components/chakraLayoutOverrides/AppBox';
import AppText from 'version-two/components/Typography/AppText';
import {
  APP_STUDENT_LOGIN_SCREEN,
  MAKE_STUDENT_CHAT_PORTAL,
} from 'version-two/navigation/routes';
import AppFlex from 'version-two/components/chakraLayoutOverrides/AppFlex';
import { ChevronDownIcon } from '@chakra-ui/icons';
import appColors from 'version-two/theme/appColors';
import useAppStore from 'version-two/store';
import { useMutation } from 'react-query';
import makePostRequest from 'version-two/api/utils/makePostRequest';
import { APP_TEACHER_LOGOUT } from 'version-two/api/urls/teacher';
import removeUserToken from 'version-two/api/utils/removeUserToken';
import { useNavigate } from 'react-router-dom';
import getIsWebView from 'version-two/utils/getIsWebView';
import { useMemo } from 'react';
import { CometChatUIKit } from '@cometchat/chat-uikit-react';
import { REACT_NATIVE_MESSAGES } from '../../utils/constants';

function AppStudentNavbar() {
  const isAuthenticated = useAppStore.getState().is_authenticated;
  const { setCometChatLoading } = useAppStore();
  const username = useAppStore.getState().user_name;
  const userId = useAppStore.getState().user_id;
  const navigate = useNavigate();
  const { mutate: teacherLogoutMutation } = useMutation(
    () => makePostRequest(APP_TEACHER_LOGOUT),
    {
      onSuccess: () => {
        CometChatUIKit.getLoggedinUser().then((user) => {
          if (user) {
            CometChatUIKit.logout().then(() => {
              // eslint-disable-next-line
              console.log("User logged out");
            });
          }
        });
        setCometChatLoading(true);
        removeUserToken();
        navigate(APP_STUDENT_LOGIN_SCREEN);
      },
    },
  );

  const handleStudentLogout = () => {
    teacherLogoutMutation();
  };

  const handleWebViewNavbarClose = () => {
    window.ReactNativeWebView.postMessage(REACT_NATIVE_MESSAGES.GO_BACK);
  };

  const handleNavigateToStudentChat = () => {
    navigate(MAKE_STUDENT_CHAT_PORTAL(userId));
  };

  // console.log('studentData?.data?.data?.user?.id', studentData)

  const isResponsive = useMemo(() => getIsWebView(), []);
  // eslint-disable-next-line no-nested-ternary
  return isAuthenticated ? (
    isResponsive ? (
      <AppBox variant="mobileNavbar">
        <CloseButton
          size="lg"
          onClick={handleWebViewNavbarClose}
          color={appColors.brandGrey[700]}
        />
        <AppText title="Live Class" variant="mobileNavbarText" />
      </AppBox>
    ) : (
      <Flex
        height="80px"
        px={10}
        mb={5}
        justifyContent="space-between"
        alignItems="center"
      >
        <AppBox>
          <img src={VoxGuruLogo} alt="" />
        </AppBox>
        {/* <AppBox> */}
        {/*  <AppNavLink title="Programs" to={TEACHER_DASHBOARD} /> */}
        {/* </AppBox> */}
        <AppFlex
          customStyles={{
            alignItems: 'center',
            zIndex: 9,
          }}
        >
          <AppText
            title="Hello"
            size="xs"
            customStyles={{
              color: appColors.brandGrey['900'],
            }}
          />
          <Menu>
            <MenuButton as={Button} variant="navButton">
              <AppText
                customStyles={{ as: 'span' }}
                title={username}
                size="xs"
                variant="manageLabel"
              />
              <ChevronDownIcon />
            </MenuButton>
            <MenuList>
              <MenuItem onClick={handleNavigateToStudentChat}>
                Group Chat
              </MenuItem>
              <MenuItem onClick={handleStudentLogout}>Logout</MenuItem>
            </MenuList>
          </Menu>
        </AppFlex>
      </Flex>
    )
  ) : (
    <Flex height="80px" justifyContent="center" alignItems="center">
      <img src={VoxGuruLogo} alt="" />
    </Flex>
  );
}

export default AppStudentNavbar;
