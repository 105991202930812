import { AppLoaderWrapper } from 'version-two/components/AppLoader/AppLoader.styles';
import { Flex } from '@chakra-ui/react';

function AppLoader({ isLoading, children, height = '90vh', customStyles }) {
  if (isLoading) {
    return (
      <Flex
        alignItems="center"
        justifyContent="center"
        height={height}
        {...customStyles}
      >
        <AppLoaderWrapper />
      </Flex>
    );
  }
  return children;
}

export default AppLoader;
